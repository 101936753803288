import * as React from "react";

import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  Avatar,
} from "@mui/material";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

interface OurValuesProps {}

const OurValues: React.FC<OurValuesProps> = ({}) => {
  const icon1 = require("../../assets/aboutHeart.png");
  const icon2 = require("../../assets/aboutPpl.png");
  const icon3 = require("../../assets/aboutHelper.png");

  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Box
      sx={{
        width: phone ? "100%" : tablet ? "100%" : "75%",
        height: phone ? "fit-content": tablet ? "max-content": "30rem",
        display: "flex",
        flexDirection: phone ? "column" : "row",
        alignItems: phone ? "left" : "center",
        margin: phone ? "1.5rem auto" : "3rem auto",
        gap: tablet ? "30px" : "150px"
      }}
    >
      {/* header text */}

      <Box
        sx={{
          flex: 1,
          textAlign: "left",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          width: "max-content",
          maxWidth: "250px"
        }}
      >
        <h1
          style={{
            textAlign: "left",
            fontSize: phone ? "1.75rem" : "2.5rem",
            margin: 0,
            marginLeft: phone ? "2rem" : 0,
          }}
        >
          Our{" "}
          <span style={{ fontStyle: "italic", color: "#1D99BE" }}>values</span>
        </h1>
      </Box>

      {/* icon list */}

      <Box
        sx={{
          width: phone ? "90%" : "65%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          margin: phone ? "0 auto" : 0,
        }}
      >
        <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
          <List>
            {/* bullet 1 */}

            <ListItem
              sx={{
                padding: phone ? "1.5rem 1rem" : "0 1rem 1.5rem 1rem",
                display: "flex",
                flexDirection: phone ? "column" : "row",
                alignItems: phone ? "flex-start" : "center",
              }}
            >
              <ListItemAvatar>
                <Avatar
                  src={icon1}
                  alt="heart icon"
                  sx={{
                    width: 40,
                    height: 40,
                    bgcolor: "transparent",
                    marginRight: "2rem",
                    marginBottom: phone ? "1.5rem" : 0,
                  }}
                />
              </ListItemAvatar>
              <ListItemText
                primaryTypographyProps={{
                  fontFamily: "Sitebody",
                  lineHeight: 1.5,
                  fontSize: "1rem",
                  color: "#0C3D4C",
                  fontWeight: "bold",
                }}
                secondaryTypographyProps={{
                  textAlign: "left",
                  fontFamily: "Sitebody",
                  lineHeight: 1.5,
                  fontSize: "1rem",
                }}
                primary="We aim to make things easier for you"
                secondary="We know that when you need care or need a job, you need it now. We’re committed to giving families and older adults faster access to help at home, and giving Helpers speedy booking for tasks and jobs."
              />
            </ListItem>
            <Divider />

            {/* bullet 2 */}

            <ListItem
              sx={{
                padding: phone ? "1.5rem 1rem" : " 1.5rem 1rem",
                display: "flex",
                flexDirection: phone ? "column" : "row",
                alignItems: phone ? "flex-start" : "center",
              }}
            >
              <ListItemAvatar>
                <Avatar
                  src={icon2}
                  alt="heart icon"
                  sx={{
                    width: 40,
                    height: 40,
                    bgcolor: "transparent",
                    marginRight: "2rem",
                    marginBottom: phone ? "1.5rem" : 0,
                  }}
                />
              </ListItemAvatar>
              <ListItemText
                primaryTypographyProps={{
                  fontFamily: "Sitebody",
                  lineHeight: 1.5,
                  fontSize: "1rem",
                  color: "#0C3D4C",
                  fontWeight: "bold",
                }}
                secondaryTypographyProps={{
                  textAlign: "left",
                  fontFamily: "Sitebody",
                  lineHeight: 1.5,
                  fontSize: "1rem",
                }}
                primary="We know that care isn’t one size fits all"
                secondary="We want older adults to get help and support that makes sense for them. With customizable booking options we can bring together Helpers and seniors to make sure everyone’s needs are met."
              />
            </ListItem>
            <Divider />

            {/* bullet 3 */}

            <ListItem
              sx={{
                padding: phone ? "1.5rem 1rem" : " 1.5rem 1rem",
                display: "flex",
                flexDirection: phone ? "column" : "row",
                alignItems: phone ? "flex-start" : "center",
              }}
            >
              <ListItemAvatar>
                <Avatar
                  src={icon3}
                  alt="heart icon"
                  variant="square"
                  sx={{
                    width: 40,
                    height: 40,
                    bgcolor: "transparent",
                    marginRight: "2rem",
                    marginBottom: phone ? "1.5rem" : "inherit",
                  }}
                />
              </ListItemAvatar>
              <ListItemText
                primaryTypographyProps={{
                  fontFamily: "Sitebody",
                  lineHeight: 1.5,
                  fontSize: "1rem",
                  color: "#0C3D4C",
                  fontWeight: "bold",
                }}
                secondaryTypographyProps={{
                  textAlign: "left",
                  fontFamily: "Sitebody",
                  lineHeight: 1.5,
                  fontSize: "1rem",
                }}
                primary="We’re in this together"
                secondary="Trust is the cornerstone of giving and receiving care. That’s why we prioritize features and tools to help both families and Helpers connect safely and smoothly, with complete transparency on pricing and services."
              />
            </ListItem>
          </List>
        </Box>
      </Box>
    </Box>
  );
};

export default OurValues;
