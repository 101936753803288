import * as React from "react";
import { AppBar, Box, Toolbar, Button, Tooltip } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { Link } from "react-router-dom";

interface NavProps {
  color: string;
}
const PartnerNavbar: React.FC<NavProps> = ({ color }) => {
  const logo = require("../assets/Logo.png");
  return (
    <Box sx={{ flexGrow: 1, width: "100%" }}>
      <AppBar
        position="static"
        elevation={0}
        sx={{
          backgroundColor: "transparent",
          margin: "0 auto",
          display: "flex",
          flexFlow: "row wrap",
          height: "8rem",
          width: "80%",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Link
          style={{
            textDecoration: "none",
            height: "50%",
          }}
          to="/"
        >
          <img
            src={logo}
            style={{
              objectFit: "contain",
              height: "75%",
            }}
            alt="logo"
          />
        </Link>
        <Toolbar sx={{ flex: 1 }}></Toolbar>
        <Button
          variant="contained"
          startIcon={<PersonIcon fontSize="large" />}
          sx={{
            width: "fit-content",
            borderRadius: 9999,
            padding: ".5rem 1rem",
            boxShadow: "none",
          }}
        >
          Login
        </Button>
      </AppBar>
    </Box>
  );
};

export default PartnerNavbar;
