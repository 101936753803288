import SvgIcon from "@mui/material/SvgIcon";

export default function GoogleIcon() {
  return (
    <SvgIcon
      sx={{
        width: "inherit",
        flex: 1,
        height: "inherit",
      }}
    >
      <svg width="189" height="56" viewBox="0 0 189 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2159_6327)">
<path d="M0 9.8C0 4.38761 4.38761 0 9.8 0H179.2C184.612 0 189 4.38761 189 9.8V46.2C189 51.6124 184.612 56 179.2 56H9.8C4.38761 56 0 51.6124 0 46.2V9.8Z" fill="black"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M179.2 1.4H9.8C5.16081 1.4 1.4 5.16081 1.4 9.8V46.2C1.4 50.8392 5.16081 54.6 9.8 54.6H179.2C183.839 54.6 187.6 50.8392 187.6 46.2V9.8C187.6 5.16081 183.839 1.4 179.2 1.4ZM9.8 0C4.38761 0 0 4.38761 0 9.8V46.2C0 51.6124 4.38761 56 9.8 56H179.2C184.612 56 189 51.6124 189 46.2V9.8C189 4.38761 184.612 0 179.2 0H9.8Z" fill="#A6A6A6"/>
<path d="M62.258 18.522C63.56 18.522 64.638 18.088 65.436 17.234C66.15 16.52 66.514 15.54 66.514 14.336C66.514 14.126 66.5 13.902 66.458 13.678L66.43 13.566H62.104V14.854H65.142C65.044 15.568 64.736 16.128 64.246 16.548C63.714 16.996 63.042 17.22 62.244 17.22C61.376 17.22 60.62 16.912 60.018 16.31C59.416 15.694 59.122 14.924 59.122 14C59.122 13.076 59.43 12.306 60.018 11.69C60.62 11.088 61.362 10.78 62.244 10.78C62.692 10.78 63.112 10.864 63.504 11.032C63.896 11.2 64.218 11.438 64.456 11.732L64.554 11.844L65.534 10.864L65.45 10.766C65.086 10.346 64.61 10.024 64.05 9.79999C63.49 9.57599 62.888 9.46399 62.244 9.46399C60.984 9.46399 59.92 9.89799 59.052 10.766C58.184 11.634 57.75 12.712 57.75 13.986C57.75 15.26 58.184 16.338 59.052 17.206C59.92 18.074 60.998 18.508 62.244 18.508L62.258 18.522Z" fill="white"/>
<path d="M72.9821 17.024H69.1601V14.644H72.6041V13.356H69.1601V10.976H72.9821V9.65997H67.7881V18.34H72.9821V17.024Z" fill="white"/>
<path d="M77.532 18.34V10.976H79.884V9.65997H73.822V10.976H76.174V18.34H77.532Z" fill="white"/>
<path d="M85.1341 9.65997H83.7761V18.34H85.1341V9.65997Z" fill="white"/>
<path d="M89.9222 18.34V10.976H92.2602V9.65997H86.2122V10.976H88.5642V18.34H89.9222Z" fill="white"/>
<path d="M99.9741 18.522C101.22 18.522 102.284 18.088 103.152 17.22C104.006 16.366 104.44 15.274 104.44 14C104.44 12.726 104.006 11.648 103.152 10.794C102.298 9.91197 101.234 9.47797 99.9741 9.47797C98.7141 9.47797 97.6501 9.91197 96.7961 10.794C95.9421 11.648 95.5081 12.74 95.5081 14.014C95.5081 15.288 95.9421 16.38 96.7961 17.234C97.6501 18.102 98.7141 18.55 99.9741 18.55V18.522ZM97.776 11.69C98.364 11.088 99.1061 10.78 99.9741 10.78C100.842 10.78 101.57 11.074 102.158 11.69C102.746 12.292 103.04 13.062 103.04 14C103.04 14.938 102.746 15.708 102.158 16.31C101.57 16.926 100.856 17.22 99.9741 17.22C99.0921 17.22 98.364 16.912 97.776 16.31C97.188 15.708 96.8941 14.924 96.8941 14C96.8941 13.076 97.1881 12.292 97.7621 11.69H97.776Z" fill="white"/>
<path d="M107.016 12.964L106.988 11.788L111.034 18.27L111.076 18.34H112.42V9.65997H111.062V14.714L111.09 15.904L107.198 9.65997H105.658V18.34H107.016V12.964Z" fill="white"/>
<path d="M66.8361 32.284V34.804H72.8841C72.7021 36.218 72.2261 37.268 71.5121 37.982C70.6301 38.864 69.2581 39.83 66.8501 39.83C63.1261 39.83 60.2141 36.834 60.2141 33.11C60.2141 29.386 63.1261 26.39 66.8501 26.39C68.8521 26.39 70.3221 27.174 71.4001 28.196L73.1781 26.418C71.6661 24.976 69.6641 23.87 66.8361 23.87C61.7401 23.87 57.4561 28.014 57.4561 33.124C57.4561 38.234 61.7401 42.378 66.8361 42.378C69.5941 42.378 71.6661 41.482 73.2901 39.788C74.9561 38.122 75.4741 35.77 75.4741 33.88C75.4741 33.292 75.4321 32.746 75.3341 32.298H66.8361V32.284Z" fill="white"/>
<path d="M118.3 24.5H115.682V42H118.3V24.5Z" fill="white"/>
<path d="M158.746 30.422C156.856 30.422 154.896 31.262 154.084 33.096L156.408 34.062C156.898 33.096 157.822 32.774 158.788 32.774C160.146 32.774 161.518 33.586 161.532 35.028V35.21C161.056 34.944 160.048 34.538 158.802 34.538C156.296 34.538 153.762 35.91 153.762 38.472C153.762 40.81 155.806 42.322 158.102 42.322C159.852 42.322 160.832 41.538 161.434 40.614H161.518V41.958H164.038V35.252C164.038 32.144 161.714 30.408 158.718 30.408L158.746 30.422ZM158.424 40.012C157.57 40.012 156.38 39.578 156.38 38.528C156.38 37.17 157.864 36.666 159.152 36.666C160.3 36.666 160.846 36.918 161.532 37.254C161.336 38.878 159.936 40.026 158.424 40.026V40.012Z" fill="white"/>
<path d="M82.3481 30.45C79.0581 30.45 76.3701 32.956 76.3701 36.4C76.3701 39.844 79.0581 42.35 82.3481 42.35C85.6381 42.35 88.3261 39.83 88.3261 36.4C88.3261 32.97 85.6381 30.45 82.3481 30.45ZM82.3481 40.012C80.5421 40.012 78.9881 38.528 78.9881 36.4C78.9881 34.272 80.5421 32.788 82.3481 32.788C84.1541 32.788 85.7081 34.258 85.7081 36.4C85.7081 38.542 84.1541 40.012 82.3481 40.012Z" fill="white"/>
<path d="M173.236 30.8L170.24 38.388H170.156L167.048 30.8H164.234L168.896 41.412L166.236 47.306H168.966L176.148 30.8H173.236Z" fill="white"/>
<path d="M142.534 24.5H136.276V42H138.894V35.364H142.548C145.446 35.364 148.288 33.264 148.288 29.932C148.288 26.6 145.446 24.5 142.548 24.5H142.534ZM142.604 32.942H138.894V26.936H142.604C144.564 26.936 145.67 28.56 145.67 29.932C145.67 31.304 144.564 32.928 142.604 32.928V32.942Z" fill="white"/>
<path d="M152.334 24.5H149.716V42H152.334V24.5Z" fill="white"/>
<path d="M95.396 30.45C92.106 30.45 89.418 32.956 89.418 36.4C89.418 39.844 92.106 42.35 95.396 42.35C98.686 42.35 101.374 39.83 101.374 36.4C101.374 32.97 98.686 30.45 95.396 30.45ZM95.396 40.012C93.59 40.012 92.036 38.528 92.036 36.4C92.036 34.272 93.59 32.788 95.396 32.788C97.202 32.788 98.756 34.258 98.756 36.4C98.756 38.542 97.202 40.012 95.396 40.012Z" fill="white"/>
<path d="M111.398 31.78H111.314C110.726 31.08 109.606 30.45 108.178 30.45C105.196 30.45 102.466 33.068 102.466 36.428C102.466 39.788 105.196 42.364 108.178 42.364C109.592 42.364 110.726 41.734 111.314 41.006H111.398V41.86C111.398 44.142 110.18 45.36 108.22 45.36C106.624 45.36 105.63 44.212 105.224 43.246L102.942 44.198C103.6 45.78 105.336 47.712 108.22 47.712C111.286 47.712 113.876 45.906 113.876 41.51V30.814H111.398V31.78ZM108.402 40.012C106.596 40.012 105.084 38.5 105.084 36.428C105.084 34.356 106.596 32.802 108.402 32.802C110.208 32.802 111.58 34.342 111.58 36.428C111.58 38.514 110.18 40.012 108.402 40.012Z" fill="white"/>
<path d="M130.27 34.244C129.78 32.914 128.268 30.45 125.174 30.45C122.08 30.45 119.56 32.858 119.56 36.4C119.56 39.732 122.08 42.35 125.468 42.35C128.198 42.35 129.78 40.684 130.424 39.718L128.394 38.36C127.722 39.354 126.798 40.012 125.468 40.012C124.138 40.012 123.186 39.41 122.584 38.206L130.55 34.916L130.284 34.244H130.27ZM122.15 36.232C122.08 33.936 123.928 32.76 125.258 32.76C126.294 32.76 127.176 33.278 127.47 34.02L122.15 36.232Z" fill="white"/>
<path d="M29.0079 27.188L14.0979 43.008C14.5599 44.73 16.1279 46.004 17.9899 46.004C18.7319 46.004 19.4319 45.808 20.0339 45.444L20.0759 45.416L36.8479 35.742L28.9939 27.188H29.0079Z" fill="#EA4335"/>
<path d="M44.0861 24.4999H44.0721L36.8341 20.2859L28.6721 27.552L36.8621 35.742L44.0721 31.5839C45.3321 30.8979 46.1861 29.568 46.1861 28.042C46.1861 26.516 45.3461 25.186 44.0861 24.514V24.4999Z" fill="#FBBC04"/>
<path d="M14.098 12.9919C14.014 13.3279 13.958 13.6639 13.958 14.0279V41.9719C13.958 42.3359 14 42.6719 14.098 43.0079L29.512 27.5939L14.098 12.9919Z" fill="#4285F4"/>
<path d="M29.1199 28L36.8339 20.286L20.0759 10.57C19.4739 10.206 18.7599 9.99597 17.9899 9.99597C16.1279 9.99597 14.5599 11.27 14.0979 12.978L29.1199 28Z" fill="#34A853"/>
</g>
<defs>
<clipPath id="clip0_2159_6327">
<rect width="189" height="56" fill="white"/>
</clipPath>
</defs>
</svg>

    </SvgIcon>
  );
}
