import SvgIcon from "@mui/material/SvgIcon";

export default function CircleHeartIcon() {
  return (
    <SvgIcon
    sx={{
        width: "100%",
        height: "100%"
      }}
    >
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="48" height="48" rx="24" fill="#CEE9E1"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M32.5669 17.8388C30.419 15.6909 26.9366 15.6909 24.7887 17.8388L23.7281 18.8995L22.6674 17.8388C20.5195 15.6909 17.0371 15.6909 14.8892 17.8388C12.7413 19.9867 12.7413 23.4691 14.8892 25.617L15.9499 26.6777L23.021 33.7487C23.4115 34.1392 24.0447 34.1392 24.4352 33.7487L31.5062 26.6777L32.5669 25.617C34.7148 23.4691 34.7148 19.9867 32.5669 17.8388Z" fill="#6AA08F"/>
</svg>


    </SvgIcon>
  );
}
