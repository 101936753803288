import SvgIcon from "@mui/material/SvgIcon";

export default function PhoneIcon() {
  return (
    <SvgIcon
      sx={{
        width: "32px",
        height: "32px",
      }}
    >

    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.5" d="M4.00073 4C4.00073 1.79086 5.79159 0 8.00073 0H24.0007C26.2099 0 28.0007 1.79086 28.0007 4V28C28.0007 30.2091 26.2099 32 24.0007 32H8.00073C5.79159 32 4.00073 30.2091 4.00073 28V4Z" fill="#C97B99"/>
        <path fillRule="evenodd" clip-rule="evenodd" d="M16 26C14.8954 26 14 26.8954 14 28C14 29.1046 14.8954 30 16 30C17.1046 30 18 29.1046 18 28C18 26.8954 17.1046 26 16 26Z" fill="#C97B99"/>
        <rect x="8" y="4" width="16" height="20" rx="1" fill="#C97B99"/>
    </svg>

    </SvgIcon>
  );
}
