import SvgIcon from "@mui/material/SvgIcon";

export default function AppleIcon() {
  return (
    <SvgIcon
      sx={{
        width: "inherit",
        flex: 1,
        height: "inherit",
      }}
    >
        <svg width="189" height="56" viewBox="0 0 189 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2159_6326)">
<path d="M0 9.8C0 4.38761 4.38761 0 9.8 0H179.2C184.612 0 189 4.38761 189 9.8V46.2C189 51.6124 184.612 56 179.2 56H9.8C4.38761 56 0 51.6124 0 46.2V9.8Z" fill="black"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M179.2 1.4H9.8C5.16081 1.4 1.4 5.16081 1.4 9.8V46.2C1.4 50.8392 5.16081 54.6 9.8 54.6H179.2C183.839 54.6 187.6 50.8392 187.6 46.2V9.8C187.6 5.16081 183.839 1.4 179.2 1.4ZM9.8 0C4.38761 0 0 4.38761 0 9.8V46.2C0 51.6124 4.38761 56 9.8 56H179.2C184.612 56 189 51.6124 189 46.2V9.8C189 4.38761 184.612 0 179.2 0H9.8Z" fill="#A6A6A6"/>
<path d="M45.8764 28.421C45.8915 27.2525 46.2018 26.1067 46.7787 25.0904C47.3555 24.0741 48.18 23.2202 49.1756 22.6081C48.5431 21.7049 47.7088 20.9616 46.7389 20.4372C45.7689 19.9128 44.69 19.6218 43.588 19.5874C41.237 19.3406 38.9579 20.9941 37.7599 20.9941C36.5387 20.9941 34.6942 19.6119 32.708 19.6527C31.4232 19.6942 30.1712 20.0678 29.0737 20.7371C27.9763 21.4064 27.071 22.3485 26.4459 23.4717C23.7383 28.1595 25.7579 35.049 28.3516 38.8383C29.6492 40.6937 31.1658 42.7664 33.1503 42.6929C35.0922 42.6123 35.8174 41.4546 38.1614 41.4546C40.4837 41.4546 41.1641 42.6929 43.1888 42.6461C45.2726 42.6123 46.5854 40.7824 47.8375 38.9093C48.7699 37.5872 49.4874 36.126 49.9633 34.5798C48.7527 34.0678 47.7197 33.2108 46.9929 32.1156C46.2661 31.0203 45.8778 29.7354 45.8764 28.421Z" fill="white"/>
<path d="M42.052 17.0953C43.1882 15.7314 43.7479 13.9784 43.6123 12.2084C41.8766 12.3907 40.2732 13.2203 39.1217 14.5319C38.5587 15.1726 38.1275 15.918 37.8528 16.7255C37.5781 17.533 37.4651 18.3867 37.5205 19.2378C38.3887 19.2468 39.2476 19.0586 40.0326 18.6875C40.8175 18.3164 41.508 17.772 42.052 17.0953Z" fill="white"/>
<path d="M70.4232 37.9956H63.7964L62.205 42.6946H59.3982L65.675 25.3094H68.5912L74.8679 42.6946H72.0132L70.4232 37.9956ZM64.4828 35.8272H69.7356L67.1461 28.201H67.0736L64.4828 35.8272Z" fill="white"/>
<path d="M88.4236 36.3577C88.4236 40.2966 86.3154 42.8272 83.1339 42.8272C82.328 42.8694 81.5265 42.6837 80.8212 42.2916C80.1159 41.8994 79.5353 41.3165 79.1458 40.6096H79.0856V46.8878H76.4839V30.0194H79.0023V32.1276H79.0501C79.4575 31.4242 80.0479 30.8443 80.7586 30.4497C81.4693 30.0551 82.2736 29.8606 83.0861 29.8868C86.303 29.8868 88.4236 32.4298 88.4236 36.3577ZM85.7494 36.3577C85.7494 33.7915 84.4232 32.1044 82.3997 32.1044C80.4119 32.1044 79.0747 33.827 79.0747 36.3577C79.0747 38.9116 80.4119 40.622 82.3997 40.622C84.4232 40.622 85.7494 38.9472 85.7494 36.3577Z" fill="white"/>
<path d="M102.374 36.3577C102.374 40.2966 100.266 42.8272 97.0846 42.8272C96.2787 42.8694 95.4772 42.6837 94.7719 42.2915C94.0666 41.8993 93.486 41.3165 93.0965 40.6096H93.0363V46.8878H90.4346V30.0194H92.9529V32.1276H93.0008C93.4081 31.4242 93.9985 30.8443 94.7092 30.4497C95.4199 30.0551 96.2243 29.8606 97.0367 29.8868C100.254 29.8868 102.374 32.4298 102.374 36.3577ZM99.7 36.3577C99.7 33.7915 98.3739 32.1044 96.3504 32.1044C94.3625 32.1044 93.0254 33.827 93.0254 36.3577C93.0254 38.9116 94.3625 40.622 96.3504 40.622C98.3739 40.622 99.7 38.9472 99.7 36.3577Z" fill="white"/>
<path d="M111.595 37.8507C111.787 39.5747 113.462 40.7067 115.751 40.7067C117.944 40.7067 119.522 39.5746 119.522 38.0201C119.522 36.6707 118.57 35.8627 116.317 35.309L114.064 34.7662C110.871 33.9951 109.389 32.5022 109.389 30.0795C109.389 27.0799 112.004 25.0195 115.715 25.0195C119.389 25.0195 121.907 27.0799 121.992 30.0795H119.366C119.209 28.3445 117.774 27.2973 115.678 27.2973C113.583 27.2973 112.148 28.3568 112.148 29.899C112.148 31.1281 113.064 31.8514 115.305 32.405L117.221 32.8753C120.788 33.7189 122.27 35.1518 122.27 37.6947C122.27 40.9472 119.679 42.9843 115.558 42.9843C111.703 42.9843 109.1 40.9951 108.931 37.8505L111.595 37.8507Z" fill="white"/>
<path d="M127.885 27.0198V30.0194H130.295V32.0797H127.885V39.0674C127.885 40.153 128.367 40.6588 129.427 40.6588C129.713 40.6538 129.999 40.6338 130.283 40.5986V42.6467C129.806 42.7357 129.322 42.776 128.838 42.767C126.271 42.767 125.271 41.8031 125.271 39.3449V32.0797H123.428V30.0194H125.271V27.0198H127.885Z" fill="white"/>
<path d="M131.691 36.3577C131.691 32.3696 134.04 29.8636 137.702 29.8636C141.377 29.8636 143.715 32.3696 143.715 36.3577C143.715 40.3568 141.39 42.8519 137.702 42.8519C134.016 42.8519 131.691 40.3567 131.691 36.3577ZM141.064 36.3577C141.064 33.622 139.811 32.0073 137.702 32.0073C135.594 32.0073 134.342 33.6343 134.342 36.3577C134.342 39.1044 135.594 40.7068 137.702 40.7068C139.811 40.7068 141.064 39.1044 141.064 36.3577Z" fill="white"/>
<path d="M145.861 30.0194H148.342V32.1768H148.402C148.57 31.503 148.965 30.9076 149.52 30.4905C150.075 30.0735 150.757 29.8603 151.451 29.8868C151.751 29.8857 152.05 29.9183 152.342 29.9839V32.4175C151.964 32.3018 151.569 32.2487 151.173 32.2602C150.796 32.2449 150.419 32.3115 150.069 32.4556C149.719 32.5996 149.405 32.8177 149.147 33.0947C148.89 33.3717 148.695 33.7012 148.577 34.0606C148.459 34.4199 148.42 34.8006 148.462 35.1764V42.6946H145.861L145.861 30.0194Z" fill="white"/>
<path d="M164.338 38.9717C163.988 41.2727 161.747 42.8518 158.88 42.8518C155.193 42.8518 152.904 40.3813 152.904 36.4178C152.904 32.442 155.205 29.8635 158.771 29.8635C162.278 29.8635 164.483 32.2725 164.483 36.1157V37.0071H155.531V37.1643C155.489 37.6308 155.548 38.1007 155.702 38.5429C155.856 38.9851 156.103 39.3894 156.425 39.7289C156.748 40.0685 157.139 40.3356 157.573 40.5124C158.006 40.6892 158.472 40.7718 158.94 40.7545C159.555 40.8121 160.172 40.6697 160.7 40.3485C161.227 40.0273 161.637 39.5444 161.868 38.9717L164.338 38.9717ZM155.543 35.1887H161.88C161.903 34.7693 161.84 34.3495 161.693 33.9559C161.547 33.5622 161.32 33.203 161.028 32.9009C160.736 32.5989 160.385 32.3604 159.997 32.2005C159.608 32.0406 159.191 31.9627 158.771 31.9717C158.347 31.9692 157.927 32.0506 157.535 32.2112C157.143 32.3719 156.787 32.6086 156.487 32.9077C156.187 33.2067 155.949 33.5623 155.787 33.9538C155.625 34.3453 155.542 34.765 155.543 35.1887Z" fill="white"/>
<path d="M64.1565 12.2234C64.702 12.1843 65.2493 12.2667 65.7591 12.4647C66.2689 12.6627 66.7283 12.9714 67.1043 13.3684C67.4804 13.7655 67.7636 14.2411 67.9335 14.7609C68.1035 15.2806 68.156 15.8317 68.0872 16.3742C68.0872 19.0429 66.6448 20.577 64.1565 20.577H61.1392V12.2234H64.1565ZM62.4366 19.3956H64.0116C64.4014 19.4189 64.7914 19.355 65.1534 19.2085C65.5153 19.0621 65.8401 18.8368 66.104 18.549C66.3679 18.2612 66.5643 17.9182 66.6789 17.5449C66.7935 17.1716 66.8234 16.7775 66.7665 16.3912C66.8193 16.0064 66.7863 15.6148 66.6699 15.2442C66.5536 14.8737 66.3567 14.5335 66.0934 14.248C65.8301 13.9625 65.5069 13.7388 65.147 13.5928C64.7871 13.4469 64.3994 13.3824 64.0116 13.4039H62.4366V19.3956Z" fill="white"/>
<path d="M69.5529 17.4221C69.5132 17.0078 69.5606 16.5898 69.692 16.1949C69.8234 15.8 70.0359 15.437 70.3158 15.129C70.5957 14.821 70.9369 14.5749 71.3175 14.4066C71.6981 14.2382 72.1097 14.1512 72.5258 14.1512C72.942 14.1512 73.3536 14.2382 73.7342 14.4066C74.1148 14.5749 74.456 14.821 74.7359 15.129C75.0158 15.437 75.2283 15.8 75.3597 16.1949C75.491 16.5898 75.5384 17.0078 75.4988 17.4221C75.5392 17.8368 75.4923 18.2554 75.3613 18.6509C75.2303 19.0465 75.018 19.4102 74.738 19.7188C74.458 20.0274 74.1165 20.274 73.7355 20.4427C73.3546 20.6115 72.9425 20.6986 72.5258 20.6986C72.1092 20.6986 71.6971 20.6115 71.3161 20.4427C70.9351 20.274 70.5937 20.0274 70.3137 19.7188C70.0337 19.4102 69.8214 19.0465 69.6904 18.6509C69.5593 18.2554 69.5125 17.8368 69.5529 17.4221ZM74.2191 17.4221C74.2191 16.0556 73.6052 15.2565 72.5279 15.2565C71.4464 15.2565 70.8381 16.0556 70.8381 17.4221C70.8381 18.7996 71.4465 19.5925 72.5279 19.5925C73.6052 19.5925 74.2191 18.7941 74.2191 17.4221Z" fill="white"/>
<path d="M83.4024 20.5769H82.1118L80.8089 15.9339H80.7105L79.413 20.5769H78.1347L76.397 14.2728H77.6589L78.7882 19.0832H78.8812L80.1773 14.2728H81.3708L82.6669 19.0832H82.7653L83.8892 14.2728H85.1333L83.4024 20.5769Z" fill="white"/>
<path d="M86.5947 14.2728H87.7924V15.2743H87.8854C88.0431 14.9146 88.3091 14.613 88.6463 14.4117C88.9835 14.2104 89.3752 14.1193 89.7666 14.1511C90.0733 14.128 90.3813 14.1743 90.6678 14.2864C90.9542 14.3986 91.2118 14.5737 91.4213 14.7989C91.6309 15.0241 91.7871 15.2935 91.8784 15.5873C91.9696 15.881 91.9937 16.1915 91.9486 16.4958V20.5768H90.7045V16.8082C90.7045 15.7952 90.2643 15.2913 89.3441 15.2913C89.1359 15.2816 88.928 15.3171 88.7347 15.3953C88.5414 15.4734 88.3673 15.5924 88.2244 15.7442C88.0814 15.8959 87.9729 16.0768 87.9064 16.2744C87.8398 16.472 87.8168 16.6816 87.8389 16.8889V20.5769H86.5947L86.5947 14.2728Z" fill="white"/>
<path d="M93.9312 11.8118H95.1753V20.5769H93.9312V11.8118Z" fill="white"/>
<path d="M96.9047 17.4221C96.8651 17.0078 96.9125 16.5898 97.0439 16.1949C97.1753 15.8 97.3878 15.4369 97.6677 15.1289C97.9477 14.8209 98.2889 14.5748 98.6695 14.4064C99.0501 14.238 99.4617 14.1511 99.8779 14.1511C100.294 14.1511 100.706 14.238 101.086 14.4064C101.467 14.5748 101.808 14.8209 102.088 15.1289C102.368 15.4369 102.581 15.8 102.712 16.1949C102.843 16.5898 102.891 17.0078 102.851 17.4221C102.892 17.8368 102.845 18.2554 102.714 18.6509C102.583 19.0465 102.37 19.4102 102.09 19.7188C101.81 20.0274 101.469 20.2739 101.088 20.4427C100.707 20.6114 100.295 20.6986 99.8779 20.6986C99.4613 20.6986 99.0492 20.6114 98.6682 20.4427C98.2872 20.2739 97.9457 20.0274 97.6657 19.7188C97.3857 19.4102 97.1733 19.0465 97.0423 18.6509C96.9112 18.2554 96.8643 17.8368 96.9047 17.4221ZM101.571 17.4221C101.571 16.0556 100.957 15.2565 99.8797 15.2565C98.7982 15.2565 98.1899 16.0556 98.1899 17.4221C98.1899 18.7996 98.7983 19.5925 99.8797 19.5925C100.957 19.5925 101.571 18.7941 101.571 17.4221Z" fill="white"/>
<path d="M104.161 18.7941C104.161 17.6593 105.006 17.0051 106.506 16.9121L108.213 16.8137V16.2696C108.213 15.6038 107.773 15.2278 106.923 15.2278C106.228 15.2278 105.747 15.4828 105.609 15.9285H104.404C104.532 14.8456 105.55 14.1511 106.98 14.1511C108.561 14.1511 109.452 14.9379 109.452 16.2696V20.5769H108.254V19.691H108.156C107.956 20.0088 107.676 20.2678 107.343 20.4417C107.01 20.6156 106.637 20.6981 106.263 20.6808C105.998 20.7083 105.73 20.6801 105.477 20.5979C105.224 20.5157 104.991 20.3814 104.793 20.2036C104.595 20.0259 104.437 19.8086 104.328 19.5658C104.219 19.3231 104.162 19.0602 104.161 18.7941ZM108.213 18.2554V17.7284L106.674 17.8268C105.806 17.8849 105.412 18.1802 105.412 18.736C105.412 19.3033 105.904 19.6335 106.581 19.6335C106.779 19.6536 106.98 19.6336 107.17 19.5746C107.361 19.5157 107.537 19.4191 107.689 19.2905C107.842 19.1619 107.967 19.004 108.057 18.8262C108.147 18.6484 108.2 18.4543 108.213 18.2554Z" fill="white"/>
<path d="M111.087 17.4221C111.087 15.4301 112.111 14.1682 113.704 14.1682C114.098 14.15 114.489 14.2444 114.832 14.4403C115.174 14.6361 115.453 14.9254 115.637 15.2742H115.73V11.8118H116.975V20.5769H115.782V19.5809H115.684C115.486 19.9273 115.196 20.213 114.847 20.4069C114.498 20.6007 114.103 20.6955 113.704 20.6808C112.1 20.6808 111.087 19.4189 111.087 17.4221ZM112.373 17.4221C112.373 18.7592 113.003 19.5638 114.057 19.5638C115.106 19.5638 115.754 18.7476 115.754 17.4276C115.754 16.1137 115.099 15.2859 114.057 15.2859C113.01 15.2859 112.373 16.0959 112.373 17.4221Z" fill="white"/>
<path d="M122.122 17.4221C122.082 17.0078 122.13 16.5898 122.261 16.1949C122.392 15.8 122.605 15.437 122.885 15.129C123.165 14.821 123.506 14.5749 123.887 14.4066C124.267 14.2382 124.679 14.1512 125.095 14.1512C125.511 14.1512 125.923 14.2382 126.303 14.4066C126.684 14.5749 127.025 14.821 127.305 15.129C127.585 15.437 127.797 15.8 127.929 16.1949C128.06 16.5898 128.107 17.0078 128.068 17.4221C128.108 17.8368 128.061 18.2554 127.93 18.6509C127.799 19.0465 127.587 19.4102 127.307 19.7188C127.027 20.0274 126.686 20.274 126.305 20.4427C125.924 20.6115 125.512 20.6986 125.095 20.6986C124.678 20.6986 124.266 20.6115 123.885 20.4427C123.504 20.274 123.163 20.0274 122.883 19.7188C122.603 19.4102 122.39 19.0465 122.259 18.6509C122.128 18.2554 122.082 17.8368 122.122 17.4221ZM126.788 17.4221C126.788 16.0556 126.174 15.2565 125.097 15.2565C124.016 15.2565 123.407 16.0556 123.407 17.4221C123.407 18.7996 124.016 19.5925 125.097 19.5925C126.174 19.5925 126.788 18.7941 126.788 17.4221Z" fill="white"/>
<path d="M129.737 14.2728H130.935V15.2743H131.028C131.185 14.9146 131.451 14.613 131.789 14.4117C132.126 14.2104 132.517 14.1193 132.909 14.1511C133.216 14.128 133.524 14.1743 133.81 14.2864C134.097 14.3986 134.354 14.5737 134.564 14.7989C134.773 15.0241 134.929 15.2935 135.021 15.5873C135.112 15.881 135.136 16.1915 135.091 16.4958V20.5768H133.847V16.8082C133.847 15.7952 133.407 15.2913 132.486 15.2913C132.278 15.2816 132.07 15.3171 131.877 15.3953C131.684 15.4734 131.51 15.5924 131.367 15.7442C131.224 15.8959 131.115 16.0768 131.049 16.2744C130.982 16.472 130.959 16.6816 130.981 16.8889V20.5769H129.737V14.2728Z" fill="white"/>
<path d="M142.121 12.7032V14.3015H143.487V15.3494H142.121V18.591C142.121 19.2514 142.393 19.5406 143.012 19.5406C143.171 19.54 143.329 19.5305 143.487 19.5118V20.5482C143.263 20.5881 143.037 20.6094 142.81 20.6117C141.427 20.6117 140.876 20.125 140.876 18.9096V15.3494H139.875V14.3014H140.876V12.7032H142.121Z" fill="white"/>
<path d="M145.186 11.8118H146.419V15.2859H146.518C146.683 14.9228 146.957 14.6196 147.301 14.4175C147.645 14.2154 148.042 14.1243 148.44 14.1566C148.745 14.14 149.05 14.1909 149.333 14.3058C149.617 14.4206 149.871 14.5966 150.078 14.821C150.286 15.0455 150.441 15.313 150.533 15.6044C150.625 15.8958 150.652 16.2039 150.611 16.5068V20.5769H149.366V16.8137C149.366 15.8067 148.897 15.2968 148.018 15.2968C147.804 15.2792 147.589 15.3086 147.388 15.3829C147.186 15.4572 147.004 15.5745 146.852 15.7268C146.701 15.879 146.585 16.0624 146.512 16.2642C146.439 16.466 146.411 16.6813 146.43 16.895V20.5769H145.186L145.186 11.8118Z" fill="white"/>
<path d="M157.866 18.8747C157.697 19.4509 157.33 19.9493 156.831 20.2827C156.332 20.6161 155.731 20.7633 155.134 20.6986C154.719 20.7095 154.306 20.63 153.924 20.4654C153.543 20.3009 153.202 20.0553 152.925 19.7457C152.648 19.4361 152.441 19.0699 152.32 18.6726C152.199 18.2752 152.165 17.8562 152.222 17.4447C152.167 17.0318 152.201 16.6119 152.322 16.2134C152.443 15.8148 152.648 15.447 152.924 15.1347C153.199 14.8224 153.539 14.5729 153.919 14.4032C154.3 14.2335 154.712 14.1476 155.129 14.1511C156.883 14.1511 157.941 15.3495 157.941 17.3291V17.7632H153.489V17.833C153.47 18.0643 153.499 18.2972 153.575 18.5166C153.65 18.7361 153.771 18.9373 153.929 19.1073C154.087 19.2773 154.279 19.4125 154.493 19.504C154.706 19.5956 154.936 19.6415 155.168 19.639C155.466 19.6747 155.767 19.6211 156.034 19.485C156.301 19.3489 156.522 19.1365 156.668 18.8747L157.866 18.8747ZM153.489 16.8431H156.673C156.689 16.6315 156.66 16.419 156.589 16.2192C156.518 16.0194 156.405 15.8367 156.259 15.6829C156.113 15.5291 155.936 15.4076 155.74 15.3261C155.545 15.2447 155.334 15.2051 155.122 15.21C154.907 15.2073 154.693 15.2477 154.494 15.3288C154.294 15.4099 154.113 15.5301 153.961 15.6823C153.809 15.8345 153.689 16.0157 153.608 16.215C153.527 16.4144 153.486 16.6279 153.489 16.8431Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_2159_6326">
<rect width="189" height="56" fill="white"/>
</clipPath>
</defs>
</svg>


    </SvgIcon>
  );
}
