import * as React from "react";
import { Box } from "@mui/material";

import Footer from "../../components/Footer.tsx";
import PartnerHeader from "./PartnerHeader.tsx";
import TrustedLocal from "./TrustedLocal.tsx";
import FastConvenient from "./FastConvenient.tsx";
import HelpYouNeed from "./HelpYouNeed.tsx";
import WeCanHelpEvents from "./WeCanHelpEvents.tsx";
import Testimonials from "../Dashboard/Testimonials.tsx";
import { Helmet } from "react-helmet";

interface PartnerLandingProps {
  partner: string;
  showLogo: boolean;
}

const PartnerLanding: React.FC<PartnerLandingProps> = ({
  partner,
  showLogo,
}) => {
  
  return (
    <>
    <Helmet>
        <title>Get Started</title>
        <meta
          name="description"
          content="Book a Helper today! Get affordable, background-checked help for seniors—choose one-time or ongoing help with everyday tasks. No commitments, no subscriptions. "
        />
      </Helmet>
      <Box
        sx={{
          // height: "fit-content",
          width: "100vw",
          backgroundColor: "white",
          maxWidth: "100vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "0 ",
        }}
      >
        {/* Header */}

        <PartnerHeader showLogo={showLogo} partner={partner} />
        <FastConvenient />
        <TrustedLocal partner={partner} />
        <WeCanHelpEvents partner={partner} />
        <HelpYouNeed />
        {/* <EventsTestimonials /> */}
        <Testimonials />
        <Footer />
      </Box>
    </>
  );
};

export default PartnerLanding;
