import SvgIcon from "@mui/material/SvgIcon";

export default function InstaIcon({color="black"}) {
  return (
    <SvgIcon
      sx={{
        width: "inherit",
        flex: 1,
        height: "inherit",
      }}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 18.1111C15.4881 18.1111 18.3158 15.3751 18.3158 12C18.3158 8.62493 15.4881 5.88889 12 5.88889C8.51189 5.88889 5.68421 8.62493 5.68421 12C5.68421 15.3751 8.51189 18.1111 12 18.1111ZM12 15.6667C14.0929 15.6667 15.7895 14.025 15.7895 12C15.7895 9.97496 14.0929 8.33333 12 8.33333C9.90713 8.33333 8.21053 9.97496 8.21053 12C8.21053 14.025 9.90713 15.6667 12 15.6667Z"
          fill={color}
        />
        <path
          d="M18.3158 7.11111C19.0134 7.11111 19.5789 6.5639 19.5789 5.88889C19.5789 5.21387 19.0134 4.66667 18.3158 4.66667C17.6182 4.66667 17.0526 5.21387 17.0526 5.88889C17.0526 6.5639 17.6182 7.11111 18.3158 7.11111Z"
          fill={color}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.05263 1C2.26214 1 0 3.18883 0 5.88889V18.1111C0 20.8112 2.26214 23 5.05263 23H18.9474C21.7379 23 24 20.8112 24 18.1111V5.88889C24 3.18883 21.7379 1 18.9474 1H5.05263ZM18.9474 3.44444H5.05263C3.65739 3.44444 2.52632 4.53886 2.52632 5.88889V18.1111C2.52632 19.4611 3.65739 20.5556 5.05263 20.5556H18.9474C20.3426 20.5556 21.4737 19.4611 21.4737 18.1111V5.88889C21.4737 4.53886 20.3426 3.44444 18.9474 3.44444Z"
          fill={color}
        />
      </svg>
    </SvgIcon>
  );
}
