import SvgIcon from "@mui/material/SvgIcon";

export default function CaringDotComIcon() {
  return (
    <SvgIcon
    sx={{
        width: "100%",
        height: "100%"
      }}
    >
        <svg xmlns="http://www.w3.org/2000/svg" width="122" height="32" viewBox="0 -8 122 43" fill="none">
  <path d="M72.3735 9.4389C74.373 9.4389 75.9939 7.77703 75.9939 5.72702C75.9939 3.67701 74.373 2.01514 72.3735 2.01514C70.374 2.01514 68.7532 3.67701 68.7532 5.72702C68.7532 7.77703 70.374 9.4389 72.3735 9.4389Z" fill="#0A4753"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M71.1378 21.6235C71.1588 23.6997 70.8217 25.7646 70.1409 27.7313C69.7939 28.789 69.2655 29.6939 67.9769 29.2515C66.7617 28.8339 66.9223 27.8368 67.2579 26.8443C68.3727 23.6175 68.4449 20.1335 67.4645 16.8652C66.4285 13.3262 64.3106 10.5777 61.2826 8.47583C60.6866 8.08603 60.1125 7.66553 59.5622 7.21607C58.9028 6.64794 58.5031 5.94735 59.036 5.08281C59.5691 4.2183 60.3868 4.02966 61.3058 4.37771C61.8769 4.59104 62.4085 4.89447 62.8795 5.27594C67.9839 9.55592 71.1149 14.824 71.1492 21.619" fill="#0A4753"/>
  <path d="M71.1378 21.6235C71.1588 23.6997 70.8217 25.7646 70.1409 27.7313C69.7939 28.789 69.2655 29.6939 67.9769 29.2515C66.7617 28.8339 66.9223 27.8368 67.2579 26.8443C68.3727 23.6175 68.4449 20.1335 67.4645 16.8652C66.4285 13.3262 64.3106 10.5777 61.2826 8.47583C60.6866 8.08603 60.1125 7.66553 59.5622 7.21607C58.9028 6.64794 58.5031 5.94735 59.036 5.08281C59.5691 4.2183 60.3868 4.02966 61.3058 4.37771C61.8769 4.59104 62.4085 4.89447 62.8795 5.27594C67.9839 9.55592 71.1149 14.824 71.1492 21.619" stroke="#0A4753" stroke-width="0.726478" stroke-miterlimit="10"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M73.6094 21.4685C73.7104 14.7319 76.8253 9.51104 81.8791 5.25798C82.4397 4.73637 83.1412 4.38257 83.9009 4.23853C84.2551 4.16492 84.6244 4.20519 84.9534 4.35326C85.2824 4.50136 85.5539 4.74936 85.7271 5.06037C86.152 5.76997 85.9569 6.4661 85.4147 6.99153C84.8388 7.49079 84.2286 7.95073 83.5884 8.36806C77.4778 12.7334 75.1851 19.5576 77.4294 26.7073C77.5055 26.9476 77.6041 27.1812 77.6591 27.4282C77.8453 28.2635 77.6591 29.0001 76.7771 29.2695C75.8951 29.539 75.1875 29.2156 74.836 28.295C73.9835 26.1172 73.5655 23.8 73.6046 21.4685" fill="#0A4753"/>
  <path d="M73.6094 21.4685C73.7104 14.7319 76.8253 9.51104 81.8791 5.25798C82.4397 4.73637 83.1412 4.38257 83.9009 4.23853C84.2551 4.16492 84.6244 4.20519 84.9534 4.35326C85.2824 4.50136 85.5539 4.74936 85.7271 5.06037C86.152 5.76997 85.9569 6.4661 85.4147 6.99153C84.8388 7.49079 84.2286 7.95073 83.5884 8.36806C77.4778 12.7334 75.1851 19.5576 77.4294 26.7073C77.5055 26.9476 77.6041 27.1812 77.6591 27.4282C77.8453 28.2635 77.6591 29.0001 76.7771 29.2695C75.8951 29.539 75.1875 29.2156 74.836 28.295C73.9835 26.1172 73.5655 23.8 73.6046 21.4685" stroke="#0A4753" stroke-width="0.726478" stroke-miterlimit="10"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M118.719 22.9436C117.874 22.9436 117.208 22.8403 117.208 21.0911C117.208 19.7842 117.208 18.4256 117.208 17.1187C117.208 16.1756 116.978 15.3515 115.866 15.3043C114.669 15.2549 113.946 15.9241 113.879 17.1007C113.801 18.5042 113.831 19.7482 113.849 21.1562C113.863 22.115 113.666 22.9077 112.517 22.9346C111.251 22.9683 111.102 22.1105 111.127 21.1C111.161 19.7527 111.139 18.5514 111.127 17.195C111.127 16.2093 110.897 15.3111 109.694 15.2976C108.49 15.2841 107.824 15.9937 107.773 17.1749C107.716 18.5222 107.732 19.7235 107.748 21.0798C107.759 22.0858 107.578 22.9459 106.335 22.9391C105.161 22.9391 105.019 22.115 105.03 21.1652C105.056 19.005 105.046 17.0087 105.03 14.8462C105.03 13.9907 105.088 13.1621 106.151 13.0296C107.1 12.9106 107.465 13.5685 107.617 14.3769C107.98 14.1209 108.191 13.9502 108.86 13.5079C110.543 12.6905 112.372 13.5618 113.353 14.6666C113.431 14.7564 113.969 14.1658 114.173 14.0109C116.11 12.5423 118.717 13.3013 119.528 14.46C119.752 14.7925 119.894 15.1716 119.942 15.5671C119.997 16.0027 120.001 16.9391 120.006 18.2392C120.01 19.5394 120.077 20.6891 120.006 21.7737C120.002 21.9336 119.965 22.0912 119.898 22.237C119.831 22.3829 119.734 22.5142 119.614 22.6232C119.494 22.7321 119.354 22.8166 119.2 22.8717C119.046 22.9267 118.883 22.9512 118.719 22.9436Z" fill="#0A4753"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M98.1573 22.9394C95.0583 22.8361 93.3127 20.9184 93.4342 17.7388C93.5469 14.8196 95.612 12.9378 98.5891 13.0523C101.488 13.1646 103.331 15.2215 103.241 18.2417C103.152 21.262 101.226 23.0427 98.1573 22.9394ZM100.358 17.9521C100.358 16.104 99.5748 15.0014 98.2448 14.9723C96.8663 14.9431 96.067 15.985 96.0324 17.8398C95.9933 19.7328 96.8203 20.9948 98.132 21.0195C98.5016 21.0369 98.8681 20.9448 99.1833 20.7553C99.4985 20.5658 99.7478 20.2877 99.8987 19.9574C100.228 19.3394 100.386 18.6482 100.358 17.9521Z" fill="#0A4753"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M82.9336 18.161C82.9336 14.3436 86.2003 12.116 89.8667 13.4252C90.5902 13.6834 91.2449 14.0629 91.64 14.7321C91.9801 15.3249 92.0881 16.0053 91.4403 16.4477C91.3107 16.5504 91.1604 16.6251 90.9989 16.667C90.8377 16.7088 90.6693 16.7169 90.5045 16.6906C90.34 16.6643 90.1827 16.6042 90.0435 16.5144C89.9043 16.4245 89.7861 16.3069 89.6968 16.1692C89.065 15.3137 88.2792 14.9207 87.2179 15.3047C86.1152 15.6909 85.7477 16.5891 85.6789 17.6176C85.6052 18.7606 85.5823 19.9574 86.7951 20.5929C87.7716 21.1072 88.7663 21.0039 89.4875 20.045C90.0158 19.3377 90.712 18.8145 91.4978 19.5016C92.2833 20.1888 91.8331 21.0286 91.231 21.6865C90.1148 22.9103 87.6408 23.3011 85.7017 22.5847C83.864 21.9111 82.9291 20.4245 82.9336 18.1543" fill="#0A4753"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.55075 9.34283C9.43906 8.93861 11.2585 9.07336 12.9538 10.0637C13.637 10.4361 14.1954 10.9937 14.5619 11.6692C14.9432 12.4057 15.081 13.1827 14.3321 13.7306C13.5235 14.3212 12.7241 14.0405 12.1613 13.3017C11.5571 12.5158 10.9667 11.7545 9.87783 11.6288C8.51329 11.4716 7.38307 11.9252 6.66174 13.0592C5.45111 14.9836 5.4718 17.0203 6.46417 19.0166C7.09132 20.2337 8.23991 20.7007 9.58151 20.6693C10.6727 20.6446 11.3779 19.9664 11.9453 19.1356C12.5748 18.2149 13.3926 17.6783 14.3941 18.4777C15.3475 19.2389 14.8283 20.1731 14.277 20.9186C12.9285 22.7464 10.9529 23.2741 8.78665 23.1259C6.3631 22.962 4.90208 22.1199 3.55821 20.0675C3.1302 19.3155 2.83947 18.4963 2.69905 17.6468C2.5287 16.4994 2.5287 15.3339 2.69905 14.1864C2.92542 12.9999 3.50752 11.9059 4.37141 11.0434C5.23528 10.181 6.34192 9.58905 7.55075 9.34283Z" fill="#0A4753"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M58.2112 21.9576C55.6199 23.4508 53.3525 23.2285 52.0249 21.4433C50.6325 19.5728 50.9221 15.7868 52.5854 14.1498C54.1933 12.5779 56.2836 12.6161 58.2043 14.2801C58.4663 13.4605 58.8933 12.8182 59.8514 13.0316C60.8759 13.2561 60.7956 14.1139 60.7932 14.9088C60.7932 17.4268 60.7932 19.9433 60.7932 22.4583C60.7932 24.3715 60.2489 26.0018 58.4272 26.8528C56.4698 27.7645 54.5217 27.5938 52.7024 26.4621C51.9719 26.013 51.4436 25.245 52.1052 24.3535C52.6588 23.6035 53.3985 23.8056 53.9843 24.3535C54.903 25.2136 55.9071 25.6111 57.058 25.0272C58.2088 24.4434 58.0894 23.2128 58.2112 21.9576ZM53.8463 17.9021C53.8096 19.7277 54.5518 20.7854 55.8888 20.8213C57.2784 20.8527 58.0504 19.867 58.0711 18.0391C58.0916 16.2112 57.3542 15.1199 56.038 15.1042C54.6826 15.0862 53.8923 16.0967 53.8532 17.9021H53.8463Z" fill="#0A4753"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M25.3885 17.038C25.3885 18.437 25.3885 19.8427 25.5126 21.2327C25.5746 21.8816 25.6941 22.4475 25.0325 22.7664C24.3709 23.0853 23.5737 23.0044 23.2154 22.3173C22.857 21.6301 22.6319 21.6975 22.0829 22.0927C21.5767 22.479 20.9835 22.7416 20.3526 22.8588C19.7218 22.9759 19.0714 22.9443 18.4556 22.7664C17.1714 22.4228 16.4225 21.5516 16.3881 20.1975C16.349 18.931 16.9325 18.0059 18.1454 17.5455C19.1677 17.157 20.2359 17.0807 21.3041 16.9527C21.9542 16.8763 22.8363 16.8471 22.6985 15.8299C22.5791 14.9587 21.8141 14.8958 21.0905 14.8711C20.7197 14.8606 20.3513 14.9308 20.0116 15.0764C19.6719 15.222 19.3696 15.4395 19.1263 15.7131C18.5405 16.3037 17.8445 16.6271 17.2312 15.8748C16.6178 15.1226 17.1186 14.4444 17.6906 13.9347C18.5219 13.2339 19.5793 12.8419 20.677 12.8276C24.4421 12.8771 25.3725 13.8314 25.3885 17.038ZM20.3094 21.2462C21.8991 21.4078 22.8846 20.2199 22.6617 18.4527C21.7819 18.6211 20.8929 18.7536 20.0246 18.9692C19.3997 19.1219 18.8759 19.5081 18.9724 20.2334C19.0689 20.9587 19.68 21.181 20.3094 21.2462Z" fill="#0A4753"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M42.5487 14.4376C43.41 13.6157 44.3633 13.0903 45.5764 13.0499C47.6552 13.0005 49.0542 14.1165 49.1691 16.115C49.2725 17.9317 49.2036 19.7573 49.1991 21.5784C49.1991 22.4766 48.6891 22.9594 47.7563 22.9415C46.8238 22.9235 46.4058 22.3981 46.4193 21.4999C46.4424 20.0739 46.4193 18.6458 46.4193 17.2176C46.4193 16.1712 46.0726 15.3224 44.8113 15.2954C43.5501 15.2685 42.7439 15.9376 42.682 17.1592C42.6038 18.5335 42.6405 19.9145 42.645 21.2932C42.645 22.2566 42.328 22.9684 41.1702 22.9437C40.0837 22.919 39.8516 22.2274 39.8633 21.3247C39.8862 19.2094 39.8633 17.0918 39.8633 14.9766C39.8633 14.1973 39.7277 13.3149 40.8464 13.0836C41.965 12.8523 42.1858 13.719 42.5487 14.4309" fill="#0A4753"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M29.9508 14.7227C30.5481 14.049 30.8329 13.3753 31.5429 13.1508C31.8157 13.0406 32.1161 13.0133 32.4051 13.0726C32.6941 13.1319 32.9578 13.275 33.1623 13.4831C33.3388 13.66 33.4585 13.8835 33.5066 14.1261C33.555 14.3687 33.5294 14.6198 33.4335 14.8484C33.3665 15.11 33.2029 15.3383 32.9746 15.4895C32.7461 15.6407 32.4685 15.7043 32.1952 15.668C30.7227 15.5512 30.3275 16.5213 30.2402 17.689C30.1438 18.9645 30.1644 20.2512 30.1828 21.5311C30.1989 22.4495 29.7923 22.9436 28.9079 22.9503C28.0234 22.957 27.5709 22.454 27.5686 21.5581C27.5686 19.2901 27.5686 17.0221 27.5686 14.7541C27.5686 13.9502 27.6973 13.1822 28.6161 13.0834C29.6315 12.9644 29.9554 13.6718 29.96 14.7294" fill="#0A4753"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M37.6442 17.9474C37.6442 19.0701 37.6235 20.2131 37.6442 21.3449C37.6695 22.2633 37.403 22.9167 36.4245 22.9437C35.4457 22.9706 35.0875 22.389 35.0896 21.4414C35.0896 19.1285 35.0896 16.8134 35.0896 14.5005C35.0896 13.6359 35.4411 13.0633 36.328 13.0476C37.2837 13.0296 37.658 13.5955 37.6397 14.5521C37.6165 15.6749 37.6397 16.8156 37.6397 17.9474H37.6442ZM35.092 10.5595C35.0893 10.8075 35.1621 11.0506 35.301 11.2581C35.4399 11.4655 35.6387 11.6279 35.8724 11.7247C36.1057 11.8214 36.3635 11.8481 36.6127 11.8014C36.8617 11.7546 37.0909 11.6366 37.2713 11.4622C37.4518 11.2879 37.5747 11.065 37.6253 10.822C37.6758 10.5791 37.6514 10.3269 37.5549 10.0975C37.4584 9.86822 37.2945 9.67206 37.0837 9.53403C36.8732 9.39599 36.6251 9.32227 36.3716 9.32227C36.0357 9.32342 35.7139 9.45402 35.4763 9.68568C35.2385 9.91734 35.1034 10.2314 35.101 10.5595H35.092Z" fill="#0A4753"/>
</svg>

    </SvgIcon>
  );
}
