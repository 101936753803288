import SvgIcon from "@mui/material/SvgIcon";

export default function BagIcon() {
  return (
    <SvgIcon
      sx={{
        width: "32px",
        height: "32px",
      }}
    >
     <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.5" d="M2.66714 10.6667C2.66714 9.93028 3.2641 9.33333 4.00048 9.33333H28.0005C28.7369 9.33333 29.3338 9.93028 29.3338 10.6667V25.3333C29.3338 26.8061 28.1399 28 26.6671 28H5.33381C3.86105 28 2.66714 26.8061 2.66714 25.3333V10.6667Z" fill="#678F68"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.90909 7.09091C8.90909 3.17471 12.0838 0 16 0C19.9162 0 23.0909 3.17471 23.0909 7.09091V15.0147C23.0909 15.8266 22.4327 16.4848 21.6208 16.4848H10.3792C9.5673 16.4848 8.90909 15.8266 8.90909 15.0147V7.09091ZM16 2.66667C13.5566 2.66667 11.5758 4.64747 11.5758 7.09091V13.8182H20.4242V7.09091C20.4242 4.64747 18.4434 2.66667 16 2.66667Z" fill="#678F68"/>
    </svg>



    </SvgIcon>
  );
}
