import SvgIcon from "@mui/material/SvgIcon";

export default function TicketsIcon() {
  return (
    <SvgIcon
    sx={{
        width: "100%",
        height: "100%"
      }}
    >
     <svg width="117" height="25" viewBox="0 -10 117 43" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="ticketsatwork 1" clip-path="url(#clip0_3927_10271)">
<g id="Group">
<g id="Group_2">
<path id="Vector" d="M87.2606 9.36116H89.3486V9.65242L86.3906 18.9437H84.1286L82.4466 13.5845C82.2436 13.0019 82.0986 12.4485 82.0116 11.866H81.9826C81.8956 12.4485 81.7216 13.0019 81.5476 13.5845L79.8656 18.9146H77.6036L74.6456 9.62329V9.33203H76.9076L78.3866 14.4582C78.5606 15.0408 78.7346 15.8854 78.8216 16.468H78.8506C78.9376 15.8854 79.1116 15.0408 79.2856 14.4582L80.8226 9.33203H83.2876L84.8246 14.4582C84.9986 15.0408 85.1726 15.8854 85.2596 16.468H85.2886C85.3756 15.8854 85.5496 15.0408 85.7236 14.4582L87.2606 9.36116Z" fill="#0A4753"/>
<path id="Vector_2" d="M110.055 14.3991V18.9137H107.735V5.51562H110.055V13.3797L113.332 9.36029H115.739V9.65155L112.404 13.7001L116.667 18.6515V18.9428H113.999L110.055 14.3991Z" fill="#0A4753"/>
<path id="Vector_3" d="M98.6286 11.0213C98.5996 10.9921 98.5996 10.963 98.5706 10.9339C98.5416 10.9048 98.5126 10.8756 98.4836 10.8174C97.7876 9.91446 96.7726 9.33194 95.6706 9.09893C95.6416 9.09893 95.5836 9.0698 95.5546 9.0698C95.4676 9.04067 95.3516 9.04067 95.2646 9.04067C95.1776 9.04067 95.1196 9.01155 95.0326 9.01155C94.9456 9.01155 94.8296 9.01155 94.7426 8.98242C94.6846 8.98242 94.6266 8.98242 94.5686 8.98242C94.5686 8.98242 94.5686 8.98242 94.5396 8.98242C92.8576 8.98242 91.2916 9.7397 90.3926 11.1669C89.8126 12.0115 89.4646 13.031 89.4646 14.1378C89.4646 15.2154 89.8126 16.2348 90.3926 17.0795C91.2916 18.5067 92.8866 19.2931 94.5686 19.2931C94.5686 19.2931 94.5686 19.2931 94.5976 19.2931C94.6556 19.2931 94.7136 19.2931 94.7716 19.2931C94.8586 19.2931 94.9746 19.2931 95.0616 19.264C95.1486 19.264 95.2066 19.2348 95.2936 19.2348C95.3806 19.2348 95.4966 19.2057 95.5836 19.1766C95.6126 19.1766 95.6706 19.1475 95.6996 19.1475C96.8016 18.9145 97.8166 18.3028 98.5416 17.3999C98.5996 17.3416 98.6286 17.2834 98.6866 17.2251C99.3246 16.3805 99.7306 15.3028 99.7306 14.1378C99.6726 12.9727 99.2956 11.895 98.6286 11.0213ZM94.5686 17.196C92.8866 17.196 91.5236 15.8271 91.5236 14.1378C91.5236 12.4484 92.8866 11.0795 94.5686 11.0795C96.2506 11.0795 97.6136 12.4484 97.6136 14.1378C97.6136 15.8271 96.2506 17.196 94.5686 17.196Z" fill="#0A4753"/>
<path id="Vector_4" d="M103.298 18.9723H100.978V9.30239H103.124V11.1665H103.153C103.414 9.91404 104.574 9.24414 105.821 9.24414C106.024 9.24414 106.401 9.27327 106.604 9.30239V11.6034H105.734C103.53 11.6034 103.298 12.8267 103.298 14.3704V18.9723Z" fill="#0A4753"/>
</g>
<g id="Group_3">
<path id="Vector_5" d="M34.1906 14.8652C34.3356 16.205 35.2636 16.9914 36.6846 16.9914C37.9606 16.9914 38.6856 16.3215 39.2076 15.4769L41.0636 16.6128C40.4546 17.8652 39.1206 19.2342 36.6846 19.2342C33.6686 19.2342 31.5516 17.2536 31.5516 14.0788C31.5516 11.0788 33.5816 8.86523 36.4816 8.86523C39.4976 8.86523 41.3826 10.8458 41.3826 13.8458C41.3826 14.0788 41.3826 14.4575 41.3536 14.8652H34.1906ZM34.2486 12.972H38.7726C38.6566 11.8361 37.8156 10.9914 36.4526 10.9914C35.1766 10.9623 34.3646 11.807 34.2486 12.972Z" fill="#0A4753"/>
<path id="Vector_6" d="M6.84363 9.12783V10.9628H4.37863V16.0016C4.37863 16.8754 4.87163 17.1084 5.45163 17.1084C5.97363 17.1084 6.40863 16.9337 6.81463 16.6715H7.13363V18.5938C6.64063 18.856 5.68363 19.089 5.16163 19.089C3.13163 19.089 2.00063 18.0696 2.00063 15.7977V10.9919H0.666626V9.91423C2.17463 9.18608 2.84163 8.10841 3.33463 6.94336H4.37863V9.15695H6.84363V9.12783Z" fill="#0A4753"/>
<path id="Vector_7" d="M14.2386 14.0495C14.2386 15.8554 15.1376 17.2534 16.8776 17.2534C18.1536 17.2534 18.9366 16.7 19.1686 15.3311H21.2856V15.535C21.0246 17.9524 19.1106 19.234 16.8196 19.234C14.0066 19.234 11.8896 17.1078 11.8896 14.1952V13.8748C11.8896 11.0204 13.9196 8.83594 16.7616 8.83594C19.1686 8.83594 20.9956 10.1175 21.2856 12.535V12.7389H19.1686C18.9076 11.4573 18.1826 10.8165 16.8486 10.8165C15.1666 10.7874 14.2386 12.2146 14.2386 14.0495Z" fill="#0A4753"/>
<path id="Vector_8" d="M25.1136 14.312V18.9139H22.7356V5.22461H25.1136V13.2343L28.4486 9.12752H30.9136V9.41878L27.5206 13.5838L31.8706 18.6227V18.9139H29.1446L25.1136 14.312Z" fill="#0A4753"/>
<path id="Vector_9" d="M47.7626 9.12783V10.9628H45.2976V16.0016C45.2976 16.8754 45.7906 17.1084 46.3706 17.1084C46.8926 17.1084 47.3276 16.9337 47.7336 16.6715H48.0526V18.5938C47.5596 18.856 46.6026 19.089 46.0806 19.089C44.0506 19.089 42.9196 18.0696 42.9196 15.7977V10.9919H41.5856V9.91423C43.0936 9.18608 43.7606 8.10841 44.2536 6.94336H45.2976V9.15695H47.7626V9.12783Z" fill="#0A4753"/>
<path id="Vector_10" d="M52.6636 8.89453C54.6646 8.89453 56.2886 9.73919 56.4046 11.6906V11.8945H54.2586C54.2006 10.9916 53.6206 10.6712 52.6636 10.6712C51.7356 10.6712 51.2716 11.0499 51.2716 11.6906C51.2716 12.1858 51.6196 12.5644 52.1996 12.6809C53.3306 12.9431 54.9836 13.0596 55.9116 14.0207C56.3756 14.4868 56.6946 15.244 56.6946 16.0013V16.0887C56.6946 18.4479 54.5776 19.2635 52.8086 19.2635C50.6336 19.2635 48.9806 18.2732 48.8646 16.0305V15.8266H51.0976C51.0976 16.9625 51.6486 17.4576 52.8376 17.4576C53.8526 17.4576 54.4036 16.9625 54.4036 16.2926C54.4036 15.7974 54.1426 15.3314 53.3016 15.0984C52.3736 14.8363 50.6626 14.778 49.7056 13.8169C49.2996 13.4091 48.9516 12.7101 48.9516 11.9819V11.8654C48.9516 9.73919 50.8946 8.89453 52.6636 8.89453Z" fill="#0A4753"/>
<path id="Vector_11" d="M8.40963 18.9139V9.12752H10.7876V18.9139H8.40963ZM9.59863 5.22461C8.78663 5.22461 8.11963 5.89451 8.11963 6.71005C8.11963 7.52558 8.78663 8.19548 9.59863 8.19548C10.4106 8.19548 11.0776 7.52558 11.0776 6.71005C11.0776 5.89451 10.4106 5.22461 9.59863 5.22461Z" fill="#0A4753"/>
</g>
<path id="Vector_12" d="M61.9146 19.2625C60.0876 19.2625 58.6086 18.0684 58.6086 16.2917V16.146C58.6086 15.2722 58.8986 14.6315 59.4206 14.1072C60.1456 13.3499 61.5376 13.0004 62.6686 13.0004C63.3646 13.0004 64.1186 13.0878 64.7566 13.2625V12.5635C64.7566 11.282 64.1186 10.4955 62.6686 10.4955C61.5666 10.4955 60.8126 10.9616 60.7546 12.0392H59.1016V11.8936C59.3046 9.88389 60.8416 9.06836 62.7266 9.06836C64.9016 9.06836 66.4676 10.0878 66.4676 12.9421V18.9713H65.1626L64.8436 17.9227H64.8146C64.1766 18.7383 63.0456 19.2625 61.9146 19.2625ZM64.7566 15.4761V14.7188C64.1766 14.515 63.4226 14.3985 62.7556 14.3985C61.5956 14.3985 60.3776 14.8936 60.3776 16.146C60.3776 17.2237 61.1316 17.8062 62.2336 17.8062C63.3066 17.7771 64.7566 16.8742 64.7566 15.4761Z" fill="#0A4753"/>
<path id="Vector_13" d="M72.7896 9.36042V10.7876H70.1216V16.176C70.1216 17.3119 70.7596 17.6323 71.4556 17.6323C71.9486 17.6323 72.4416 17.4866 72.8186 17.1954H73.1376V18.6808C72.6736 18.9429 71.8616 19.1468 71.3106 19.1468C69.2516 19.1468 68.3526 18.0109 68.3526 16.2342V10.7876H66.9896V9.94294C68.4396 9.4478 68.9906 8.2245 69.2516 7.20508H70.1216V9.38954H72.7896V9.36042Z" fill="#0A4753"/>
</g>
</g>
<defs>
<clipPath id="clip0_3927_10271">
<rect width="116" height="24" fill="white" transform="translate(0.666626 0.244141)"/>
</clipPath>
</defs>
</svg>

    </SvgIcon>
  );
}
