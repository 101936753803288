import * as React from "react";

import IconRepeater from "./IconRepeater.tsx";
import BookTrustedHelpers from "../../components/BookTrustedHelpers.tsx";
import WeCanHelp from "./WeCanHelpCopy.tsx";
import { Box } from "@mui/material";
import Header from "./NoHelperHeader.tsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Navbar from "../../components/Navbar.tsx";
import { Helmet } from "react-helmet";
import SimpleFooter from "../../components/SimpleFooter.tsx";
import { Link } from "react-router-dom";


interface DashboardProps {}

const MiniHome: React.FC<DashboardProps> = () => {
  const theme = useTheme();
  const headerimg = require("../../assets/hedimg.png");
  const promo = require("../../assets/Partners/CompressedWebP/BannerDesktop.webp");
  const promoMbl = require("../../assets/Partners/CompressedWebP/BannerMobile.webp");
  const promoTablet = require("../../assets/Partners/CompressedWebP/BannerTablet.webp");

  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("lg"));

  const [utm, setUTM] = React.useState(
    "/?utm_campaign=social_launch_24&utm_source=facebook&utm_medium=ad&utm_content=familyvideo3&utm_id=marketing"
  );
  React.useEffect(() => {
    if (window.location.href.includes("?utm")) {
      setUTM(`?${window.location.href.split("?").pop()}`);
    }
  }, [])
  
  return (
    <>
    
      <Helmet>
        <title>Get Started</title>
        <meta
          name="description"
          content="Book a Helper today! Get affordable, background-checked help for seniors—choose one-time or ongoing help with everyday tasks. No commitments, no subscriptions. "
        />
      </Helmet>
      <Box
        sx={{
          // height: "fit-content",
          width: "100%",
          backgroundColor: "#FAF8F5",

          maxWidth: "100vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "0 ",
        }}
      >
        <Navbar color="white" />
        <Link to={`https://app.herewith.com/sign-up${utm}`}>
        <img
          src={promo}
          alt="promotional banner - get a free hour of service"
          loading="lazy"
          style={{ display: phone || tablet ? "none" : "block", width: "100%" }}
        />
      </Link>

      <Link to={`https://app.herewith.com/sign-up${utm}`}>
        <img
          src={promoTablet}
          alt="promotional banner - get a free hour of service"
          loading="lazy"
          style={{
            display: phone ? "none" : tablet ? "flex" : "none",
            width: "100%",
          }}
        />
      </Link>

      <Link to={`https://app.herewith.com/sign-up${utm}`}>
        <img
          src={promoMbl}
          alt="promotional banner - get a free hour of service"
          loading="lazy"
          style={{
            display: phone ? "block" : "none",
            borderBottom: "2px solid white",
            width: "100%",
          }}
        />
      </Link>
      <Header headerTextBeforeSpan="Book" headerTextSpan=" in-home help " headerTextAfterSpan="for seniors, at half the cost of care agencies" buttonText="Book a Helper"/>

        <IconRepeater />
        <React.Suspense>
          <BookTrustedHelpers 
            subtext1={<> <strong style={{color: "#0C3D4C"}}> Herewith Helpers are background-checked </strong> and enjoy working with older adults. 
            Send a message to request help around the home, basic tech assistance, and more.</> }
            subtext2="Loved your experience? Re-book the same Helper again!"
          />
        </React.Suspense>
        <WeCanHelp />
        <SimpleFooter />
      </Box>
    </>
  );
};

export default MiniHome;
