import * as React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";



interface ButtonProps {
    link: string,
    text: string,
    buttonColor?: string,
    textColor?: string,

}
const SlideButton: React.FC<ButtonProps> = ({link, text, buttonColor="#FDBB2E", textColor="black"}) => {
    return(
        <Link to={link}>
            <Button
              variant="contained"
              endIcon={<ArrowForwardIcon sx={{ fontSize: "1.5rem" }} />}
              sx={{
                width: "fit-content",
                height: "3rem",
                fontSize: "18px",
                backgroundColor: buttonColor,
                color: textColor,
                marginTop: "2rem",
                boxShadow: "none !important", // Remove box shadow
                transition: "background 0.5s ease", // Smooth transition for background color
                "&:hover": {
                    background: "#E59101",
                    "& #buttontext": {
                        paddingRight: "5px", // Shift text on hover
                    },
                    },
                    "& #buttontext": {
                    transition: "padding-right 0.5s ease", // Smooth return when hover ends
                },
            }}
            >
              <span id="buttontext">{text}</span>
            </Button>
          </Link>
    );
};

export default SlideButton;