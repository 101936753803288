import * as React from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";

import CircleHatIcon from "../../assets/icons/CircleHatIcon.tsx";
import CircleHeartIcon from "../../assets/icons/CircleHeartIcon.tsx";
import CircleBagIcon from "../../assets/icons/CircleBagIcon.tsx";
import CircleCalendarIcon from "../../assets/icons/CircleCalendarIcon.tsx";
import Divider from "@mui/material/Divider";

import { useTheme, createTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box } from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#05242A",
  ...theme.typography.body2,
  padding: ".25rem",
  textAlign: "center",
  color: theme.palette.text.secondary,
  display: "flex",
  flexDirection: "row",
  width: "20%",
  height: "5rem",
  justifyContent: "space-evenly",
  alignItems: "center",
}));

export default function IconRepeater() {
  const theme = useTheme();
  const newBp = createTheme({
    breakpoints: {
      values: {
        xs: 370,
        sm: 600,
        md: 900,
        lg: 20000,
        xl: 760,
      },
    },
  });

  const xsPhone = useMediaQuery(newBp.breakpoints.down("xs"));
  const phone = useMediaQuery(newBp.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.down("lg"));
  const teal = require("../../assets/icons/CircleCalendarIcon.tsx");
  const bgimage = require("../../assets/Home/CompressedWebP/MobileIconRepeater.webp");
  return (
    <>
      {/* phone */}

        <Stack
          direction="column"
          spacing={0}
          sx={{
            display: phone ? "flex" : "none",
            width: "100%",
            padding: "0 10%",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 auto",
            backgroundColor: "#05242A",
          }}
        >
          <Stack sx={{
            flexDirection: xsPhone ? "column": "row",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            }}>
            <Item
              sx={{ backgroundColor: "#05242A", height: xsPhone ? "8.5rem": "9rem", width: phone ? "100%" : "15vw", flexDirection:"column", padding: "8% 0", displey: "flex",gap: "12px"}}
              elevation={0}
            >
              <Box sx={{width: "48px", height: "48px"}}><CircleHatIcon /></Box>
              <p style={{ width: "100%", textAlign: "center", fontSize: "14px", color:"#90C8D4", padding: "0 5px"}}>
                No healthcare experience required
              </p>
            </Item>
            <Divider flexItem variant="fullWidth" orientation={xsPhone ? "horizontal": "vertical"} sx={{height: xsPhone ? "" : "38vw", width: xsPhone ? "100%" : "", opacity:"0.5", borderColor: "#0A4753", color: "#90C8D4"  }}/>
            <Item
              sx={{ backgroundColor: "#05242A", height: xsPhone ? "8.5rem": "9rem", width: phone ? "100%" : "15vw", flexDirection:"column", padding: "8% 0", displey: "flex",gap: "12px"}}
              elevation={0}
            >
              <Box sx={{width: "48px", height: "48px"}}><CircleCalendarIcon /></Box>
              <p style={{ width: "100%", textAlign: "center", fontSize: "14px", color:"#90C8D4", padding: "0 5px"}}>
                Book task-based gig work
              </p>
            </Item>
          </Stack>
          <Divider flexItem variant="fullWidth" sx={{width:"100%", opacity:"0.5", borderColor: "#0A4753"}}/>
          <Stack sx={{
            flexDirection: xsPhone ? "column": "row",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}>
            <Item
              sx={{backgroundColor: "#05242A", height: xsPhone ? "8.5rem": "9rem",  flexDirection:"column", padding: "8% 0", displey: "flex",gap: "12px", width: "100%" }}
              elevation={0}
            > 
              <Box sx={{width: "48px", height: "48px"}}><CircleBagIcon /></Box>
              <p style={{ width: "100%",textAlign: "center", fontSize: "14px", color:"#90C8D4", padding: "0 5px" }}>
                Find private senior clients
              </p>
            </Item>
            <Divider flexItem orientation={xsPhone ? "horizontal": "vertical"} sx={{height: xsPhone ? "" : "35vw", width: xsPhone ? "100%" : "", opacity:"0.5", borderColor: "#0A4753"}}/>
            <Item
              sx={{ backgroundColor: "#05242A", height: xsPhone ? "8.5rem": "9rem", width:"100%", flexDirection:"column", padding: "8% 0", displey: "flex",gap: "12px"}}
              elevation={0}
            >
              <Box sx={{width: "48px", height: "48px"}}><CircleHeartIcon/></Box>
              <p style={{ width: "100%", textAlign: "center", fontSize: "14px", color:"#90C8D4", padding: "0 5px" }}>
                Always free for Helpers
              </p>
            </Item>
          </Stack>
        </Stack>

      {/* desktop */}
      <div
        style={{
          display: phone ? "none" : tablet ? "none" : "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#05242A",
          width: "100%",
          minHeight: "7rem",
          [theme.breakpoints.down("sm")]: {
            display: "none !important",
          },
        }}
      >
        <Stack
          direction="row"
          spacing={0}
          sx={{
            width: "90%",
            gap: "32px",
            margin: "0 auto",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Item
            sx={{ backgroundColor: "#05242A", height: "6rem", width:"fit-content"  }}
            elevation={0}
          >
            <Box sx={{width: "48px"}}>
              <CircleHatIcon />
            </Box>
            
            <p style={{ width: tablet ? "100%" : "15vw", textAlign: "left", fontSize: "1.1rem", color: "#90C8D4", paddingLeft: "16px" }}>
              No healthcare experience required
            </p>
          </Item>
          <Item
            sx={{ backgroundColor: "#05242A", height: "6rem", width:"fit-content", color: "#90C8D4"  }}
            elevation={0}
          > 
            <Box sx={{width: "48px"}}>
              <CircleCalendarIcon />
            </Box>
            <p style={{ width: tablet ? "100%" : "15vw", textAlign: "left", fontSize: "1.1rem", color: "#90C8D4", paddingLeft: "16px" }}>
              Book task-based gig work
            </p>
          </Item>
          <Item
            sx={{ backgroundColor: "#05242A", height: "6rem", width:"15rem" }}
            elevation={0}
          >
            <Box sx={{width: "50px", minWidth:"50px"}}>
              <CircleBagIcon/>
            </Box>
            
            <p style={{ width: tablet ? "100%" : "15vw", textAlign: "left", fontSize: "1.1rem", color: "#90C8D4", paddingLeft: "16px" }}>
              Find private senior clients
            </p>
          </Item>
          <Item
            sx={{ backgroundColor: "#05242A", height: "6rem", width:"max-content" }}
            elevation={0}
          >
            <Box sx={{width: "48px"}}>
              <CircleHeartIcon />
            </Box>
            <p style={{ width: tablet ? "100%" : "15vw", textAlign: "left", fontSize: "1.1rem", color: "#90C8D4", paddingLeft: "16px" }}>
              Always free for Helpers
            </p>
          </Item>
        </Stack>
      </div>

      {/* tablet */}
      <div
        style={{
          display: phone ? "none" : tablet ? "flex" : "none",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "#05242A",
          width: "100%",
          minHeight: "11rem",
          [theme.breakpoints.down("sm")]: {
            display: "none !important",
          },
        }}
      >

        <Stack
          direction="row"
          spacing={0}
          sx={{
            width: "100%",
            justifyContent: "space-evenly",
            margin: "0 auto",
          }}
        >
          <Item
            sx={{
              backgroundColor: "#05242A",
              height: "8.2rem",
              display: "flex",
              flexDirection: "column",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
            elevation={0}
          >
            <Box sx={{width: "48px", height: "48px"}}><CircleHatIcon /></Box>
            <p style={{ width: "150px", textAlign: "center", fontSize: "1rem", color: "#90C8D4" }}>
              No healthcare experience required
            </p>
          </Item>
          <Divider orientation="vertical" flexItem sx={{opacity:"0.5", borderColor: "#0A4753"}}/>
          <Item
            sx={{
              backgroundColor: "#05242A",
              height: "8.2rem",
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
            elevation={0}
          >
            <Box sx={{width: "48px", height: "48px"}}>
              <CircleCalendarIcon />
            </Box>
            <p style={{ width: "150px", textAlign: "center", fontSize: "1rem", color: "#90C8D4" }}>
              Book task-based gig work
            </p>
          </Item>
          <Divider orientation="vertical" flexItem sx={{opacity:"0.5", borderColor: "#0A4753"}}/>
          <Item
            sx={{
              backgroundColor: "#05242A",
              height: "8.2rem",
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
            elevation={0}
          >
            <Box sx={{width: "48px", height: "48px"}}><CircleBagIcon /></Box>
            <p style={{ width: "150px", textAlign: "center", fontSize: "1rem", color: "#90C8D4" }}>
              Find private senior clients
            </p>
          </Item>
          <Divider orientation="vertical" flexItem sx={{opacity:"0.5", borderColor: "#0A4753"}}/>
          <Item
            sx={{
              backgroundColor: "#05242A",
              height: "8.2rem",
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
            elevation={0}
          >
            <Box sx={{width: "48px", height: "48px"}}><CircleHeartIcon /></Box>
            <p style={{ width: "150px", textAlign: "center", fontSize: "1rem", color: "#90C8D4" }}>
              Always free for Helpers
            </p>
          </Item>
        </Stack>
      </div>
    </>
  );
}
