import SvgIcon from "@mui/material/SvgIcon";

export default function PurpleBagIcon() {
  return (
    <SvgIcon
    sx={{
        width: "100%",
        height: "100%"
      }}
    >
     <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.9">
<path opacity="0.5" d="M1.33301 9.33329C1.33301 7.86053 2.52692 6.66663 3.99967 6.66663H27.9997C29.4724 6.66663 30.6663 7.86053 30.6663 9.33329V25.3333C30.6663 26.8061 29.4724 28 27.9997 28H3.99967C2.52691 28 1.33301 26.8061 1.33301 25.3333V9.33329Z" fill="#795EC6"/>
<path d="M12 0C9.79086 0 8 1.79086 8 4V6.66667H10.6667V4C10.6667 3.26362 11.2636 2.66667 12 2.66667H20C20.7364 2.66667 21.3333 3.26362 21.3333 4V6.66667H24V4C24 1.79086 22.2091 0 20 0H12Z" fill="#795EC6"/>
<path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" d="M3.33301 6.66663C2.22844 6.66663 1.33301 7.56206 1.33301 8.66663V15.3333C1.33301 16.4379 2.22844 17.3333 3.33301 17.3333H10.6663V14.8333C10.6663 14.0049 11.3379 13.3333 12.1663 13.3333H19.833C20.6614 13.3333 21.333 14.0049 21.333 14.8333V17.3333H28.6663C29.7709 17.3333 30.6663 16.4379 30.6663 15.3333V8.66663C30.6663 7.56206 29.7709 6.66663 28.6663 6.66663H3.33301Z" fill="#795EC6"/>
<rect x="12" y="14.6666" width="8" height="5.33333" rx="1" fill="#795EC6"/>
</g>
</svg>


    </SvgIcon>
  );
}
