import SvgIcon from "@mui/material/SvgIcon";

export default function WorkingAdvIcon() {
  return (
    <SvgIcon
    sx={{
        width: "100%",
        height: "100%"
      }}
    >
     <svg width="94" height="55" viewBox="0 0 94 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Workingadvantage 1">
<g id="Group">
<path id="Vector" d="M33.2729 17.1479C33.2456 17.1119 33.2184 17.0759 33.1911 17.0399C33.1547 16.9949 33.1183 16.9499 33.0819 16.9049C32.1631 15.7529 30.8532 15.0059 29.4159 14.6999C29.3613 14.6909 29.3067 14.6729 29.2522 14.6639C29.1248 14.6369 29.0065 14.6279 28.8792 14.6099C28.7791 14.5919 28.6791 14.5829 28.579 14.5739C28.4516 14.5649 28.3152 14.5559 28.1878 14.5559C28.1151 14.5559 28.0423 14.5469 27.9604 14.5469C27.9513 14.5469 27.9513 14.5469 27.9422 14.5469C27.9331 14.5469 27.9331 14.5469 27.924 14.5469C25.7408 14.5469 23.6758 15.5279 22.5024 17.3549C21.7291 18.4349 21.2743 19.7489 21.2743 21.1709C21.2743 22.5749 21.72 23.8799 22.4751 24.9509C23.6485 26.7959 25.7226 27.7949 27.924 27.7949C27.9331 27.7949 27.9331 27.7949 27.9422 27.7949C27.9513 27.7949 27.9513 27.7949 27.9604 27.7949C28.0332 27.7949 28.106 27.7859 28.1878 27.7859C28.3243 27.7769 28.4516 27.7769 28.579 27.7679C28.6791 27.7589 28.7791 27.7409 28.8792 27.7319C29.0065 27.7139 29.1339 27.6959 29.2522 27.6779C29.3067 27.6689 29.3613 27.6509 29.4159 27.6419C30.8805 27.3359 32.1995 26.5709 33.1183 25.4009L33.1274 25.3919C33.1911 25.3199 33.2456 25.2389 33.3002 25.1579C34.1462 24.0509 34.6466 22.6739 34.6466 21.1889C34.6466 19.6499 34.128 18.2549 33.2729 17.1479ZM27.9604 25.0949C25.7499 25.0949 23.9669 23.3219 23.9669 21.1439C23.9669 18.9569 25.759 17.1929 27.9604 17.1929C30.1618 17.1929 31.9539 18.9659 31.9539 21.1439C31.9539 23.3309 30.1618 25.0949 27.9604 25.0949Z" fill="#0A4753"/>
<path id="Vector_2" d="M88.9816 27.542C88.0901 26.831 86.8712 26.471 85.3611 26.471H82.4501C81.5404 26.471 81.0765 26.111 81.0765 25.391C81.0765 24.959 81.2857 24.608 81.686 24.338C82.3682 24.617 83.1142 24.779 83.9056 24.779C86.9985 24.779 89.5001 22.394 89.5001 19.451C89.5001 16.508 86.9985 14.123 83.9056 14.123C80.8127 14.123 78.3111 16.508 78.3111 19.451C78.3111 20.765 78.8114 21.962 79.6301 22.889C78.493 23.564 77.9108 24.545 77.9108 25.814C77.9108 26.678 78.1564 27.398 78.6386 27.965L78.6659 28.001L78.6295 28.028C77.8653 28.721 77.4833 29.657 77.4833 30.791C77.4833 32.258 78.0837 33.41 79.2571 34.193C80.4397 34.985 81.9953 35.39 83.8874 35.39C85.0063 35.39 86.0433 35.228 86.9712 34.913C87.89 34.598 88.6905 34.067 89.3455 33.32C89.9913 32.573 90.3279 31.646 90.3279 30.566C90.3188 29.261 89.864 28.244 88.9816 27.542ZM83.8874 16.445C85.5612 16.445 86.7438 17.723 86.7438 19.442C86.7438 21.161 85.6249 22.457 83.8874 22.457C82.1954 22.457 81.0583 21.161 81.0583 19.442C81.0583 17.741 82.159 16.445 83.8874 16.445ZM84.0239 32.6C83.005 32.6 82.1863 32.42 81.5677 32.051C80.9491 31.682 80.6399 31.16 80.6399 30.467C80.6399 29.873 80.749 29.423 80.9673 29.108C81.4495 29.189 82.0226 29.225 82.7048 29.225H84.7425C86.3617 29.225 87.1713 29.738 87.1713 30.764C87.1622 31.988 86.1161 32.6 84.0239 32.6Z" fill="#0A4753"/>
<path id="Vector_3" d="M18.1723 14.9062H20.9195V15.2843L17.0079 27.3802H14.0332L11.8136 20.4233C11.5589 19.6762 11.3588 18.9293 11.2314 18.1823H11.1768C11.0495 18.9293 10.8493 19.6762 10.5946 20.4233L8.37503 27.3802H5.40038L1.48877 15.2843V14.9062H4.46341L6.42832 21.5932C6.65574 22.3403 6.88316 23.4652 7.01051 24.2122H7.06509C7.16516 23.4652 7.41987 22.3403 7.64728 21.5932L9.66677 14.9062H12.9416L14.9611 21.5932C15.1885 22.3403 15.4159 23.4652 15.5433 24.2122H15.5979C15.6979 23.4652 15.9526 22.3403 16.1801 21.5932L18.1723 14.9062Z" fill="#0A4753"/>
<path id="Vector_4" d="M39.368 27.3796H36.3206V14.9056H39.1042L39.1406 17.2996H39.1951C39.5499 15.6796 41.06 14.8066 42.6701 14.8066C42.9248 14.8066 43.4251 14.8336 43.6798 14.8786V17.8486H42.5427C39.6409 17.8486 39.368 19.4236 39.368 21.4126V27.3796Z" fill="#0A4753"/>
<path id="Vector_5" d="M48.5101 21.4939V27.3799H45.4354V9.91992H48.5101V20.1439L52.8493 14.9059H56.0241V15.2839L51.6121 20.5669L57.2612 27.0019V27.3799H53.7317L48.5101 21.4939Z" fill="#0A4753"/>
<path id="Vector_6" d="M61.6093 14.9062H58.5345V27.3802H61.6093V14.9062Z" fill="#0A4753"/>
<path id="Vector_7" d="M64.3292 27.3793V14.9053H67.1401L67.1492 16.4263H67.2038C68.159 15.0763 69.4962 14.5273 70.9881 14.5273C73.7353 14.5273 75.6001 16.3993 75.6001 18.9643V27.3703H72.5709V19.8643C72.5709 17.9473 71.5339 17.0923 70.0511 17.0923C68.4137 17.0923 67.3766 17.9923 67.3766 19.8823V27.3613H64.3292V27.3793Z" fill="#0A4753"/>
<path id="Vector_8" d="M60.0719 13.5559C61.0868 13.5559 61.9095 12.742 61.9095 11.7379C61.9095 10.7339 61.0868 9.91992 60.0719 9.91992C59.0571 9.91992 58.2344 10.7339 58.2344 11.7379C58.2344 12.742 59.0571 13.5559 60.0719 13.5559Z" fill="#0A4753"/>
<g id="Group_2">
<path id="Vector_9" d="M7.11045 41.7244C5.59129 41.7244 4.33594 40.7434 4.33594 39.2674V39.1594C4.33594 38.4304 4.57245 37.9084 5.0091 37.4764C5.61858 36.8374 6.79207 36.5584 7.73813 36.5584C8.31123 36.5584 8.9571 36.6394 9.48471 36.7834V36.1984C9.48471 35.1364 8.9389 34.4884 7.73813 34.4884C6.82845 34.4884 6.18258 34.8844 6.1371 35.7754H4.75439V35.6494C4.92723 34.0024 6.20987 33.3184 7.80181 33.3184C9.63026 33.3184 10.9493 34.1554 10.9493 36.5044V41.4814H9.59387V40.6084H9.55749C9.02078 41.3014 8.05652 41.7244 7.11045 41.7244ZM9.48471 38.5834V37.9534C8.98439 37.7824 8.36581 37.6834 7.82 37.6834C6.83755 37.6834 5.81871 38.0974 5.81871 39.1234C5.81871 40.0144 6.44639 40.5004 7.37426 40.5004C8.28394 40.5094 9.48471 39.7444 9.48471 38.5834Z" fill="#0A4753"/>
<path id="Vector_10" d="M16.3072 33.3012C17.4261 33.3012 18.2812 33.7152 18.7906 34.3632H18.827V30.3672H20.2825V41.4732H18.9089L18.8907 40.5192H18.8543C18.2812 41.3292 17.1987 41.7252 16.1162 41.7252C14.224 41.7252 12.3137 40.3302 12.3137 37.6932V37.4592C12.3228 35.0832 13.7783 33.3012 16.3072 33.3012ZM13.7965 37.5222C13.7965 39.1602 14.6152 40.4922 16.38 40.4922C18.0993 40.4922 18.8816 39.1242 18.8816 37.5222C18.8816 35.9202 18.1266 34.5252 16.38 34.5252C14.5788 34.5252 13.7965 35.8392 13.7965 37.5222Z" fill="#0A4753"/>
<path id="Vector_11" d="M27.6964 33.5449H29.1519V33.7519L25.9135 41.4739H24.5035L21.265 33.7519V33.5449H22.7842L24.8219 38.7919C24.9219 39.0169 25.1766 39.7279 25.2585 39.9799H25.2949C25.3768 39.7279 25.5951 39.0259 25.6951 38.7919L27.6964 33.5449Z" fill="#0A4753"/>
<path id="Vector_12" d="M32.2265 41.7244C30.7074 41.7244 29.452 40.7434 29.452 39.2674V39.1594C29.452 38.4304 29.6885 37.9084 30.1252 37.4764C30.7347 36.8374 31.9082 36.5584 32.8542 36.5584C33.4273 36.5584 34.0732 36.6394 34.6008 36.7834V36.1984C34.6008 35.1364 34.055 34.4884 32.8542 34.4884C31.9445 34.4884 31.2987 34.8844 31.2532 35.7754H29.8705V35.6494C30.0433 34.0024 31.326 33.3184 32.9179 33.3184C34.7463 33.3184 36.0654 34.1554 36.0654 36.5044V41.4814H34.71L34.7009 40.6084H34.6645C34.1369 41.3014 33.1726 41.7244 32.2265 41.7244ZM34.6008 38.5834V37.9534C34.1005 37.7824 33.4819 37.6834 32.9361 37.6834C31.9536 37.6834 30.9348 38.0974 30.9348 39.1234C30.9348 40.0144 31.5625 40.5004 32.4903 40.5004C33.4 40.5094 34.6008 39.7444 34.6008 38.5834Z" fill="#0A4753"/>
<path id="Vector_13" d="M39.2947 41.4735H37.8392V33.5445H39.2128L39.2219 34.4805H39.2583C39.8496 33.6885 40.732 33.3105 41.6144 33.3105C42.5787 33.3105 43.3337 33.5805 43.8795 34.1475C44.3616 34.6425 44.6163 35.3985 44.6163 36.3885V41.4825H43.1699V36.6405C43.1699 35.8305 43.0244 35.3715 42.706 35.0565C42.3876 34.7415 41.9055 34.5795 41.3415 34.5795C40.6956 34.5795 40.1589 34.7505 39.7496 35.2005C39.4494 35.5155 39.3038 36.0375 39.3038 36.8385V41.4735H39.2947Z" fill="#0A4753"/>
<path id="Vector_14" d="M50.4385 33.5441V34.7141H48.2098V39.1691C48.2098 40.1051 48.7374 40.3751 49.3287 40.3751C49.7471 40.3751 50.1656 40.2491 50.484 40.0061H50.7387V41.2301C50.3566 41.4371 49.6835 41.6081 49.2013 41.6081C47.4729 41.6081 46.7361 40.6721 46.7361 39.2141V34.7141H45.5808V34.0121C46.7998 33.5981 47.2637 32.5991 47.4729 31.7441H48.2098V33.5441H50.4385Z" fill="#0A4753"/>
<path id="Vector_15" d="M54.4684 41.7244C52.9492 41.7244 51.6938 40.7434 51.6938 39.2674V39.1594C51.6938 38.4304 51.9304 37.9084 52.367 37.4764C52.9765 36.8374 54.15 36.5584 55.096 36.5584C55.6691 36.5584 56.315 36.6394 56.8426 36.7834V36.1984C56.8426 35.1364 56.2968 34.4884 55.096 34.4884C54.1864 34.4884 53.5405 34.8844 53.495 35.7754H52.1123V35.6494C52.2851 34.0024 53.5678 33.3184 55.1597 33.3184C56.9882 33.3184 58.3072 34.1554 58.3072 36.5044V41.4814H56.9609L56.9427 40.6084H56.9063C56.3696 41.3014 55.4144 41.7244 54.4684 41.7244ZM56.8426 38.5834V37.9534C56.3423 37.7824 55.7237 37.6834 55.1779 37.6834C54.1955 37.6834 53.1766 38.0974 53.1766 39.1234C53.1766 40.0144 53.8043 40.5004 54.7322 40.5004C55.6328 40.5094 56.8426 39.7444 56.8426 38.5834Z" fill="#0A4753"/>
<path id="Vector_16" d="M63.1284 44.5684C61.3182 44.5684 59.3715 43.9204 59.3715 42.4444V42.3454C59.3715 41.6164 59.8536 40.8874 60.4449 40.6354C60.172 40.3474 59.8991 39.9514 59.8991 39.4474C59.8991 38.8444 60.1902 38.4034 60.736 38.0344C60.2357 37.6204 59.9355 36.9904 59.9355 36.1444V35.9734C59.9355 34.1824 61.3818 33.2914 63.2103 33.2914C63.6288 33.2914 64.029 33.3364 64.3929 33.4354L65.6755 31.9414H67.1037V32.1484L65.5573 33.9664C66.1304 34.4074 66.4851 35.0734 66.4851 35.9644V36.1534C66.4851 37.8814 64.9933 38.7544 63.1466 38.7544C62.619 38.7544 62.1369 38.6914 61.7002 38.5474C61.3818 38.6914 61.2181 38.9884 61.2181 39.2584C61.2181 39.6724 61.4546 40.0054 62.0368 40.0054H64.3474C65.8848 40.0054 67.1037 40.7524 67.1037 41.9854V42.0934C67.1128 43.7944 65.0115 44.5684 63.1284 44.5684ZM65.6482 42.0934C65.6482 41.5894 65.2025 41.2654 64.22 41.2654H61.5911C61.1453 41.4274 60.8178 41.8054 60.8178 42.2824C60.8178 42.9664 61.6184 43.3804 63.1921 43.3804C64.6385 43.3804 65.6482 42.9664 65.6482 42.0934ZM61.3637 36.0454C61.3637 37.0804 62.0823 37.6204 63.2103 37.6204C64.3656 37.6204 65.0569 37.0804 65.0569 36.0454C65.0569 35.0464 64.3656 34.4704 63.2103 34.4704C62.0914 34.4794 61.3637 35.0464 61.3637 36.0454Z" fill="#0A4753"/>
<path id="Vector_17" d="M74.9724 39.251C74.5904 40.835 73.2531 41.726 71.4975 41.726C69.2688 41.726 67.6495 40.088 67.6495 37.613V37.388C67.6495 34.949 69.2051 33.293 71.452 33.293C73.8444 33.293 75.1089 35.12 75.1089 37.352C75.1089 37.577 75.1089 37.703 75.0907 37.937H69.1414C69.2688 39.395 70.0511 40.475 71.5339 40.475C72.58 40.475 73.2168 40.043 73.5352 39.242H74.9724V39.251ZM71.4884 34.526C70.0784 34.526 69.3688 35.426 69.1596 36.767H73.5988C73.517 35.687 72.9166 34.526 71.4884 34.526Z" fill="#0A4753"/>
</g>
<path id="Vector_18" d="M91.2498 15.5388C92.2579 15.1413 92.7495 14.0105 92.3478 13.0131C91.9461 12.0157 90.8031 11.5294 89.795 11.9268C88.7869 12.3243 88.2953 13.455 88.697 14.4525C89.0987 15.4499 90.2417 15.9362 91.2498 15.5388Z" fill="#0A4753"/>
</g>
</g>
</svg>

    </SvgIcon>
  );
}
