import * as React from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";

import PeopleIcon from "../../assets/icons/PeopleIcon.tsx";
import TimeIcon from "../../assets/icons/TimeIcon.tsx";
import SubscriptionIcon from "../../assets/icons/SubscriptionIcon.tsx";
import CheckIcon from "../../assets/icons/CheckIcon.tsx";
import Divider from "@mui/material/Divider";

import { useTheme, createTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: ".25rem",
  textAlign: "center",
  color: theme.palette.text.secondary,
  display: "flex",
  flexDirection: "row",
  width: "20%",
  height: "5rem",
  justifyContent: "space-evenly",
  alignItems: "center",
}));

export default function IconRepeater() {
  const theme = useTheme();
  const newBp = createTheme({
    breakpoints: {
      values: {
        xs: 370,
        sm: 700,
        md: 900,
        lg: 20000,
        xl: 760,
      },
    },
  });

  const xsPhone = useMediaQuery(newBp.breakpoints.down("xs"));
  const phone = useMediaQuery(newBp.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.down("lg"));
  const teal = require("../../assets/icons/CheckIcon.tsx");
  const bgimage = require("../../assets/Home/CompressedWebP/MobileIconRepeater.webp");
  return (
    <>
      {/* phone */}

        <Stack
          direction="column"
          spacing={0}
          sx={{
            display: phone ? "flex" : "none",
            width: "100%",
            paddingTop: "32px",

            justifyContent: "center",
            alignItems: "center",
            margin: "0 auto",
            gap: "3vw"
          }}
        >
          <Stack sx={{
            flexDirection: xsPhone ? "column": "row",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            gap: xsPhone ? "3vw" : "15vw",
            }}>
            <Item
              sx={{ backgroundColor: "#FFFFFF", height: xsPhone ? "7rem": "9rem", width:"15vw", flexDirection:"column"}}
              elevation={0}
            >
              <PeopleIcon />
              <p style={{ width: "200px", textAlign: "center", fontSize: "1.1rem", color:"#4F786B"}}>
                Choose your own Helper
              </p>
            </Item>
            <Divider orientation={xsPhone ? "horizontal": "vertical"} sx={{height: xsPhone ? "" : "35vw", width: xsPhone ? "80vw" : "", opacity: "0.5", marginBottom: xsPhone ? "" : "-3vw"  }}/>
            <Item
              sx={{ backgroundColor: "#FFFFFF", height: xsPhone ? "7rem": "9rem", width:"15vw", flexDirection:"column"}}
              elevation={0}
            >
              <CheckIcon />
              <p style={{ width: "200px", textAlign: "center", fontSize: "1.1rem", color:"#996076"}}>
                100% Background Checked
              </p>
            </Item>
          </Stack>
          <Divider variant="middle" sx={{width:"80vw", opacity:"0.5"}}/>
          <Stack sx={{
            flexDirection: xsPhone ? "column": "row",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            gap: xsPhone ? "0vw" : "15vw",
          }}>
            <Item
              sx={{ backgroundColor: "#FFFFFF", height: xsPhone ? "7rem": "9rem", width:"15vw", flexDirection:"column"}}
              elevation={0}
            >
              <SubscriptionIcon />
              <p style={{ width: "200px", textAlign: "center", fontSize: "1.1rem", color:"#614B9E" }}>
                No commitments or subscriptions
              </p>
            </Item>
            <Divider orientation={xsPhone ? "horizontal": "vertical"} sx={{height: xsPhone ? "" : "35vw", width: xsPhone ? "80vw" : "", opacity: "0.5", marginTop: xsPhone ? "" : "-3vw" }}/>
            <Item
              sx={{ backgroundColor: "#FFFFFF", height: xsPhone ? "7rem": "9rem", width:"15vw", flexDirection:"column"}}
              elevation={0}
            >
              <TimeIcon/>
              <p style={{ width: "200px", textAlign: "center", fontSize: "1.1rem", color:"#3C583D" }}>
                Live customer support
              </p>
            </Item>
          </Stack>
        </Stack>

      {/* desktop */}
      <div
        style={{
          display: phone ? "none" : tablet ? "none" : "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          borderTop: "1px solid #EBE9E6",
          borderBottom: "1px solid #EBE9E6",
          backgroundColor: "#FFFFFF",
          width: "100%",
          minHeight: "7rem",
          [theme.breakpoints.down("sm")]: {
            display: "none !important",
          },
        }}
      >
        <Divider
          variant="fullWidth"
          sx={{ color: "black", border: "black", zIndex: "1000" }}
        />
        <hr />
        <Stack
          direction="row"
          spacing={0}
          sx={{
            width: "90%",

            justifyContent: "space-evenly",
            margin: "0 auto",
          }}
        >
          <Item
            sx={{ backgroundColor: "#FFFFFF", height: "6rem", width:"15rem"  }}
            elevation={0}
          >
            <PeopleIcon />
            <p style={{ width: "150px", textAlign: "left", fontSize: "1.1rem" }}>
              Choose your own Helper
            </p>
          </Item>
          <Item
            sx={{ backgroundColor: "#FFFFFF", height: "6rem", width:"15rem"  }}
            elevation={0}
          >
            <CheckIcon />
            <p style={{ width: "150px", textAlign: "left", fontSize: "1.1rem" }}>
              100% Background Checked
            </p>
          </Item>
          <Item
            sx={{ backgroundColor: "#FFFFFF", height: "6rem", width:"15rem" }}
            elevation={0}
          >
            <SubscriptionIcon />
            <p style={{ width: "150px", textAlign: "left", fontSize: "1.1rem" }}>
              No commitments or subscriptions
            </p>
          </Item>
          <Item
            sx={{ backgroundColor: "#FFFFFF", height: "6rem", width:"15rem" }}
            elevation={0}
          >
            <TimeIcon />
            <p style={{ width: "150px", textAlign: "left", fontSize: "1.1rem" }}>
            Live customer support
            </p>
          </Item>
        </Stack>
        <Divider variant="fullWidth" sx={{ opacity: "0.5" }} />
      </div>

      {/* tablet */}
      <div
        style={{
          display: phone ? "none" : tablet ? "flex" : "none",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          borderTop: "1px solid #EBE9E6",
          borderBottom: "1px solid #EBE9E6",
          backgroundColor: "#FFFFFF",
          width: "100%",
          minHeight: "11rem",
          [theme.breakpoints.down("sm")]: {
            display: "none !important",
          },
        }}
      >
        <Divider
          variant="fullWidth"
          sx={{ color: "black", border: "black", zIndex: "1000" }}
        />
        <hr />
        <Stack
          direction="row"
          spacing={0}
          sx={{
            width: "100%",
            justifyContent: "space-evenly",
            margin: "0 auto",
          }}
        >
          <Item
            sx={{
              backgroundColor: "#FFFFFF",
              height: "8.2rem",
              display: "flex",
              flexDirection: "column",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
            elevation={0}
          >
            <PeopleIcon />
            <p style={{ width: "150px", textAlign: "center", fontSize: "1rem" }}>
            Choose your own Helper
            </p>
          </Item>
          <Divider orientation="vertical" flexItem/>
          <Item
            sx={{
              backgroundColor: "#FFFFFF",
              height: "8.2rem",
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
            elevation={0}
          >
            <CheckIcon />
            <p style={{ width: "150px", textAlign: "center", fontSize: "1rem" }}>
              100% Background Checked
            </p>
          </Item>
          <Divider orientation="vertical" flexItem/>
          <Item
            sx={{
              backgroundColor: "#FFFFFF",
              height: "8.2rem",
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
            elevation={0}
          >
            <SubscriptionIcon />
            <p style={{ width: "150px", textAlign: "center", fontSize: "1rem" }}>
              No commitments or subscriptions
            </p>
          </Item>
          <Divider orientation="vertical" flexItem/>
          <Item
            sx={{
              backgroundColor: "#FFFFFF",
              height: "8.2rem",
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
            elevation={0}
          >
            <TimeIcon />
            <p style={{ width: "150px", textAlign: "center", fontSize: "1rem" }}>
            Live customer support
            </p>
          </Item>
        </Stack>
        <Divider variant="fullWidth" sx={{ opacity: "0.5" }} />
      </div>
    </>
  );
}
