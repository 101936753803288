import SvgIcon from "@mui/material/SvgIcon";

export default function HouseIcon({color="black"}) {
  return (
    <SvgIcon
      sx={{
        width: "inherit",
        flex: 1,
        height: "inherit",
      }}
    >
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.5" d="M1.33325 13V28C1.33325 28.7363 1.93021 29.3333 2.66659 29.3333H10.6437C11.7511 29.3333 12.6476 28.4335 12.6437 27.3262L12.6269 22.5567C12.6215 20.7157 14.1094 19.219 15.9504 19.2135C17.7913 19.2081 19.2881 20.6961 19.2935 22.537L19.3105 27.3404C19.3144 28.4422 20.2087 29.3333 21.3105 29.3333H29.3333C30.0696 29.3333 30.6666 28.7363 30.6666 28V13C30.6666 11.7409 30.0738 10.5554 29.0666 9.79996L17.5999 1.19996C16.6518 0.488848 15.3481 0.488848 14.3999 1.19996L2.93325 9.79996C1.92603 10.5554 1.33325 11.7409 1.33325 13Z" fill="#1D99BE"/>
            <circle cx="16" cy="12" r="4" fill="#1D99BE"/>
        </svg>

    </SvgIcon>
  );
}
