import SvgIcon from "@mui/material/SvgIcon";

export default function CartIcon() {
  return (
    <SvgIcon
    sx={{
        width: "100%",
        height: "100%"
      }}
    >
     <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" d="M2 4C2 2.89543 2.89543 2 4 2H7.43845C9.27392 2 10.8739 3.24919 11.319 5.02986L11.9403 7.51493C11.9468 7.54108 11.9528 7.56736 11.9583 7.59376L13.9583 17.2348C13.9649 17.2665 13.9707 17.2983 13.9757 17.3303L14.7101 22L28 22C29.1046 22 30 22.8954 30 24C30 25.1046 29.1046 26 28 26H14.7101C12.7408 26 11.0645 24.5667 10.7586 22.6214L10.0318 17.9997L8.04991 8.44585L7.43845 6H4C2.89543 6 2 5.10457 2 4Z" fill="#678F68"/>
<path d="M18 27C18 28.6569 16.6569 30 15 30C13.3431 30 12 28.6569 12 27C12 25.3431 13.3431 24 15 24C16.6569 24 18 25.3431 18 27Z" fill="#678F68"/>
<path d="M28 27C28 28.6569 26.6569 30 25 30C23.3431 30 22 28.6569 22 27C22 25.3431 23.3431 24 25 24C26.6569 24 28 25.3431 28 27Z" fill="#678F68"/>
<path d="M12.0391 8H29.6033C30.8511 8 31.7942 9.13009 31.571 10.3578L30.4801 16.3578C30.3072 17.3088 29.479 18 28.5124 18H14.0685L12.0391 8Z" fill="#678F68"/>
</svg>


    </SvgIcon>
  );
}
