import SvgIcon from "@mui/material/SvgIcon";

export default function SearchIcon() {
  return (
    <SvgIcon
      sx={{
        width: "inherit",
        flex: 1,
        height: "50%",
      }}
    >
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13.2928 13.2929C13.6834 12.9024 14.3165 12.9024 14.707 13.2929L19.7064 18.2922C20.0969 18.6828 20.0976 19.3166 19.707 19.7071C19.3165 20.0976 18.6834 20.0976 18.2928 19.7071L13.2928 14.7071C12.9023 14.3166 12.9023 13.6834 13.2928 13.2929Z"
          fill="#85827D"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.5 2C4.91015 2 2 4.91015 2 8.5C2 12.0899 4.91015 15 8.5 15C12.0899 15 15 12.0899 15 8.5C15 4.91015 12.0899 2 8.5 2ZM0 8.5C0 3.80558 3.80558 0 8.5 0C13.1944 0 17 3.80558 17 8.5C17 13.1944 13.1944 17 8.5 17C3.80558 17 0 13.1944 0 8.5Z"
          fill="#85827D"
        />
      </svg>
    </SvgIcon>
  );
}
