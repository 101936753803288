import videoSrc from "../utils/videoSource";
interface HomepageVideoProps {
    style: React.CSSProperties;
}

const HomepageVideo: React.FC<HomepageVideoProps> = ({style}) => {
    return(
        <video
        // controls
        playsInline
        autoPlay
        muted
        loop
        poster={require("../assets/pink.webp")}
        src={videoSrc}
        style={style}
      />
    );
};

export default HomepageVideo;