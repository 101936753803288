import SvgIcon from "@mui/material/SvgIcon";

export default function PeopleIcon() {
  return (
    <SvgIcon
      sx={{
        width: "inherit",
        flex: 1,
        height: "50%",
      }}
    >
      <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="48" height="48" rx="24" fill="#CEE9E1" />
        <path
          d="M28 19C28 21.2091 26.2091 23 24 23C21.7909 23 20 21.2091 20 19C20 16.7909 21.7909 15 24 15C26.2091 15 28 16.7909 28 19Z"
          fill="#6AA08F"
        />
        <path
          d="M18 18.5C18 19.6046 17.1046 20.5 16 20.5C14.8954 20.5 14 19.6046 14 18.5C14 17.3954 14.8954 16.5 16 16.5C17.1046 16.5 18 17.3954 18 18.5Z"
          fill="#6AA08F"
        />
        <path
          d="M34 18.5C34 19.6046 33.1046 20.5 32 20.5C30.8954 20.5 30 19.6046 30 18.5C30 17.3954 30.8954 16.5 32 16.5C33.1046 16.5 34 17.3954 34 18.5Z"
          fill="#6AA08F"
        />
        <path
          d="M24 25C20.415 25 17.3195 27.0961 15.8724 30.1297C14.9212 32.1236 16.7909 34 19 34H29C31.2091 34 33.0788 32.1236 32.1276 30.1297C30.6805 27.0961 27.585 25 24 25Z"
          fill="#4F786B"
        />
        <path
          d="M16.0001 21.5C14.4859 21.5 13.1682 22.3413 12.489 23.5821C11.9586 24.551 12.8955 25.5 14.0001 25.5H18.0001C19.1046 25.5 20.0415 24.551 19.5111 23.5821C18.8319 22.3413 17.5142 21.5 16.0001 21.5Z"
          fill="#4F786B"
        />
        <path
          d="M32.0001 21.5C30.4859 21.5 29.1682 22.3413 28.489 23.5821C27.9586 24.551 28.8955 25.5 30.0001 25.5H34.0001C35.1046 25.5 36.0415 24.551 35.5111 23.5821C34.8319 22.3413 33.5142 21.5 32.0001 21.5Z"
          fill="#4F786B"
        />
      </svg>
    </SvgIcon>
  );
}
