import * as React from "react";

import { Box } from "@mui/material";
import ArrowButton from "./ArrowButton";
interface DealProps {}

const PinkDeal: React.FC<DealProps> = ({}) => {
  const bgimg = require("../assets/PinkDealBg.png");
  return (
    <Box
      sx={{
        width: "100%",
        height: "10rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "5rem",
      }}
    >
      <Box
        sx={{
          width: "80%",
          height: "100%",
          backgroundImage: `url(${bgimg})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          borderRadius: "1rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 5rem 0 18rem",
        }}
      >
        <Box sx={{ flex: 1, textAlign: "left" }}>
          <h1 style={{ fontSize: "2rem" }}>
            New customers get $500 of help on us!
          </h1>
          <p>
            Id velit mauris facilisis cursus adipiscing eu nec. Nulla sed semper
            sit ultrices tincidunt sem pharetra.
          </p>{" "}
          <p style={{ fontSize: "12px", marginTop: "1rem" }}>
            Offer valid 06/01/24-07/01/24
          </p>
        </Box>
        <Box sx={{ width: "10rem" }}>
          <ArrowButton text="Claim Now" />
        </Box>
      </Box>
    </Box>
  );
};

export default PinkDeal;
