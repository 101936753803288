import SvgIcon from "@mui/material/SvgIcon";

export default function PlumBenIcon() {
  return (
    <SvgIcon
    sx={{
        width: "100%",
        height: "100%"
      }}
    >
     <svg width="111" height="20" viewBox="0 -13 111 43" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="plumbenefits 1" clip-path="url(#clip0_3927_9525)">
<g id="Group">
<path id="Vector" d="M49.5605 5.24485C48.1969 5.24485 46.9696 5.6994 46.1969 6.6994H46.1514V1.51758H44.3333V15.563H45.9696V14.3812H46.0151C46.6514 15.1994 47.9242 15.8812 49.2423 15.8812C52.4696 15.8812 54.2423 13.6539 54.2423 10.6539V10.2903C54.2878 7.01758 51.9242 5.24485 49.5605 5.24485ZM49.1969 14.3358C47.0151 14.3358 46.0605 12.563 46.0605 10.563C46.0605 8.51758 47.0605 6.83576 49.1969 6.83576C51.4242 6.83576 52.4242 8.51758 52.4242 10.563C52.4242 12.6539 51.4696 14.3358 49.1969 14.3358Z" fill="#0A4753"/>
<path id="Vector_2" d="M59.7877 5.24414C56.9696 5.24414 55.015 7.33505 55.015 10.426V10.6987C55.015 13.835 57.0605 15.8805 59.8332 15.8805C62.0605 15.8805 63.6968 14.7441 64.1968 12.7441H62.3786C61.9696 13.7441 61.1968 14.2896 59.8786 14.2896C58.015 14.2896 57.0605 12.926 56.8787 11.0623H64.3332C64.3332 10.7441 64.3332 10.6078 64.3332 10.3351C64.3787 7.56232 62.7877 5.24414 59.7877 5.24414ZM56.9241 9.60778C57.1968 7.92596 58.0605 6.78959 59.8332 6.78959C61.6059 6.78959 62.3786 8.24414 62.4696 9.60778H56.9241Z" fill="#0A4753"/>
<path id="Vector_3" d="M70.2878 5.24414C69.1969 5.24414 68.0605 5.74414 67.3333 6.74414H67.2878V5.56232H65.5605V15.6078H67.3787V9.69869C67.3787 8.69869 67.5605 8.01687 67.9242 7.65323C68.4242 7.10778 69.106 6.8805 69.9242 6.8805C70.606 6.8805 71.2424 7.06232 71.606 7.47141C72.0151 7.8805 72.1969 8.47141 72.1969 9.47141V15.6078H74.0151V9.10778C74.0151 7.8805 73.6969 6.8805 73.106 6.2896C72.4242 5.56232 71.4696 5.24414 70.2878 5.24414Z" fill="#0A4753"/>
<path id="Vector_4" d="M84.4242 10.3805C84.4242 7.56232 82.8333 5.24414 79.8333 5.24414C77.0151 5.24414 75.0605 7.33505 75.0605 10.426V10.6987C75.0605 13.835 77.106 15.8805 79.8787 15.8805C82.106 15.8805 83.7424 14.7441 84.2424 12.7441H82.4242C82.0151 13.7441 81.2424 14.2896 79.9242 14.2896C78.0606 14.2896 77.106 12.926 76.9242 11.0623H84.4242C84.4242 10.7896 84.4242 10.6532 84.4242 10.3805ZM77.0151 9.60778C77.2878 7.92596 78.1515 6.78959 79.9242 6.78959C81.6969 6.78959 82.4696 8.24414 82.5605 9.60778H77.0151Z" fill="#0A4753"/>
<path id="Vector_5" d="M84.6515 6.0174L86.1515 5.60831V4.69922C86.1515 2.42649 87.1969 1.19922 89.3333 1.19922C89.9242 1.19922 90.3333 1.29013 90.9242 1.47195V2.97195H90.606C90.1969 2.79013 89.7879 2.74467 89.4242 2.74467C88.3788 2.74467 87.9697 3.24467 87.9697 4.42649V5.47195H90.8788V6.97195H87.9697V15.5174H86.1515V7.0174H84.6515V6.0174Z" fill="#0A4753"/>
<path id="Vector_6" d="M101.606 5.51829V7.01829H98.8333V12.6547C98.8333 13.8365 99.5151 14.2001 100.242 14.2001C100.742 14.2001 101.288 14.0183 101.697 13.7456H102.015V15.2456C101.515 15.5183 100.697 15.7456 100.106 15.7456C97.9242 15.7456 97.0151 14.5637 97.0151 12.7001V7.01829H95.5605V6.15465C97.106 5.65465 97.6515 4.38192 97.9242 3.29102H98.8333V5.56374H101.606V5.51829Z" fill="#0A4753"/>
<path id="Vector_7" d="M102.47 12.5623V12.3805H104.197C104.197 13.6987 104.97 14.3805 106.515 14.3805C107.879 14.3805 108.561 13.835 108.561 12.8805C108.561 12.4714 108.424 12.1987 108.197 11.926C107.242 10.9714 104.697 11.2441 103.379 9.97141C103.015 9.60778 102.651 8.92596 102.651 8.24414V8.10778C102.651 6.06232 104.47 5.24414 106.288 5.24414C108.242 5.24414 109.924 6.15323 109.97 8.06232V8.24414H108.242C108.197 7.06232 107.424 6.69869 106.242 6.69869C105.106 6.69869 104.47 7.19869 104.47 7.97141C104.47 8.2896 104.606 8.60778 104.833 8.83505C105.742 9.74414 108.288 9.42596 109.651 10.7896C110.106 11.2441 110.379 11.835 110.379 12.7896C110.379 15.0623 108.424 15.926 106.606 15.926C104.106 15.8805 102.561 14.7441 102.47 12.5623Z" fill="#0A4753"/>
<g id="Group_2">
<path id="Vector_8" d="M5.7878 15.9709C4.24234 15.9709 3.37871 15.4709 2.7878 14.8345H2.74234V19.2436H0.333252V5.60724H2.56052V6.74361H2.60598C3.46962 5.6527 4.65143 5.28906 5.96962 5.28906C8.33325 5.28906 10.6969 7.19815 10.6969 10.38V10.7436C10.6514 13.7891 8.69689 15.9709 5.7878 15.9709ZM8.19689 10.6527C8.19689 8.78906 7.33325 7.33452 5.46962 7.33452C3.60598 7.33452 2.74234 8.78906 2.74234 10.6527C2.74234 12.5163 3.60598 14.0163 5.46962 14.0163C7.33325 13.9709 8.19689 12.5163 8.19689 10.6527Z" fill="#0A4753"/>
<path id="Vector_9" d="M14.5605 12.2438C14.5605 13.4256 15.2878 13.7892 16.2878 13.6529H16.515V15.6074C16.0605 15.6983 15.7423 15.7438 15.2423 15.7438C13.2423 15.7438 12.106 14.6983 12.106 12.3801V1.60742H14.5605V12.2438Z" fill="#0A4753"/>
<path id="Vector_10" d="M21.6059 13.8801C22.6514 13.8801 23.5605 13.3801 23.5605 11.562V5.60742H25.9696V11.7438C25.9696 14.4711 24.015 15.9711 21.6059 15.9711C19.1969 15.9711 17.2423 14.4711 17.2423 11.7438V5.60742H19.6514V11.562C19.6514 13.3801 20.5605 13.8801 21.6059 13.8801Z" fill="#0A4753"/>
<path id="Vector_11" d="M35.7423 6.97088C36.5151 5.78906 37.9242 5.33452 38.9242 5.33452C41.0605 5.33452 42.3787 6.74361 42.3787 8.78906V15.6527H39.9696V9.47088C39.9696 7.97088 39.2878 7.42543 38.1969 7.42543C37.106 7.42543 36.3333 7.97088 36.3333 9.51634V15.6982H33.9242V9.37997C33.9242 7.92543 33.1514 7.37997 32.106 7.37997C30.9242 7.37997 30.2423 8.06179 30.2423 9.56179V15.6527H27.8333V5.60724H30.0605V6.78906H30.106C30.7878 5.69815 31.7878 5.28906 32.7878 5.28906C34.1514 5.33452 35.1514 5.97088 35.7423 6.97088Z" fill="#0A4753"/>
</g>
<path id="Vector_12" d="M92.606 15.5623V5.51687H94.4242V15.5623H92.606ZM93.5605 1.24414C92.8787 1.24414 92.3333 1.7896 92.3333 2.47141C92.3333 3.15323 92.8787 3.69869 93.5605 3.69869C94.2423 3.69869 94.7878 3.15323 94.7878 2.47141C94.7878 1.7896 94.1969 1.24414 93.5605 1.24414Z" fill="#0A4753"/>
</g>
</g>
<defs>
<clipPath id="clip0_3927_9525">
<rect width="110" height="19.0909" fill="white" transform="translate(0.333252 0.699219)"/>
</clipPath>
</defs>
</svg>

    </SvgIcon>
  );
}
