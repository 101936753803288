import SvgIcon from "@mui/material/SvgIcon";

export default function CircleBagIcon() {
  return (
    <SvgIcon
    sx={{
        width: "100%",
        height: "100%"
      }}
    >
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="48" height="48" rx="24" fill="#C9BFE8"/>
<g opacity="0.9">
<path opacity="0.5" d="M13 20C13 18.8954 13.8954 18 15 18H33C34.1046 18 35 18.8954 35 20V32C35 33.1046 34.1046 34 33 34H15C13.8954 34 13 33.1046 13 32V20Z" fill="#493877"/>
<path d="M21 13C19.3431 13 18 14.3431 18 16V18H20V16C20 15.4477 20.4477 15 21 15H27C27.5523 15 28 15.4477 28 16V18H30V16C30 14.3431 28.6569 13 27 13H21Z" fill="#493877"/>
<path opacity="0.5" fill-rule="evenodd" clip-rule="evenodd" d="M15 18C13.8954 18 13 18.8954 13 20V24C13 25.1046 13.8954 26 15 26H20V24.5C20 23.6716 20.6716 23 21.5 23H26.5C27.3284 23 28 23.6716 28 24.5V26H33C34.1046 26 35 25.1046 35 24V20C35 18.8954 34.1046 18 33 18H15Z" fill="#493877"/>
<rect x="21" y="24" width="6" height="4" rx="1" fill="#493877"/>
</g>
</svg>


    </SvgIcon>
  );
}
