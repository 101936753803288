import { Helmet } from "react-helmet";
import { Box } from "@mui/material";
import Navbar from "../components/Navbar";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";


const TermsOfService = () => {
  const theme = useTheme();

  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <>
      <Helmet>
        <title>Herewith Terms of Service</title>
        <meta
          name="description"
          content="Read Herewith’s full Terms of Service here."
        />
      </Helmet>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
        }}
      >
        <Navbar color="black" />
        <h1 style={{ marginBottom: "2rem"}}>Terms of Service</h1>
        <iframe
          //   onLoad={onLoad}
          id="myFrame"
          title="terms-of-service"
          referrerPolicy="origin"
          src="https://docs.google.com/document/d/e/2PACX-1vRT22jEu6TLrYMlrykXZ858lAo8N-GuDj1vqKKMpTfXWaj-lmrA-bpK0NcyBK0qNhu-WDcOeUCCJkRA/pub?embedded=true"
          height="fit-content"
          allowFullScreen={true}
          width="1000px"
          style={{
            width: phone ? "90vw": tablet ? "70vw" : "50vw",
            minHeight: "60vh",
            flex: 1,
            flexGrow: 1,
            margin: 0,
            border: "1px solid lightgray",
            borderRadius: "1rem",
            fontSize: "5rem",
            padding: "none !important",
            //   overflow: "auto",
          }}
        ></iframe>
      </Box>
    </>
  );
};

export default TermsOfService;
