import * as React from "react";
import Box from "@mui/material/Box";
import { Paper } from "@mui/material";

import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Place4MomIcon from "../assets/icons/Place4MomIcon";
import CaringDotComIcon from "../assets/icons/CaringDotComIcon";
import TicketsIcon from "../assets/icons/TicketsIcon";
import WorkingAdvIcon from "../assets/icons/WorkingAdvIcon";
import PlumBenIcon from "../assets/icons/PlumBenIcon";
import BenePlIcon from "../assets/icons/BenePlIcon";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function CgPartners() {
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md")); 

  return (
    <>
      <Box
        sx={{
          width: "100%",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#FAF8F5",
          padding: "64px 0px"
        }}
      >
        <h1 style={{ fontSize: tablet ? "2rem" : "2.5rem" }}>Our partners</h1>
        <Box
          sx={{
            width: tablet ? "100%" : "100%",
            display: "flex",
            justifyContent: "space-evenly",
            flexDirection: "column",
            marginTop: "2rem",
            alignItems: "center", 
            padding: phone ? "12px 12px": tablet ? "": "12px 64px"
          }} 
        >

          <Stack sx={{alignItems: "center", justifyContent: "center", width: "100%", padding: phone ? "0px 12px": tablet ? "0px 24px": ""}} direction="row" spacing={tablet ? 1 : 2}>
            <Item sx={{ backgroundColor: "#90C8D4" , display:"flex", width: phone ? "100%": "16%", maxWidth: "200px", height: phone ? "55px":tablet ? "calc(35px + 3vw)" : "72px",  borderRadius: tablet ? "12px":"16px", padding: tablet ? "7px":"12px"}}>
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <Place4MomIcon />
              </Box>
            </Item>
            <Item sx={{ backgroundColor: "#90C8D4" , display:"flex", width: phone ? "100%": "16%", maxWidth: "200px", height: phone ? "55px":tablet ? "calc(35px + 3vw)" : "72px",  borderRadius: tablet ? "12px":"16px", padding: tablet ? "7px":"12px"}}>
              <Box
                sx={{
                  width: "100%",
                  height: "100"
                }}
              >
                <CaringDotComIcon />
              </Box>
            </Item>{" "}
            <Item sx={{ backgroundColor: "#90C8D4" , display: phone ? "none" : "flex", width: phone ? "100%": "16%", maxWidth: "200px", height: phone ? "55px":tablet ? "calc(35px + 3vw)" : "72px",  borderRadius: tablet ? "12px":"16px", padding: tablet ? "7px":"12px"}}>
              <Box
                sx={{
                  width: "100%",
                  height: "100"
                }}
              >
                <TicketsIcon />
              </Box>
            </Item>{" "}
            <Item sx={{ backgroundColor: "#90C8D4" , display: phone ? "none" : "flex", width: phone ? "100%": "16%", maxWidth: "200px", height: phone ? "55px":tablet ? "calc(35px + 3vw)" : "72px",  borderRadius: tablet ? "12px":"16px", padding: tablet ? "7px":"12px"}}>
              <Box
                sx={{
                  width: "100%",
                  height: "100"
                }}
              >
                <WorkingAdvIcon />
              </Box>
            </Item>{" "}
            <Item sx={{ backgroundColor: "#90C8D4" , display: phone ? "none" : "flex", width: phone ? "100%": "16%", maxWidth: "200px", height: phone ? "55px":tablet ? "calc(35px + 3vw)" : "72px",  borderRadius: tablet ? "12px":"16px", padding: tablet ? "7px":"12px"}}>
              <Box
                sx={{
                  width: "100%",
                  height: "100%"
                }}
              >
                <PlumBenIcon />
              </Box>
            </Item>
            <Item sx={{ backgroundColor: "#90C8D4" , display: phone ? "none" : "flex", width: phone ? "100%": "16%", maxWidth: "200px", height: phone ? "55px":tablet ? "calc(35px + 3vw)" : "72px",  borderRadius: tablet ? "12px":"16px", padding: tablet ? "7px":"12px"}}>
              <Box
                sx={{
                  width: "100%",
                  height: "100%"
                }}
              >
                <BenePlIcon />
              </Box>
            </Item>
          </Stack> 
        </Box>
      </Box>
    </>
  );
}
