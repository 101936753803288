import SvgIcon from "@mui/material/SvgIcon";

export default function RedoIcon() {
  return (
    <SvgIcon
      sx={{
        width: "32px",
        height: "32px",
      }}
    >
     <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Redo">
        <path id="2" d="M10.7467 1.54596L4.21405 2.32852C3.06521 2.46614 2.58415 3.80981 3.40281 4.59437L4.60196 5.74355C3.66675 6.78285 2.87306 7.95073 2.24798 9.21826C0.906679 11.9382 0.402038 14.9947 0.797877 18.0014C1.19372 21.0081 2.47226 23.8299 4.47182 26.11C6.47138 28.39 9.10216 30.026 12.0315 30.8109C14.9608 31.5958 18.0571 31.4944 20.9288 30.5196C23.8005 29.5448 26.3186 27.7403 28.1648 25.3344C30.0109 22.9284 31.1022 20.029 31.3005 17.0029C31.4989 13.9767 30.7954 10.9597 29.2791 8.33336C28.7268 7.37677 27.5036 7.04902 26.547 7.60131C25.5905 8.15359 25.2627 9.37677 25.815 10.3334C26.9357 12.2746 27.4557 14.5045 27.3091 16.7413C27.1625 18.978 26.3559 21.121 24.9914 22.8993C23.6268 24.6776 21.7656 26.0114 19.643 26.7319C17.5204 27.4524 15.2319 27.5273 13.0667 26.9472C10.9016 26.367 8.95712 25.1579 7.47918 23.4726C6.00124 21.7873 5.05623 19.7017 4.76366 17.4793C4.47108 15.257 4.84408 12.9978 5.83547 10.9874C6.27876 10.0885 6.83678 9.25742 7.49184 8.51302L9.11891 10.0723C9.93758 10.8569 11.3397 10.3958 11.4833 9.29487L12.2999 3.03438C12.4124 2.17161 11.647 1.43812 10.7467 1.54596Z" fill="#795EC6"/>
        </g>
    </svg>



    </SvgIcon>
  );
}
