import * as React from "react";
import { useEffect, useState } from "react";
import { Box, Button, Paper } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";
interface TrustedLocalProps {
  partner: string;
}

const TrustedLocal: React.FC<TrustedLocalProps> = ({ partner }) => {
  const [utm, setUTM] = useState("");
  const img = require("../../assets/Partners/CompressedWebP/TrustedLocal.webp");
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  // const desktop = useMediaQuery(theme.breakpoints.down("lg"));

  useEffect(() => {
    if (partner === "ebg") {
      setUTM(
        "/?utm_campaign=benefits_launch_24&utm_source=ebg&utm_medium=landing_page&utm_content=cta&utm_id=employee_benefits"
      );
    }
    if (partner === "beneplace") {
      setUTM(
        "/?utm_campaign=benefits_launch_24&utm_source=beneplace&utm_medium=landing_page&utm_content=cta&utm_id=employee_benefits"
      );
    }
    if (partner === "benefithub") {
      setUTM(
        "/?utm_campaign=benefits_launch_24&utm_source=benefithub&utm_medium=landing_page&utm_content=cta&utm_id=employee_benefits"
      );
    }
    if (partner === "amac") {
      setUTM(
        "/?utm_campaign=benefits_launch_24&utm_source=AMAC&utm_medium=landing_page&utm_content=cta&utm_id=employee_benefits"
      );
    }

    if (partner === "gateway") {
      setUTM(
        "/?utm_campaign=benefits_launch_24&utm_source=rewardgateway&utm_medium=landing_page&utm_content=cta&utm_id=employee_benefits"
      );
    }

    if (partner === "vantage") {
      setUTM(
        "/?utm_campaign=benefits_launch_24&utm_source=vantagecircle&utm_medium=landing_page&utm_content=cta&utm_id=employee_benefits"
      );
    }
    if (partner === "bennie") {
      setUTM(
        "/?utm_campaign=benefits_launch_24&utm_source=bennie_health&utm_medium=landing_page&utm_content=cta&utm_id=employee_benefits"
      );
    }
    if (partner === "fond") {
      setUTM(
        "/?utm_campaign=benefits_launch_24&utm_source=fond&utm_medium=landing_page&utm_content=cta&utm_id=employee_benefits"
      );
    }
  }, [partner]);

  return (
    <Box
      sx={{
        width: "100%",
        height: phone ? "80vh" : "38rem",
        minHeight: "fit-content",
        display: "flex",
        flexDirection: "row",
        justifyContent: phone ? "center" : "space-between",
        alignItems: "center",
        margin: phone ? "1rem auto" : "0 auto",
        backgroundImage: `url(${img})`,
        backgroundPosition: phone ? "80% 40%" : tablet ? "63% 0%" : "63% 20%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        padding: phone ? 0 : tablet ? "3rem" : "5rem ",
        paddingTop: phone ? "90%" : tablet ? "" : "",
        paddingBottom: phone ? "20px" : "",
        marginBottom: phone ? "0px !important" : "",
        marginTop: phone ? "0px !important" : "",
      }}
    >
      <Paper
        sx={{
          width: phone ? "90%" : tablet ? "50%" : "45%",
          height: "fit-content",
          padding: phone ? "1rem" : tablet ? "2.5rem" : "4rem",
          borderRadius: phone ? "2rem" : "3rem",
          textAlign: phone ? "center" : "left",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignSelf: phone ? "last baseline": "",
          minWidth: "350px"

        }}
      >
        <h1
          style={{
            fontSize: phone ? "1.75rem" : tablet ? "2rem" : "2.5rem",
            marginBottom: phone ? "1rem" : "2rem",
            marginTop: phone ? "1rem" : "0",
          }}
        >
          Book <i style={{ color: "#1D99BE" }}>Trusted</i> local Helpers
        </h1>
        <p
          style={{fontSize: "1rem", lineHeight: 1.5, alignSelf: "center"}}
        >
          Chat online with 100% background-checked, insured Helpers and choose the best fit for you and your family. Once you find a great Helper, rebook them anytime for convenient support on your schedule!
        </p>
        <Link to={`https://app.herewith.com/sign-up${utm}`}>
        <Button
              variant="contained"
              endIcon={<ArrowForwardIcon sx={{ fontSize: "1.5rem" }} />}
              sx={{
                width: "fit-content",
                height: "3rem",
                fontSize: "18px",
                backgroundColor: "#FDBB2E",
                color: "black",
                marginTop: "2rem",
                boxShadow: "none !important", // Remove box shadow
                transition: "background 0.5s ease", // Smooth transition for background color
                "&:hover": {
                    background: "#E59101",
                    "& #buttontext": {
                        paddingRight: "5px", // Shift text on hover
                    },
                    },
                    "& #buttontext": {
                    transition: "padding-right 0.5s ease", // Smooth return when hover ends
                },
            }}
            >
              <span id="buttontext">Find Helpers near you</span>
            </Button>
        </Link>
      </Paper>
    </Box>
  );
};

export default TrustedLocal;
