import * as React from "react";

import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useTheme, createTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Button } from "@mui/material";

interface HeaderProps{
  headerTextBeforeSpan?: string;
  headerTextSpan?: string;
  headerTextAfterSpan?: string;
  buttonText?: string;
}

export default function Header({headerTextAfterSpan="at home for seniors", headerTextSpan="everyday help ", headerTextBeforeSpan="Find ", buttonText="Find a Helper"}) {
  const theme = useTheme();
  const newBp = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1282,
        lg: 20000,
        xl: 760,
      },
    },
  });

  const phone = useMediaQuery(newBp.breakpoints.down("sm"));
  const tablet = useMediaQuery(newBp.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.down("lg"));

  const bgimage = require("../../assets/Home/CompressedWebP/blueheaderimage.webp");
  const bgimageTablet = require("../../assets/Home/CompressedWebP/headerimgtablet.webp");
  const bgImgMobile = require("../../assets/Home/CompressedWebP/MobileHomeHero.webp");
  const [utm, setUTM] = React.useState(
    "/?utm_campaign=social_launch_24&utm_source=facebook&utm_medium=ad&utm_content=familyvideo3&utm_id=marketing"
  );
  React.useEffect(() => {
    if (window.location.href.includes("?utm")) {
      setUTM(`?${window.location.href.split("?").pop()}`);
    }
  }, [])
  return (
    <>
      <div
        style={{
          maxWidth: "1500px",
          display: phone ? "none" : tablet ? "none" : "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "flex-start",
          backgroundImage: `url(${bgimage})`,
          margin: "2rem auto",
          width: "90%",
          height: phone ? "100px" : tablet ? "400px" : "400px",
          backgroundPosition: "50% 60%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          borderRadius: "2.5rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "space-around",
            height: "inherit",
            margin: "2rem auto",
            width: "90%",
          }}
        >
          <Box sx={{ maxWidth: "50%", textAlign: "left" }}>
          <Link to={`https://app.herewith.com/sign-up${utm}`}>
            <h1 style={{ fontSize: "40px" }}>
              {headerTextBeforeSpan} <span style={{ fontStyle: "italic" }}>{headerTextSpan}</span> {headerTextAfterSpan}
            </h1>
            </Link>

            <Link to={`https://app.herewith.com/sign-up${utm}`}>
              <Button
                variant="contained"
                endIcon={<ArrowForwardIcon sx={{ fontSize: "1.5rem" }} />}
                sx={{
                  minWidth: phone ? "fit-content" : "fit-content",
                  width: phone ? "100%" : "fit-content",
                  height: "3.5rem",
                  marginTop: "2rem",
                  borderRadius: 9999,
                  fontSize: "18px",
                  "&:hover": {
                    background: "#FECA5B",
                    // Add other hover styles as needed
                  },
                }}
              >
                {buttonText}
              </Button>
            </Link>
          </Box>
        </Box>
        {/* <img src={bgimage} alt="background" /> */}
      </div>
      {/* ------------ tablet ------------ */}
      <div
        style={{
          display: phone ? "none" : tablet ? "flex" : "none",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "flex-start",
          backgroundImage: `url(${bgimageTablet})`,
          margin: "2rem auto",
          width: "90%",
          height: "400px",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          borderRadius: "2.5rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "space-around",
            height: "inherit",
            margin: "2rem auto",
            width: "90%",
          }}
        >
          <Box sx={{ maxWidth: "60%", textAlign: "left" }}>
          <Link to={`https://app.herewith.com/sign-up${utm}`}>
          <h1 style={{ fontSize: "calc(20px + 1.7vw)" }}>
              {headerTextBeforeSpan} <span style={{ fontStyle: "italic" }}>{headerTextSpan}</span> {headerTextAfterSpan}
            </h1>
          </Link>
            {/* <Search>
              <SearchIconWrapper>
                <LocationOnRoundedIcon
                  style={{ fontSize: "2rem", color: "#177A98" }}
                />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Enter your zip code"
                inputProps={{ "aria-label": "search" }}
              />
              <Button
                variant="contained"
                disableElevation
                sx={{
                  borderRadius: "9999px",
                  fontSize: "18px",
                  "&:hover": {
                    background: "#FECA5B",
                    // Add other hover styles as needed
                  },
                }}
              >
                Find a Helper
              </Button>
            </Search> */}
            <Link to={`https://app.herewith.com/sign-up${utm}`}>
              <Button
                variant="contained"
                endIcon={<ArrowForwardIcon sx={{ fontSize: "1.5rem" }} />}
                sx={{
                  minWidth: phone ? "fit-content" : "fit-content",
                  width: phone ? "100%" : "fit-content",
                  height: "2.75rem",
                  marginTop: "2rem",
                  borderRadius: 9999,
                  fontSize: "16px",
                  "&:hover": {
                    background: "#FECA5B",
                    // Add other hover styles as needed
                  },
                }}
              >
                {buttonText}
              </Button>
            </Link>
          </Box>
        </Box>
        {/* <img src={bgimage} alt="background" /> */}
      </div>

      {/* ------------mobile------------ */}

      <div
        style={{
          display: phone ? "flex" : "none",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          backgroundImage: `url(${bgImgMobile})`,
          margin: "2rem auto",
          width: "90%",
          height: "450px",
          minHeight: "fit-content",
          backgroundPosition: "center bottom",
          backgroundRepeat: "no-repeat",
          backgroundSize: " cover",
          borderRadius: "2.5rem",
          padding: "2rem 1rem 0 1rem",
        }}
      >
        <Link to={`https://app.herewith.com/sign-up${utm}`}>
        <h1 style={{ fontSize: "28px" }}>
              {headerTextBeforeSpan} <span style={{ fontStyle: "italic" }}>{headerTextSpan}</span> {headerTextAfterSpan}
            </h1>
        </Link>
        {/* <Search>
          <SearchIconWrapper>
            <LocationOnRoundedIcon
              style={{ fontSize: "2rem", color: "#177A98" }}
            />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Enter your zip code"
            inputProps={{ "aria-label": "search" }}
          />
        </Search> */}
        <Link to={`https://app.herewith.com/sign-up${utm}`}>
          <Button
            variant="contained"
            disableElevation
            sx={{
              borderRadius: "9999px",
              fontSize: "1.25rem",
              marginTop: "2rem",
              width: "80vw",
              height: "4rem",
              "&:hover": {
                background: "#FECA5B",
                // Add other hover styles as needed
              },
            }}
          >
            {buttonText}
          </Button>
        </Link>
      </div>
    </>
  );
}
