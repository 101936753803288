import SvgIcon from "@mui/material/SvgIcon";

export default function CartCalendarIcon() {
  return (
    <SvgIcon
    sx={{
        width: "100%",
        height: "100%"
      }}
    >
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="48" height="48" rx="24" fill="#CEE9E1"/>
<path opacity="0.5" d="M14 22C14 20.8954 14.8954 20 16 20H32C33.1046 20 34 20.8954 34 22V33C34 34.1046 33.1046 35 32 35H16C14.8954 35 14 34.1046 14 33V22Z" fill="#4F786B"/>
<path d="M20 14C20.5523 14 21 14.4477 21 15V17H27V15C27 14.4477 27.4477 14 28 14C28.5523 14 29 14.4477 29 15V17H32C33.1046 17 34 17.8954 34 19V23H14V19C14 17.8954 14.8954 17 16 17H19V15C19 14.4477 19.4477 14 20 14Z" fill="#4F786B"/>
</svg>


    </SvgIcon>
  );
}
