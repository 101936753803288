import React, { useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { Box, List, Divider, Typography, Stack } from "@mui/material";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar";
import { client } from "../../client.js";
import SearchIcon from "../../assets/icons/SearchIcon.tsx";
import { useLocation } from "react-router-dom";
import { useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import XIcon from "../../assets/icons/XIcon.tsx";

interface Result {
  title: string;
  slug: string;
  description: string;
  mainImage?: { asset: { url: string } };
  body: any[];
}

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: alpha(theme.palette.common.white, 1),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.95),
  },
  width: "100%",
  height: "4rem",
  borderRadius: "9999px",
  display: "flex !important",
  padding: "8px",
  alignSelf: "center",
  alignItems: "center",
  zIndex: "1"
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  height: "80%",
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minWidth: "3rem",
  alignSelf: "center",
  zIndex: "10"
}));

const ClearIconWrapper = styled("div")(({ theme }) => ({
    height: "23px",
    width: "23px",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "3rem",
    alignSelf: "right",
    zIndex: "10",
    right: "1.5%"
  }));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  flex: 1,
  height: "100%",
  borderRadius: "9999px",
  zIndex: "1",
  alignSelf: "center",
  justifyContent: "center",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    height: "100%",
    fontSize: "1.25rem",
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
  },
}));

const stopWords = ["the", "and", "or", "but", "a", "an", "at", "on", "in", "to"];

const BlogSearch: React.FC = () => {
  const location = useLocation();
  const { searchQuery, results = [] }: { searchQuery?: string; results: Result[] } = location.state || {};
  const [query, setQuery] = useState<string>(searchQuery || "");
  const [result, setResults] = useState<Result[]>(results);
  const [displayedQuery, setDisplayedQuery] = useState<string>(searchQuery || "");
  const [loading, setLoading] = useState<boolean>(false);

  const preprocessQuery = (query: string) => {
    return query
      .toLowerCase()
      .split(/\s+/)
      .filter((word) => !stopWords.includes(word));
  };

  const calculateRelevance = (data: Result[], keywords: string[]) => {
    return data
      .map((item) => {
        const titleMatches = (item.title.match(new RegExp(keywords.join("|"), "gi")) || []).length;
        const descriptionMatches = (item.description.match(new RegExp(keywords.join("|"), "gi")) || []).length;
        const bodyMatches = item.body
          .flatMap((block) => block.children?.map((child: any) => child.text) || [])
          .join(" ")
          .match(new RegExp(keywords.join("|"), "gi")) || [];
        const totalMatches = titleMatches + descriptionMatches + bodyMatches.length;

        return { ...item, relevance: totalMatches };
      })
      .sort((a, b) => b.relevance - a.relevance);
  };

  const handleSearch = (searchQuery: string) => {
    const keywords = preprocessQuery(searchQuery);
    setLoading(true);

    client
      .fetch(
        `*[_type in ["post", 'author', 'category']
          && (title match $queryString + '*' || description match $queryString + '*' || body[].children[].text match $queryString + '*')] {
            title,
            "name": author->name,
            bio,
            body,
            'slug': slug.current,
            description,
            'type': _type,
            mainImage {
                asset -> {
                _id,
                url
                },
                alt,
            }
          }`,
        { queryString: keywords.join(" ") }
      )
      .then((data) => {
        const sortedData = calculateRelevance(data, keywords);
        setResults(sortedData);
        setDisplayedQuery(searchQuery);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setQuery(value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSearch(query);
    }
  };

  const handleSearchClick = () => {
    handleSearch(query);
  };

  const handleClear = () => {
    setQuery(""); // Clear the query state
  }

  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));

  const catImg = require("../../assets/cat.webp")

  return (
    <Box
      sx={{
        width: "100vw",
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          backgroundColor: "#DBF7FF",
          paddingBottom: "1rem",
        }}
      >
        <Navbar color="black" />
        <Box
          sx={{
            width: "90%",
            margin: "0 auto",
            textAlign: "center",
            display: "flex !important",
          }}
        >
          <Search style={{ margin: phone ? "16px 16px 32px 16px" : tablet ? "16px 10% 32px 10%": "16px 20% 32px 20%",}}>
            <SearchIconWrapper onClick={handleSearchClick} style={{ cursor: "pointer" }}>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search for advice and articles"
              value={query}
              onChange={handleChange}
              onKeyDown={handleKeyPress} // Listen for "Enter"
            />
            {query != "" ? 
            (<ClearIconWrapper onClick={handleClear} style={{ cursor: "pointer" }}>
              <XIcon />
            </ClearIconWrapper>
              ) : (<></>)}
          </Search>
        </Box>
      </Box>
      <List
  sx={{
    width: phone ? "100%" : tablet ? "80%" : "57%",
    margin: "2rem auto",
  }}
>
<Typography sx={{width: "100%", textAlign: "left", paddingBottom: "10px", fontSize: "20px", color: "#85827D", textDecoration: "none", paddingLeft: phone ? "18px" : ""}}><Link style={{paddingBottom: "10px", fontSize: "20px", color: "#115C72", textDecoration: "none"}} to="/help-center">Help Center </Link>/<Link style={{paddingBottom: "10px", fontSize: "20px", color: "#85827D", textDecoration: "none"}} to=""> Search Results</Link></Typography>
  {result.length === 0 ? (
    <div style={{display: "flex", flexDirection: "column", alignItems: "center", margin: phone ? "0 10%": ""}}>
      <h1 style={{ paddingTop: "32px", color: "black", maxWidth: "80vw", height: "fit-content"}}>{"No results found for "} <span style={{ fontStyle: "italic", color: "#795EC6" }}>
              {`“${displayedQuery}”`}
            </span></h1>
      <Typography
        sx={{
          lineHeight: 2,
          fontSize: "20px",
          color: "#85827D",
        }}
        component="p"
        variant="body2"
      >
        {"Try rewording your search for more results."}
      </Typography>
      <img style={{width: "300px", alignSelf: "center", paddingTop: "32px"}} src={catImg}/>
    </div>
  ) : (
    <>
      {/* Show this heading only once */}
      <Stack sx={{ paddingLeft: phone ? "5vw" : "", alignItems: "start" }}>
        {/*<h1 style={{ fontSize: "28px"}}>Search Results</h1>*/}
        <Typography
          sx={{
            lineHeight: 2,
            fontSize: "20px",
            color: "#85827D",
          }}
          component="p"
          variant="body2"
        >
          {`${result.length} matches`}
        </Typography>
      </Stack>

      {/* Render the search results */}
      {result.map((post, index) => (
        <React.Fragment key={post.slug}>
          <Link
            to={`/help-center/${post.slug}`}
            style={{ textDecoration: "none" }}
          >
            <Box
              sx={{
                padding: "1rem 0",
                display: "flex",
                gap: phone ? "10px" : "",
                alignItems: "center",
                justifyContent: "center",
                alignSelf: "center",
                flexDirection: "row",
              }}
            >
              {post.mainImage && (
                <Box
                  sx={{
                    width: phone ? "150px" : tablet ? "400px" : "306px",
                    minWidth: phone ? "60px" : "200px",
                    height: phone ? "100px" : tablet ? "175px" : "212px",
                    backgroundImage: `url(${post.mainImage?.asset?.url || "https://via.placeholder.com/150"})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    borderRadius: "24px",
                    marginRight: phone ? "0px" : "32px",
                  }}
                />
              )}
              <Box
                sx={{
                  width: phone ? "45%" : "524px",
                  minWidth: phone ? "10px" : "200px",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    paddingTop: phone ? "10px" : "",
                    textAlign: phone ? "center" : "left",
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#0C3D4C",
                    paddingBottom: "8px",
                    fontFamily: "Sitebody !important",
                  }}
                >
                  {post.title}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    textAlign: "left",
                    display: phone ? "none" : "block",
                    fontSize: "14px",
                    color: "#0C3D4C",
                    fontFamily: "Sitebody !important",
                  }}
                >
                  {post.description}
                </Typography>
                <p
                  style={{
                    color: "#706D67",
                    textAlign: phone ? "center" : "left",
                    fontSize: "14px",
                    paddingTop: "8px",
                    fontFamily: "Sitebody !important",
                  }}
                >
                  {" "}
                  {`by Jill Tomasetti`}
                </p>
              </Box>
            </Box>
            {index < result.length - 1 && <Divider sx={{ opacity: "0.5" }} />}
          </Link>
        </React.Fragment>
      ))}
    </>
  )}
</List>
    </Box>
  );
};

export default BlogSearch;