import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import FooterLink from "./FooterLink.tsx";
import FooterHeader from "./FooterHeader.tsx";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";

import LockIcon from "@mui/icons-material/Lock";


import { Link } from "react-router-dom";
import { useTheme, createTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function Footer({googleLink = "https://play.google.com/store/apps/details?id=com.quoori.care.family.app", appleLink= "https://apps.apple.com/app/herewith-find-in-home-care/id1629912951"}) {
  const logo = require("../assets/FooterLogo.png");
  const googleIcon = require("../assets/Footer/Google Play Store Button.webp");
  const appleIcon = require("../assets/Footer/App Store Button.webp");
  const FBIcon = require("../assets/Footer/facebook_1384005.webp");
  const XIcon = require("../assets/Footer/twitter_5969020.webp");
  const LinkIcon = require("../assets/Footer/linkedin_1384014.webp");
  const IGIcon = require("../assets/Footer/instagram_1384015.webp");
  const newBp = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 700,
        md: 900,
        lg: 20000,
        xl: 760,
      },
    },
  });
  const theme = useTheme();
  const phone = useMediaQuery(newBp.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const between = useMediaQuery(newBp.breakpoints.down("xl"));
  const desktop = useMediaQuery(newBp.breakpoints.down("lg"));
  return (
    <Box
      sx={{
        width: "100%",
        // position: "absolute",
        // bottom: 0,
        display: "block",
        background: "white",
        boxSizing: "border-box",
        height: "40rem",
        minHeight: "fit-content",
        color: "#706D67",

        padding: phone ? "1rem" : tablet ? "2.5rem" : "3rem 5rem",
      }}
    >
      <AppBar
        elevation={0}
        position="static"
        sx={{
          background: "white",

          bottom: 0,
          display: "flex",
          flexDirection: phone ? "column" : tablet ? "column" : "row",
          height: phone || tablet ? "fit-content" : "18rem",
          width: "100%",

          alignItems: "flex-start",
          justifyContent: "space-between",
          padding: "1rem ",
          margin: 0,
        }}
      >
        <Box sx={{ width: phone ? "100%" : "50%", textAlign: "left" }}>
          <Link
            style={{
              textDecoration: "none",
            }}
            to="/"
          >
            <img
              src={logo}
              style={{
                width: phone ? "50%" : tablet ? "45%" : "30%",
                marginBottom: tablet ? "3rem" : "none",
              }}
              alt="logo"
            />
          </Link>
        </Box>

        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: phone ? "column" : "row",
            height: "100%",
            width: tablet ? "100%" : "auto",
            justifyContent: "space-around",
            marginTop: phone ? "2rem" : 0,
          }}
        >
          {/* <Box sx={{ height: "100%", flex: 1 }}> */}
          <Stack
            sx={{
              textAlign: "left",
              height: "100%",
              flex: 1,
              borderBottom: phone ? "1px solid #EBE9E6" : "none",
              marginBottom: phone ? "1rem" : "none",
            }}
            spacing={0}
          >
            <FooterHeader text="Get Started" />
            <FooterLink text="Find Help" url="/" />
            <FooterLink text="Be a Helper" url="/helpers" />
            <FooterLink text="Help Center" url="/help-center" />
          </Stack>
          {/* </Box> */}
          {/* <Box sx={{ height: "100%", flex: 1 }}> */}
          <Stack
            sx={{
              textAlign: "left",
              height: "100%",
              flex: 1,
              borderBottom: phone ? "1px solid #EBE9E6" : "none",
              marginBottom: phone ? "1rem" : "none",
            }}
            spacing={0}
          >
            <FooterHeader text="Company" />

            <FooterLink text="About Us" url="/about" />
            <FooterLink text="Privacy Policy" url="/privacy" />
            <FooterLink text="Terms of Service" url="/terms-of-service" />
            <FooterLink
              text="Community Guidelines"
              url="/community-guidelines"
            />
          </Stack>
          {/* </Box>{" "} */}
          {/* <Box sx={{ height: "100%", flex: 1 }}> */}
          <Stack
            sx={{
              textAlign: "left",
              height: "100%",
              flex: 1,

              marginBottom: phone ? "1rem" : "none",
            }}
            spacing={0}
          >
            <FooterHeader text="Contact Us" />
            <FooterLink
              text="hello@herewith.com"
              url="mailto:hello@herewith.com"
            />
            <FooterLink text="415-506-9776" url="tel:415-506-9776" />
            <FooterLink text="225 Bush St, San Francisco, CA 94104" url="#" />
          </Stack>
          {/* </Box> */}
          {/* <Link to="/caregiving"></Link>
          <Link to="/about"></Link>
          <Link to="/support"></Link> */}
        </Box>
      </AppBar>
      <Divider variant="fullWidth" sx={{ opacity:  phone ? "0.5" : tablet ? "0.5" : desktop ? "0" : "0.5"}} />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: between ? "column-reverse" : "row",
          justifyContent: between ? "flex-start" : "space-between",
          alignItems: "center",
          padding: between ? "1rem" : "0.5rem 0",
        }}  
      >
        {" "}
        <p style={{ fontSize: "1rem", marginTop: between ? "1rem" : 0, textAlign:"left", width: "100%"}}>
          Copyright 2024
        </p>
        <Stack spacing={phone ? 4 : 1} sx={{display: "flex", flexDirection: between ? "column-reverse" : "row", alignItems: "center", gap: phone? "" : tablet? "32px": "24px"}}>
          <Stack direction="row" sx={{gap: between? "20%" : tablet? "32px": "24px", alignItems: "center", width: between ? "80%" : "fit-content", alignContent:"center", justifyContent: "space-around", paddingTop: phone ? "16px" : "0"}}>
            <Link to="https://www.facebook.com/herewithdotcom/">
              <img src={FBIcon} style={{height: "24px", width: "24px", minWidth: "24px"}} alt="Facebook Icon" />
            </Link>

            <Link to="https://twitter.com/_herewith">
              <img src={XIcon} style={{height: "24px", width: "24px", minWidth: "24px"}}  alt="Twitter Icon"/>
            </Link>

            <Link to="https://www.linkedin.com/company/herewith">
              <img src={LinkIcon} style={{height: "24px", width: "24px", minWidth: "24px"}} alt="Linkedin Icon"/>
            </Link>

            <Link to="https://www.instagram.com/_herewith/">
              <img src={IGIcon} style={{height: "24px", width: "24px", minWidth: "24px"}}  alt="Instagram Icon"/>
            </Link>
          </Stack>
          <Divider orientation="vertical" style={{opacity: "0.5", height:"46px", display: between ? "none" : "block" }}/>
          <Divider orientation="horizontal" variant="fullWidth"  style={{opacity: "0.5", width: "93vw",  display: between ? "block" : "none", margin: "0px" }}/>
          <Stack direction="row" sx={{gap: between? "16px" : tablet? "32px": "24px", marginTop: phone ? "0px !important" : "", alignItems: "center",padding: phone? "0px 0px 16px 0px" : "0px"}}>
            <Link to={appleLink}>
              <img src={appleIcon} style={{height: "40px", width: "135px", minWidth: "135px"}} alt="Apple Appstore"/>
            </Link>
            <Link to={googleLink}>
              <img src={googleIcon} style={{height: "40px", width: "135px", minWidth: "135px"}} alt="Google Appstore"/>
            </Link>
          </Stack>
        </Stack>
      </Box>
      <Divider variant="fullWidth" sx={{ opacity: "0.5", display: between ?  "none" : ""}} />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          textAlign: "left",
          alignItems: "center",
          padding: phone ? "0rem 1rem 1rem 1rem" : "1rem 0",
          lineHeight: 1.5,
        }}
      >
        <p style={{fontSize: "0.625rem",}}>
        Herewith provides a platform to assist Helpers and care recipients in connecting 
        regarding in-home non-medical care opportunities. Herewith does not employ any Helpers, 
        nor does it recommend any Helpers and/or care recipients who use its platform. User information 
        provided in profiles, posts, and otherwise on the Herewith platform is not generated 
        or verified by Herewith. Each user of Herewith’s platform is responsible for conducting 
        their own vetting before determining whether to enter into an employment relationship 
        and for their own conduct, including compliance with applicable laws.
        </p>
      </Box>
      <Divider variant="fullWidth" sx={{ opacity: "0.5", marginBottom: phone ? " " : tablet ? "16px" : desktop ? "16px": ""}} />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: phone ? "column" : "row",
          fontSize: "1rem",
          textAlign: "left",
          alignItems: phone ? "flex-start" : "center",
          padding: "0rem 0rem 1rem 0rem",
        }}
      >
        <div >
        <Chip
          icon={<LockIcon />}
          label="SSL"
          size="medium"
          sx={{ margin: phone ? "0.5rem 1rem" : "0 1rem 0.5rem 0", marginRight: "1rem" }}
        />
        <Chip
          icon={<LockIcon />}
          label="CCPA Compliant"
          size="medium"
          sx={{ margin: phone ? "0rem 1rem" : "0 1rem 0.5rem 0", marginRight: "1rem" }}
        />
        </div>
      </Box>
    </Box>
  );
}
