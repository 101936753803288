import * as React from "react";
import ArrowButton from "../../components/ArrowButton";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import SlideButton from "../../components/SlideButton";

interface AboutTestimonialsProps {}

const AboutTestimonials: React.FC<AboutTestimonialsProps> = ({}) => {
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const bg = require("../../assets/aboutTestimonialsBg.png");
  const VeronicaImg = require('../../assets/veronicaImg.webp')
  return (
    <Box sx={{
      display: "flex",
      backgroundImage: `url(${bg})`,
      height: "max-content",
      width: "100%",
      backgroundPosition: "center top",
      backgroundSize: "100%",
      backgroundRepeat: "no-repeat",
      backgroundColor: "#FAF8F5",
      padding: phone ? "64px 16px 16px 16px": tablet ? "64px 32px 32px 32px":"128px 64px 64px 64px",
      gap: "24px",
      flexDirection: phone ? "column" :"row"
    }}>
      <Box sx={{width: phone ? "100%":"50%", display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
        <Box sx={{backgroundColor: "#FFE0EC", width: "max-content", height: "max-content", display: "flex", justifyContent: "center", alignItems: "center", padding: phone ? "24px 0px":"32px 0px", borderRadius: "32px", maxWidth: "600px"}}>
          <Box sx={{backgroundColor:"#FAF8F5", width: "80%", height: "80%", display: "flex", justifyContent: "center", flexDirection: "column", padding: phone ? "24px":"32px", gap: "24px", borderRadius: "24px"}}>
            <Box>
              <img style={{height: phone ? "48px" : "68px", borderRadius: "50%"}} src={VeronicaImg}/>
            </Box>
            <Box>
              <h1 style={{color: "#0C3D4C", fontSize: tablet ? "16px" :"22px", textAlign: "left"}}>
              “Herewith strengthens the bond between technology and human care, creating amazing outcomes for those we serve. Witnessing this synergy come to life has been one of the most rewarding experiences of my career.”
              </h1>
            </Box>
            <Box>
              <p style={{color: "#85827D", fontSize: tablet ? "14px" :"20px", textAlign: "left"}}>Veronica Baiz, Director of Operations</p>
            </Box>
          </Box>
        </Box>

      </Box>
      <Box sx={{width: phone ? "100%":"50%", padding: phone ? "16px": tablet ? "32px":"64px", display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "left", maxWidth: "600px"}}>
        <h1 style={{paddingBottom: "24px", fontSize: phone ? "28px": tablet ? "32px":"40px"}}>
          Be a part of something bigger at <span style={{color: "#C97B99"}}>Herewith</span>
        </h1>
        <p style={{paddingBottom: "36px", color: "#0C3D4C", fontSize: "16px"}}>
        Join us in our mission to break down barriers to affordable, convenient support for older adults. 
        </p>
        <p style={{paddingBottom: "24px", color: "#0C3D4C", fontSize: "16px"}}>
        We bring the same honest, empathetic spirit to our work behind the scenes at Herewith headquarters in San Francisco as we do in our approach to care. 
        </p>
        <Box sx={{display: "flex",alignItems: "flex-start"}}>
          <SlideButton text="Get in touch" link={`https://herewith.com/partners#getintouch`}/>
        </Box>
      </Box>
    </Box>
  );
};

export default AboutTestimonials;

// “I want to make a difference in the field of Gerontology. At Herewith I can do just that, by pioneering new ways for older adults and their families to use technology and get the help they need.”
// – Bevi Kothare, VP of Business Development

// “Indeed there are only a few workplaces where you can truly touch the lives of others. Herewith allows me to do that everyday. I feel encouraged that my team can make a real difference to older adults and their families.”
// – Khurram Khan, COO

// “Herewith strengthens the bond between technology and human care, creating amazing outcomes for those we serve. Witnessing this synergy come to life has been one of the most rewarding experiences of my career.”
// – Veronica Baiz, Director of Operations

// “At Herewith, I blend my passion for UX, technology and human connection by creating enriching experiences, to provide a sense of belonging through a helping hand.”
// – Rudy Iyer, Product Manager

// “I simply want to make life easier for people like my mum and grandpa. We're building a service that helps everyone who's working hard to make sure our parents are living their best lives.”
// – Daniel Stanley, Head of Design
