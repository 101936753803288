// @ts-nocheck
import { useState, useEffect } from "react";
import * as React from "react";
import { List, Box } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "react-router-dom";
import { client } from "../../client";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

export default function BlogHome() {
  const [topicsList, setTopicsList] = useState([]);
  const [posts, setPosts] = useState([]);
  const [topic, setTopic] = useState("");
  const [filteredPosts, setFilteredPosts] = useState([]);
  const theme = useTheme();
  const backupimg = require("../../assets/genericarticleimage.jpg");
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  // const desktop = useMediaQuery(theme.breakpoints.down("lg"));

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTopic(newValue);
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    setTopic(event.target.value as string);
  };

  useEffect(() => {
    client
      .fetch(`*[_type == "topic"] {title}`)
      .then((data: any) => setTopicsList(data))
      .catch(console.error);
  }, []);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "post"] {
      title,
      "name": author->name,
      slug,
      body,
      publishedAt,
      description,
      "topics": topics[]-> {title},
      mainImage {
        asset -> {
          _id,
          url
        },
        alt,
      }
    } | order(publishedAt desc)`
      )
      .then((data: any) => setPosts(data))
      .catch(console.error);
  }, []);

  useEffect(() => {
    let filtered = posts.filter(function (el) {
      return el.topics?.find((t) => t?.title === topic);
    });
    console.log("filtered:", filtered);
    (posts && topic === "All Articles") || topic === ""
      ? setFilteredPosts(posts)
      : setFilteredPosts(filtered);
  }, [posts, topic]);

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flex: 1,
          paddingTop: "32px"
        }}
      >
        {/* sidebar */}
        <Box
          sx={{
            width: "25%",
            maxWidth: "25%",
            minHeight: "100%",
            flex: 1,
            height: "fit-content",
            display: phone || tablet ? "none" : "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              bgcolor: "background.paper",
              display: "flex",
              height: "fit-content",
              textAlign: "left",
              alignItems: "flex-start",
              flexDirection: "column",
              padding: "3rem 0 0 3rem",
            }}
          >
            <Box
              sx={{ padding: "1.5rem 0", fontWeight: "bold", fontSize: "1rem" }}
            >
              <p>Topics</p>
            </Box>
            <Tabs
              orientation="vertical"
              variant="fullWidth"
              value={topic}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              // @ts-ignore
              indicatorColor="transparent"
              sx={{
                borderTop: 1,
                borderColor: "divider",
              }}
            >
              <Tab
                disableRipple
                key="All Articles"
                sx={{
                  textAlign: "left",
                  alignItems: "flex-start",
                  textTransform: "none",
                  paddingLeft: 0,
                  fontSize: "1rem",
                }}
                value=""
                label="All Articles"
              />
              {topicsList.map((t) => {
                return (
                  <Tab
                    disableRipple
                    sx={{
                      textAlign: "left",
                      alignItems: "flex-start",
                      textTransform: "none",
                      paddingLeft: 0,
                      fontSize: "1rem",
                    }}
                    label={t.title}
                    value={t.title}
                    key={t.title}
                  />
                );
              })}
            </Tabs>
          </Box>
        </Box>

        {/* bloglist */}
        <Box
          sx={{
            flex: 1,
            textAlign: "left",
            padding: phone || tablet ? 0 : "1rem",
            maxWidth: phone || tablet ? "90%" : "100%",
            margin: "0 auto",
          }}
        >
          <Box
            sx={{ paddingTop: "32px",display: phone || tablet ? "block" : "none", width: "100%" }}
          >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Topics</InputLabel>
              <Select
                value={topic}
                label="Topics"
                onChange={handleSelectChange}
                sx={{ borderRadius: 9999 }}
              >
                <MenuItem
                  value="All Articles"
                  key="All Articles"
                  sx={{
                    textAlign: "left",
                    alignItems: "flex-start",
                    textTransform: "none",
                    paddingLeft: 0,
                    fontSize: "1rem",
                    width: "100%",
                    padding: "1rem",
                  }}
                >
                  All Articles
                </MenuItem>
                {topicsList.map((t) => {
                  return (
                    <MenuItem
                      value={t.title}
                      key={t.title}
                      sx={{
                        textAlign: "left",
                        alignItems: "flex-start",
                        textTransform: "none",
                        paddingLeft: 0,
                        fontSize: "1rem",
                        width: "100%",
                        padding: "1rem",
                      }}
                    >
                      {t.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>

          {(topic === "" || topic === "All Articles") && (
            <h1
              style={{
                fontSize: "2rem",
                display: phone ? "none" : "block",
                marginTop: tablet ? "2rem" : "none",
              }}
            >
              All Articles
            </h1>
          )}
          {topic !== "" && topic !== "All Articles" && (
            <h1
              style={{
                fontSize: "2rem",
                display: phone ? "none" : "block",
                marginTop: tablet ? "2rem" : "none",
              }}
            >
              {topic}
            </h1>
          )}

          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              marginBottom: "5rem",
            }}
          >
            {filteredPosts?.map((post: any) => {
              return (
                <>
                  <Link
                    to={`/help-center/${post?.slug?.current}`}
                    key={post?.slug?.current}
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    <ListItem
                      alignItems="center"
                      key={post?.slug?.current}
                      sx={{
                        padding: phone ? "1rem 0" : "1.5rem 0",
                        minHeight: phone ? "8rem" : 0,
                      }}
                    >
                      <ListItemAvatar
                        sx={{
                          height: "100%",

                          margin: phone ? 0 : "1.25rem",
                          marginLeft: phone || tablet ? 0 : ".5rem",
                        }}
                      >
                        {post?.mainImage?.asset?.url != null && (
                          <Box
                            sx={{
                              width: phone ? "8rem" : "15rem",
                              height: phone ? "5rem" : "10rem",
                              marginRight: phone ? "1rem" : 0,
                              borderRadius: "1rem",
                              backgroundImage: `url(${post?.mainImage?.asset?.url})`,
                              backgroundColor: "rgba(0, 0, 0, 0.5)",
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                              "&:hover": {
                                backgroundBlendMode: "darken",
                              },
                            }}
                          >
                            {" "}
                            <Box
                              sx={{
                                opacity: 0,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                height: "100%",
                                zIndex: "100",
                                "&:hover": {
                                  opacity: 1,
                                  zIndex: "100",
                                  filter: "brightness(1.2)",
                                },
                              }}
                            >
                              <p
                                style={{
                                  color: "white",
                                  opacity: 1,
                                  filter: "brightness(1.2)",
                                }}
                              >
                                Read Article
                              </p>
                            </Box>
                          </Box>
                        )}
                        {post?.mainImage?.asset?.url == null && (
                          <Box
                            sx={{
                              width: phone ? "8rem" : "15rem",
                              height: phone ? "5rem" : "10rem",
                              marginRight: phone ? "1rem" : 0,
                              borderRadius: "1rem",
                              backgroundImage: `url(${backupimg})`,
                              backgroundColor: "rgba(0, 0, 0, 0.5)",

                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                              "&:hover": {
                                backgroundBlendMode: "darken",
                              },
                            }}
                          >
                            <Box
                              sx={{
                                opacity: 0,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                height: "100%",
                                zIndex: "100",
                                "&:hover": {
                                  opacity: 1,
                                  zIndex: "100",
                                  filter: "brightness(1.2)",
                                },
                              }}
                            >
                              <p
                                style={{
                                  color: "white",
                                  opacity: 1,
                                  filter: "brightness(1.2)",
                                }}
                              >
                                Read Article
                              </p>
                            </Box>
                          </Box>
                        )}
                      </ListItemAvatar>
                      <ListItemText
                        primaryTypographyProps={{
                          fontFamily: "Sitebody !important",
                          color: "#0C3D4C",
                          fontSize: "1rem",
                          lineHeight: 1.5,
                          fontWeight: "bold",
                        }}
                        secondaryTypographyProps={{
                          fontFamily: "Sitebody !important",
                          color: "#0C3D4C",
                          fontSize: "1rem",
                        }}
                        primary={<> {post.title}</>}
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{
                                display: phone ? "none" : "block",
                                lineHeight: 2,
                              }}
                              component="p"
                              variant="body2"
                              color="text.primary"
                            >
                              {post.description}
                            </Typography>
                            {/* <Typography
                              sx={{ display: "block" }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              By {post.name}
                            </Typography> */}
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                  </Link>
                  <Divider component="li" />
                </>
              );
            })}
          </List>
        </Box>
      </Box>
    </>
  );
}
