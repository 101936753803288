import SvgIcon from "@mui/material/SvgIcon";

export default function FacebookIcon() {
  return (
    <SvgIcon
      sx={{
        width: "inherit",
        flex: 1,
        height: "inherit",
      }}
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 12C0 17.94 4.32 22.86 10.02 23.88L10.0909 23.822C10.0873 23.8213 10.0836 23.8207 10.08 23.82V15.36H7.08V12H10.08V9.35999C10.08 6.35999 12 4.67999 14.76 4.67999C15.6 4.67999 16.56 4.79999 17.4 4.91999V7.97999H15.84C14.4 7.97999 14.04 8.69999 14.04 9.65999V12H17.22L16.68 15.36H14.04V23.82C14.0035 23.8266 13.9669 23.8331 13.9304 23.8394L13.98 23.88C19.68 22.86 24 17.94 24 12C24 5.4 18.6 0 12 0C5.4 0 0 5.4 0 12Z"
          fill="black"
        />
      </svg>
    </SvgIcon>
  );
}
