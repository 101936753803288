import { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box } from "@mui/material";
import ArrowButton from "../components/ArrowButton";

export default function Error() {
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.down("lg"));

  const bgimage = require("../assets/404img.png");
  const bgmobile = require("../assets/404mobile.png");
  return (
    <>
      <Navbar color="black" />
      <div
        style={{
          display: phone ? "none" : "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "flex-start",
          backgroundImage: `url(${bgimage})`,
          margin: "2rem auto",
          width: "90%",
          height: "36rem",
          backgroundPosition: "center right",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover ",
          borderRadius: "2.5rem",
          padding: "5rem",
        }}
      >
        <Box
          sx={{
            width: "30%",
            textAlign: "left",
            display: "flex",
            flexDirection: "column",
            height: "80%",
            justifyContent: "space-around",
          }}
        >
          <h1>
            Hmm... how did <br /> we end up here?
          </h1>
          <p>
            We didn’t mean for you to get stuck—let’s get you back where you
            need to go.
          </p>
          <ArrowButton link="/" text="Return to homepage" />
        </Box>
      </div>

      {/* mobile */}

      <div
        style={{
          display: phone ? "flex" : "none",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "flex-start",
          backgroundImage: `url(${bgmobile})`,
          margin: "2rem auto",
          width: "90%",
          height: "80vh",
          backgroundPosition: "center right",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover ",
          borderRadius: "2.5rem",
          padding: "1rem",
        }}
      >
        <Box
          sx={{
            width: "100%",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            height: "50%",
            justifyContent: "space-around",
          }}
        >
          <h1 style={{ fontSize: "2rem" }}>
            Hmm... how did <br /> we end up here?
          </h1>
          <p style={{ lineHeight: "1.5", width: "85%", margin: "0 auto" }}>
            We didn’t mean for you to get stuck—let’s get you back where you
            need to go.
          </p>
          <ArrowButton link="/" text="Return to homepage" />
        </Box>
      </div>
      <Footer />
    </>
  );
}
