import SvgIcon from "@mui/material/SvgIcon";

export default function CircleHatIcon() {
  return (
    <SvgIcon
    sx={{
        width: "100%",
        height: "100%"
      }}
    >
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="48" height="48" rx="24" fill="#FFE0EC"/>
        <path d="M25.2628 17.5051C24.4522 17.1809 23.548 17.1809 22.7374 17.5051L12.6398 21.5442C11.9274 21.8292 11.9274 22.8376 12.6398 23.1226L22.7374 27.1616C23.548 27.4859 24.4522 27.4859 25.2628 27.1616L35.3604 23.1226C36.0729 22.8376 36.0729 21.8292 35.3604 21.5442L25.2628 17.5051Z" fill="#996076"/>
        <path d="M34.5001 26C33.6717 26 33.0001 26.6716 33.0001 27.5V30.15C33.0001 30.6195 33.3807 31 33.8501 31H35.1501C35.6196 31 36.0001 30.6195 36.0001 30.15V27.5C36.0001 26.6716 35.3285 26 34.5001 26Z" fill="#996076"/>
        <g opacity="0.5">
        <path d="M23.9043 21.8C28.3226 21.8 31.9043 25.3818 31.9043 29.8C31.9043 31.5674 28.3226 33.0001 23.9043 33.0001C19.4922 33.0001 15.9143 31.5714 15.9043 29.8075L15.9043 29.8C15.9043 25.3818 19.486 21.8 23.9043 21.8Z" fill="#996076"/>
        <path d="M34.9996 27V22H33.9996V27H34.9996Z" fill="#996076"/>
        </g>
        </svg>


    </SvgIcon>
  );
}
