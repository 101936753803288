import * as React from "react";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";

import LockIcon from "@mui/icons-material/Lock";
import FacebookIcon from "../assets/icons/FacebookIcon.tsx";
import LinkedinIcon from "../assets/icons/LinkedinIcon.tsx";
import InstaIcon from "../assets/icons/InstaIcon.tsx";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function PartnerFooter() {
  const logo = require("../assets/Logo.png");
  return (
    <Box
      sx={{
        width: "100%",
        background: "white",
        height: "25rem",
        padding: "3rem 5rem",
      }}
    >
      <Divider variant="fullWidth" sx={{ opacity: "0.5" }} />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "1rem 0",
        }}
      >
        <p style={{ fontSize: "1.25rem" }}>Copyright 2024</p>
        <Stack direction="row" spacing={1}>
          <IconButton aria-label="delete">
            <FacebookIcon />
          </IconButton>
          <IconButton aria-label="delete" disabled color="primary">
            <InstaIcon />
          </IconButton>
          <IconButton color="secondary" aria-label="add an alarm">
            <LinkedinIcon />
          </IconButton>
        </Stack>
      </Box>
      <Divider variant="fullWidth" sx={{ opacity: "0.5" }} />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          fontSize: "1rem",
          textAlign: "left",
          alignItems: "center",
          padding: "2rem 0",
          lineHeight: 1.5,
        }}
      >
        <p>
          Herewith provides a platform to assist caregivers and care recipients
          in connecting regarding in-home non-medical care opportunities.
          Herewith does not employ any caregivers, nor does it recommend any
          caregivers and/or care recipients who use its platform. User
          information provided in profiles, posts, and otherwise on the Herewith
          platform is not generated or verified by Herewith. Each user of
          Herewith’s platform is responsible for conducting their own vetting
          before determining whether to enter into an employment relationship
          and for their own conduct, including compliance with applicable laws.
        </p>
      </Box>
      <Divider variant="fullWidth" sx={{ opacity: "0.5" }} />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          fontSize: "1rem",
          textAlign: "left",
          alignItems: "center",
          padding: "1rem 0",
        }}
      >
        <Chip
          icon={<LockIcon />}
          label="SSL"
          size="medium"
          sx={{ marginRight: "1rem" }}
        />
        <Chip
          icon={<LockIcon />}
          label="CCPA Compliant"
          size="medium"
          sx={{ marginRight: "1rem" }}
        />
        <Divider orientation="vertical" flexItem />
        <p style={{ margin: "0 1rem", width: "30%" }}>
          Herewith takes your privacy seriously. Please review our privacy
          policy to understand how we use your data.
        </p>
        <Divider orientation="vertical" flexItem />
        <p style={{ margin: "0 1rem", width: "30%" }}>
          Secure Payments with{" "}
          <span style={{ fontWeight: "bold", color: "purple" }}>Stripe</span>
        </p>
      </Box>
    </Box>
  );
}
