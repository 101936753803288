import SvgIcon from "@mui/material/SvgIcon";

export default function XIcon({color="black"}) {
  return (
    <SvgIcon
      sx={{
        width: "inherit",
        flex: 1,
        height: "inherit",
      }}
    >
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 17.7071C-0.0976311 17.3166 -0.0976311 16.6834 0.292893 16.2929L16.2929 0.292894C16.6834 -0.0976317 17.3166 -0.0976307 17.7071 0.292894C18.0976 0.683418 18.0976 1.31658 17.7071 1.70711L1.70711 17.7071C1.31658 18.0976 0.683418 18.0976 0.292893 17.7071Z" fill="#85827D"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.292893 0.292893C0.683418 -0.0976309 1.31658 -0.0976309 1.70711 0.292893L17.7071 16.2929C18.0976 16.6834 18.0976 17.3166 17.7071 17.7071C17.3166 18.0976 16.6834 18.0976 16.2929 17.7071L0.292893 1.70711C-0.0976309 1.31658 -0.0976309 0.683418 0.292893 0.292893Z" fill="#85827D"/>
    </svg>

    </SvgIcon>
  );
}
