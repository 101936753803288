import React, { useState } from "react";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { client } from "../../client.js";
import { useNavigate } from "react-router-dom";
import SearchIcon from "../../assets/icons/SearchIcon.tsx";
import { debounce } from "lodash";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const stopWords = new Set(["the", "at", "in", "on", "and", "or", "but", "is", "of", "to", "a"]);

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: alpha(theme.palette.common.white, 1),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.95),
  },
  maxWidth: "550px",
  height: "4rem",
  borderRadius: "9999px",
  display: "flex",
  padding: "8px",
  alignItems: "center",
  alignSelf: "center",
  [theme.breakpoints.down("sm")]: {

  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  height: "80%",
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minWidth: "3rem",
  zIndex: "100",
  alignSelf: "center"
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  flex: 1,
  height: "100%",
  borderRadius: "9999px",
  zIndex: "1",
  "& .MuiInputBase-input": {
    zIndex: "1",
    padding: theme.spacing(1, 1, 1, 0),
    fontSize: "16px",
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      fontSize: "20px",
    },
  },
}));

const SuggestionsList = styled("ul")({
  position: "absolute",
  top: "4.5rem",
  width: "95%",
  backgroundColor: "white",
  borderRadius: "8px",
  boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
  listStyleType: "none",
  padding: "0",
  margin: "0",
  zIndex: "200",
});

const SuggestionItem = styled("li")({
  padding: "10px 15px",
  borderBottom: "1px solid #eee",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#f5f5f5",
  },
});


const HelpCenterSearch: React.FC = () => {
  const [query, setQuery] = useState<string>("");
  const [results, setResults] = useState([]);
  const navigate = useNavigate();
  const [searchSuggestions, setSearchSuggestions] = useState([]);

  const handleSearch = async (searchQuery: string) => {
    const queryWords = searchQuery
      .toLowerCase()
      .split(/\s+/)
      .filter((word) => !stopWords.has(word));

    const queryRegex = queryWords.map((word) => `*${word}*`).join(" && ");

    try {
      const data = await client.fetch(
        `*[_type in ["post", 'author', 'category']
          && (title match $queryRegex || description match $queryRegex || body[].children[].text match $queryRegex)] {
            title,
            name,
            description,
            body,
            'slug': slug.current,
            'type': _type,
            mainImage {
                asset -> { _id, url },
                alt,
            }
          }`,
        { queryRegex }
      );

      const rankedResults = data
        .map((item) => {
          const combinedText = [item.title, item.description, ...(item.body || []).flatMap((block) =>
            block.children?.map((child) => child.text || "")
          )].join(" ");

          const relevance = queryWords.reduce(
            (count, word) => count + (combinedText.toLowerCase().match(new RegExp(word, "g")) || []).length,
            0
          );

          return { ...item, relevance };
        })
        .sort((a, b) => b.relevance - a.relevance);

      setResults(rankedResults);
      navigate("/help-center/search-results", { state: { searchQuery, results: rankedResults } });
      console.log(rankedResults)
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  const populateResults = async (searchQuery: string) => {
    const queryWords = searchQuery
      .toLowerCase()
      .split(/\s+/)
      .filter((word) => !stopWords.has(word));

    const queryRegex = queryWords.map((word) => `*${word}*`).join(" && ");
    const data = await client.fetch(
      `*[_type in ["post", 'author', 'category']
        && (title match $queryRegex || description match $queryRegex || body[].children[].text match $queryRegex)] {
          title,
          'slug': slug.current,
          'type': _type,
        }`,
      { queryRegex }
    ) .then (
      );
  }

  

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && query.trim() !== "") {
      handleSearch(query);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
    populateResults(query);
  };

  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div style={{width: "100%", display: "flex", justifyContent: phone ? "center" : "", alignItems: "center", marginTop: phone ? "" : tablet ? "0rem" : "0.5rem"}}>
    <Search style={{width: phone ? "90%" : "100%"}}>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder={phone ? "Search for advice": "Search for advice and articles"}
        inputProps={{ "aria-label": "search" }}
        value={query}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
    </Search>
    {/*{searchSuggestions.length > 0 && (
        <SuggestionsList>
          {searchSuggestions.map((suggestion, index) => (
            <SuggestionItem key={index} onClick={() => handleSuggestionClick(suggestion)}>
              {suggestion.title}
            </SuggestionItem>
          ))}
        </SuggestionsList>
      )}*/}
    </div>
  );
};

export default HelpCenterSearch;