import * as React from "react";

import IconRepeater from "./IconRepeater.tsx";
import BookTrustedHelpers from "./BookTrustedHelpers.tsx";
import WeCanHelp from "./WeCanHelp.tsx";
import FastBooking from "./FastBooking.tsx";
import TrackHelpRequests from "./TrackHelpRequests.tsx";
import Subscribe from "../../components/Subscribe.tsx";
import { Box } from "@mui/material";
import Partners from "../../components/Partners.tsx";
import RHeader from "../../components/ResponsiveHeader.tsx";
// import stepper from "../../assets/dashstep.png";
// import mod2 from "../../assets/mod2.png";

// interface DashboardProps extends RouteComponentProps {}
import Navbar from "../../components/Navbar.tsx";
import Footer from "../../components/Footer.tsx";
import BackToTopButton from "../../components/BackToTopButton.tsx";
import { Helmet } from "react-helmet";
import NewTestimonials from "./NewTestimonials.tsx";
import AlertBanner from "../../components/AlertBanner.tsx";
interface DashboardProps {}

const Dashboard: React.FC<DashboardProps> = () => {  
  return (
    <>
      <Helmet>
        <title>Home</title>
        <meta
          name="description"
          content="Book a Helper today! Get affordable, background-checked help for seniors—choose one-time or ongoing help with everyday tasks. No commitments, no subscriptions. "
        />
      </Helmet>
      <Box
        sx={{
          // height: "fit-content",
          width: "100%",
          backgroundColor: "#FAF8F5",

          maxWidth: "100vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "0 ",
        }}
      >
        <Navbar color="#706D67" />
        {/*<AlertBanner text="Service Update: The Herewith and Helper platforms will undergo scheduled 
        maintenance and will be temporarily unavailable on Thursday, Nov 21, 2024, from approximately 1AM EST–4AM EST.
         We appreciate your understanding while we perform these upgrades."/> */}
        {/* <Header />*/}
        <RHeader headerImage="" headerTextBeforeSpan="Find " headerTextSpan="everyday help" headerTextAfterSpan=" at home for seniors" paragraphText="" textColor="" backgroundColor="#ACE1EF" buttonText="" secondaryBackgroundColor="#D8F4FC" />
        {/* <img
            src={headerimg}
            alt="header image"
            style={{ width: "90%", margin: "1rem auto" }}
          /> */}
        <IconRepeater />
        <BookTrustedHelpers />
        
        <Partners />
        <WeCanHelp />
        <FastBooking />

        <TrackHelpRequests />
        {/* <Faqs /> */}
        <NewTestimonials disappear={false}/>
        <Subscribe />
        <Footer />
      </Box>
    </>
  );
};

export default Dashboard;


// ┌---------------------------------------------------┐
// |                                                   |
// |                                                   |
// └---------------------------------------------------┘