import * as React from "react";
import { Box, Divider } from "@mui/material";

import Navbar from "../../components/Navbar.tsx";
import Footer from "../../components/Footer.tsx";

import ZeroCost from "./ZeroCost.tsx";
import CostlyHidden from "./CostlyHidden.tsx";
import ELHeader from "./ELHeader.tsx";
import EverydayRelief from "./EverydayRelief.tsx";
import IconRepeater from "./IconRepeater.tsx";
import GetinTouch from "./GetinTouch.tsx";
import PartnerBanner from "./PartnerBanner.tsx";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Helmet } from "react-helmet";
import ResponsiveBubble from "../../components/Responsivebubble.tsx";
interface EmployerLandingProps {}

const EmployerLanding: React.FC<EmployerLandingProps> = () => {
  const theme = useTheme();

  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.down("lg"));

  const img1 = require("../../assets/Partners/CompressedWebP/stats.webp");
  const img2 = require("../../assets/pink.webp");

  const img3 = require("../../assets/Partners/CompressedWebP/partnerlogosbanner.webp");
  const img4 = require("../../assets/about4.png");

  const mobileImg1 = require("../../assets/MobileAbt1.png");
  const mobileImg2 = require("../../assets/MobileAbt2.png");
  const mobileImg3 = require("../../assets/MobileAbt3.png");
  const mobileImg4 = require("../../assets/MobileAbt4.png");

  const costlyHiddenMobile = require("../../assets/CostlyHiddenMobile.png");
  const costlyHiddenTablet = require("../../assets/costlyhiddenttablet.png");
  const ban = require("../../assets/partnerbanner.png");
  const logo = require("../../assets/Logo.png");

  return (
    <>
      <Helmet>
        <title>Partners</title>
        <meta
          name="description"
          content="Learn why senior care on Herewith is the zero-cost, high-reward benefit that will help you and your employees save time, stress, and money."
        />
      </Helmet>
      <Box
        sx={{
          // height: "fit-content",
          // width: "100%",
          // overflowX: "hidden",
          // backgroundColor: "white",

          // boxSizing: "border-box",
          // maxWidth: "100vw",
          // display: "flex",
          // flexDirection: "column",
          // alignItems: "center",
          // justifyContent: "center",
          // padding: "0 ",

          width: "100%",
          backgroundColor: "white",

          maxWidth: "100vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "0 ",
        }}
      >
        {/* Header */}

        <ELHeader />
        {/*<ResponsiveBubble 
          titleBeforeSpan="Your employees" 
          span="have a second job" 
          titleAfterSpan="" 
          spanColor="#177A98" 
          textColor="" 
          subText="Today 40 million Americans care for aging parents—while 
          employers lose thousands of dollars every year per caregiving employee. 
          Herewith’s flexible senior care benefits offer a real solution for you 
          and your employees." 
          buttonText="Click Me" 
          backgroundC="#FECA5C" 
          image=""
        />*/}

        {/* Components */}

        <CostlyHidden
          img={img1}
          title1="The costly, hidden problem of"
          title2="employee caregivers"
          cta="Get in touch"
          subColor="#C97B99"
          mobileImg={costlyHiddenMobile}
          tabImg={costlyHiddenTablet}
        />
        <ZeroCost />
        <EverydayRelief
          img={img2}
          title1="Everyday relief for your"
          title2="employees"
          cta="Get in touch"
          subColor="#C97B99"
          mobileImg={mobileImg3}
          text="On Herewith’s online platform, families can find and book background-checked local Helpers for quick, task-based support or ongoing help for those who need it."
        />
        <IconRepeater />
        <Divider
          sx={{
            opacity: "50%",
            width: "100%",
          }}
        />
        {/* <img
          src={img3}
          alt="img"
          style={{ display: phone ? "none" : "block", width: "100%" }}
        /> */}

        <PartnerBanner />
        <GetinTouch />
        <Footer googleLink="https://play.google.com/store/apps/details?id=com.quoori.care.pro.app" appleLink="https://apps.apple.com/us/app/herewith-pro-for-caregivers/id1629914138"/>
      </Box>
    </>
  );
};

export default EmployerLanding;
