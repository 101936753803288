import * as React from "react";
import { Box } from "@mui/material";

import Footer from "../../components/Footer.tsx";
import EventsHeader from "./EventsHeader.tsx";
import TrustedLocal from "../PartnerPages/NewTrustedLocal.tsx";
import NewFastConvenient from "../PartnerPages/NewFastConvenient.tsx";
import WeCanHelpEvents from "../PartnerPages/WeCanHelpEvents.tsx";
import NewTestimonials from "../Dashboard/NewTestimonials.tsx";
import useMediaQuery from "@mui/material/useMediaQuery";
import NewIconRepeater from "../PartnerPages/NewIconRepeater.tsx";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import ResponsiveBubble from "../../components/Responsivebubble.tsx";
import ResponsiveBanner from "../../components/ResponsiveBanner.tsx";
import { Helmet } from "react-helmet";

interface GetStartedProps {}

const GetStarted: React.FC<GetStartedProps> = () => {
  const theme = useTheme();
  const curve = require("../../assets/helpyouneedbottomcurve.png");
  const phone = useMediaQuery(theme.breakpoints.down("sm"));

  const [utm, setUTM] = useState(
    "/?utm_campaign=social_launch_24&utm_source=facebook&utm_medium=ad&utm_content=familyvideo2&utm_id=marketing"
  );
  
  return (
    <>
    <Helmet>
        <title>Get Started</title>
        <meta
          name="description"
          content="Book a Helper today! Get affordable, background-checked help for seniors—choose one-time or ongoing help with everyday tasks. No commitments, no subscriptions. "
        />
      </Helmet>
      <Box
        sx={{
          // height: "fit-content",
          width: "100vw",
          backgroundColor: "white",
          maxWidth: "100vw",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "0 ",
        }}
      >
        {/* Header */}

        {/* <EventsHeader /> */}
        {/*<ResponsiveBanner
          subText="Sign up today for up to $30 off your first booking-no code needed"
          boldText="Try Herewith and get a free hour of help on us!"
          backgroundColor=""
          utm=""
        />?*/}
        <ResponsiveBubble 
          titleBeforeSpan="" 
          span={<>Need a helping hand?<br /></>} 
          titleAfterSpan="Try task-based help for seniors" 
          spanColor="#795EC6" 
          textColor="" 
          subText="On Herewith you’ll set your own budget, 
          choose your own Helper, and book as much or as little 
          help as you need, with everything from tasks around the 
          house to outings, errands, and even basic tech help." 
          buttonText="Claim your welcome offer" 
          backgroundC="" 
          image="" 
          utm={utm}/>
        <NewIconRepeater />
        <WeCanHelpEvents partner=""/>
        <TrustedLocal partner=""/>
        {/* <EventsTestimonials /> */}
        <NewFastConvenient />
        <img src={curve} alt="" style={{ width: "100vw", display: phone ? "none" : "flex"}} />
        <NewTestimonials />
        <Footer />
      </Box>
    </>
  );
};

export default GetStarted;
