import * as React from "react";
import { useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Button,
  Tooltip,
  Menu,
  MenuItem,
  IconButton,
  Avatar,
  Backdrop,
  ListItemIcon,
} from "@mui/material";
import Navlink from "./Navlink.tsx";
import { Link } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Sling as Hamburger } from "hamburger-react";

interface NavProps {
  color: string;
}
const Navbar: React.FC<NavProps> = ({ color }) => {
  const theme = useTheme();
  const [utm, setUTM] = useState("");
  const [active, setActive] = useState("");
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.down("lg"));
  const logo = require("../assets/Logo.png");

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    anchorEl === null ? setAnchorEl(event.currentTarget) : setAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (window.location.href.includes("?utm")) {
      setUTM(`?${window.location.href.split("?").pop()}`);
    }
  }, [window.location]);

  var isWhite = false;
    if(color == "white"){
      isWhite = true;
    }

  return (
    <Box
      sx={{ flexGrow: 1, width: "100%", maxWidth: "100vw", overflow: "hidden" }}
    >
      {/* ------------ desktop -------------- */}
      <AppBar
        position="static"
        elevation={0}
        sx={{
          display: phone ? "none" : tablet ? "none" : "flex",
          backgroundColor: "transparent",
          margin: "0 auto",

          flexFlow: "row wrap",
          height: "8rem",
          width: "95%",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Link
          style={{
            textDecoration: "none",
            height: "40%",
            width: "auto",

            verticalAlign: "center",
          }}
          to="/"
        >
          <img
            src={logo}
            style={{
              objectFit: "contain",
              height: "100%",
              width: "auto",
            }}
            alt="logo"
          />
        </Link>
          {isWhite ? <></> : <Toolbar sx={{ flex: 1 }}>
          <Navlink text="Find Help" url="/" color={color} />

          <Navlink text="Be a Helper" url="/helpers" color={color} />
          {/* <Navlink text="Be a Helper (td)" url="" /> */}

          <Navlink text="Partners" url="/partners" color={color} />
          <Navlink text="About Us" url="/about" color={color} />
          <Navlink text="Help Center" url="/help-center" color={color} />
          {/* <Navlink text="Bennie" url="/bennie-health" color=   {color} /> */}
          {/* <Link to="/caregiving"></Link>
          <Link to="/about"></Link>
          <Link to="/support"></Link> */}
        </Toolbar>}
        <Link to={`https://app.herewith.com/sign-in${utm}`}>
          <Button
            variant="contained"
            startIcon={<PersonIcon fontSize="large" />}
            sx={{
              width: "fit-content",
              flex: 1,
              borderRadius: 9999,
              padding: ".5rem 1rem",
              boxShadow: "none",
              "&:hover": {
                background: "white",
                // Add other hover styles as needed
              },
            }}
          >
            Login
          </Button>
        </Link>
      </AppBar>
      {/* ------------ tablet -------------- */}
      <AppBar
        position="static"
        elevation={0}
        sx={{
          display: phone ? "none" : tablet ? "none" : "none",
          backgroundColor: "transparent",
          margin: "0 auto",

          flexFlow: "row nowrap",
          height: "8rem",
          width: "90%",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Link
          style={{
            textDecoration: "none",
            // height: "40%",
            width: "20%",

            verticalAlign: "center",
          }}
          to="/"
        >
          <img
            src={logo}
            style={{
              objectFit: "contain",
              height: tablet ? "auto" : "100%",
              width: tablet ? "100%" : "auto",
            }}
            alt="logo"
          />
        </Link>
        <Toolbar sx={{ flex: 1, width: "80vw" }}>
          <Navlink text="Find Help" url="/" color={color} />
          <Tooltip title="link temporarily disabled " placement="bottom">
            <Navlink text="Be a Helper" url="/helpers" color={color} />
            {/* <Navlink text="Be a Helper (td)" url="" /> */}
          </Tooltip>
          <Navlink text="Partners" url="/partners" color={color} />
          <Navlink text="About Us" url="/about" color={color} />
          <Navlink text="Help Center" url="/help-center" color={color} />
          {/* <Navlink text="Bennie" url="/bennie-health" color=   {color} /> */}
          {/* <Link to="/caregiving"></Link>
          <Link to="/about"></Link>
          <Link to="/support"></Link> */}
        </Toolbar>
        <Link to={`https://app.herewith.com/sign-in${utm}`}>
          <Button
            variant="contained"
            startIcon={<PersonIcon fontSize="large" />}
            sx={{
              width: "fit-content",
              borderRadius: 9999,
              padding: ".5rem 1rem",
              boxShadow: "none",
              "&:hover": {
                background: "white",
                // Add other hover styles as needed
              },
            }}
          >
            Login
          </Button>
        </Link>
      </AppBar>
      {/* ------------ mobile -------------- */}
      <AppBar
        position="static"
        elevation={0}
        sx={{
          display: phone || tablet ? "flex" : "none",
          backgroundColor: "transparent",
          margin: "0 auto",
          flexDirection: "row",
          height: "5rem",
          width: "90%",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Link
          style={{
            textDecoration: "none",
            height: "50%",
            width: "auto",
          }}
          to="/"
        >
          <img
            src={logo}
            style={{
              objectFit: "contain",
              height: "100%",
              width: "100%",
            }}
            alt="logo"
          />
        </Link>

        <Box sx={{
                minWidth: "169px !important",
                paddingLeft: "10px",
              }}>
          <Link to={`https://app.herewith.com/sign-in${utm}`}>
            <Button
              variant="contained"
              startIcon={<PersonIcon fontSize="large" />}
              sx={{
                width: "fit-content",
                borderRadius: 9999,
                padding: ".5rem 1rem",
                boxShadow: "none",
                "&:hover": {
                  background: "white",
                  // Add other hover styles as needed
                },
              }}
            >
              Login
            </Button>
          </Link>

          <IconButton
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            sx={{ position: "relative", zIndex: " 10 !important" }}
          >
            <Avatar
              sx={{
                backgroundColor: open ? "#ACE0EF" : "#EBE9E6",
                height: 50,
                width: 50,
                zIndex: 1000,
              }}
            >
              <Box
                sx={{
                  margin: "1rem",
                  color: "black",
                }}
              >
                <Hamburger
                  toggled={open}
                  size={25}
                  toggle={() => handleClick}
                />
              </Box>
            </Avatar>
          </IconButton>
        </Box>

        {/*  ------------ hamburger menu ------------ */}

        <Backdrop
          sx={{ color: "#fff", zIndex: 8 }}
          open={open}
          onClick={handleClose}
        >
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            sx={{ width: "100vw", display: "flex" }}
            // position="relative"
            slotProps={{
              paper: {
                sx: {
                  flex: "1",
                  minWidth: "80vw",
                  left: 0,
                  right: 0,

                  margin: "0 auto",

                  height: tablet ? "fit-content" : "60vh",

                  top: "20%",
                  bottom: 0,
                  borderRadius: "1.5rem",
                  padding: "0 1rem",
                },
              },
            }}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            // sx={{ position: "absolute", top: 0, bottom: 0, right: 0, left: 0 }}
          >
            <MenuItem
              onClick={handleClose}
              sx={{
                borderBottom: "1px solid lightgray ",
                padding: " 0",
                display: "flex",
                width: "100%",
              }}
            >
              <Link
                style={{
                  fontSize: "1.25rem",
                  textDecoration: "none",
                  color: "black",
                  margin: "1.5rem 0",
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                to="/"
              >
                Home
                <ListItemIcon>
                  <ArrowForwardIcon fontSize="medium" />
                </ListItemIcon>
              </Link>
            </MenuItem>

            <MenuItem
              onClick={handleClose}
              sx={{
                borderBottom: "1px solid lightgray ",
                padding: " 0",
                display: "flex",
                width: "100%",
              }}
            >
              <Link
                style={{
                  fontSize: "1.25rem",
                  textDecoration: "none",
                  color: "black",
                  margin: "1.5rem 0",
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                to="/helpers"
              >
                Be a Helper
                <ListItemIcon>
                  <ArrowForwardIcon fontSize="medium" />
                </ListItemIcon>
              </Link>
            </MenuItem>
            <MenuItem
              onClick={handleClose}
              sx={{
                borderBottom: "1px solid lightgray ",
                padding: " 0",
                display: "flex",
                width: "100%",
              }}
            >
              <Link
                style={{
                  fontSize: "1.25rem",
                  textDecoration: "none",
                  color: "black",
                  margin: "1.5rem 0",
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                to="/partners"
              >
                Partners
                <ListItemIcon>
                  <ArrowForwardIcon fontSize="medium" />
                </ListItemIcon>
              </Link>
            </MenuItem>

            <MenuItem
              onClick={handleClose}
              sx={{
                borderBottom: "1px solid lightgray ",
                padding: " 0",
                display: "flex",
                width: "100%",
              }}
            >
              <Link
                style={{
                  fontSize: "1.25rem",
                  textDecoration: "none",
                  color: "black",
                  margin: "1.5rem 0",
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                to="/about"
              >
                About Us
                <ListItemIcon>
                  <ArrowForwardIcon fontSize="medium" />
                </ListItemIcon>
              </Link>
            </MenuItem>

            <MenuItem
              onClick={handleClose}
              sx={{
                // borderBottom: "1px solid lightgray ",
                padding: " 0",
                display: "flex",
                width: "100%",
              }}
            >
              <Link
                style={{
                  fontSize: "1.25rem",
                  textDecoration: "none",
                  color: "black",
                  margin: "1.5rem 0",
                  display: "flex",
                  width: "100%",
                  height: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                to="/help-center"
              >
                Help Center
                <ListItemIcon>
                  <ArrowForwardIcon fontSize="medium" />
                </ListItemIcon>
              </Link>
            </MenuItem>
          </Menu>
        </Backdrop>
      </AppBar>{" "}
    </Box>
  );
};

export default Navbar;
