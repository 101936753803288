import * as React from "react";
import { styled, alpha } from "@mui/material/styles";

import Box from "@mui/material/Box";

import InputBase from "@mui/material/InputBase";

import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Button } from "@mui/material";
import Navbar from "../../components/Navbar";
import { Link } from "react-router-dom";
import AlertBanner from "../../components/AlertBanner";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  backgroundColor: alpha(theme.palette.common.white, 1),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.95),
  },
  marginLeft: 0,
  marginTop: "2.5rem",
  width: "60% !important",
  height: "4rem",
  borderRadius: "9999px",
  display: "flex",
  padding: "8px",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100% !important",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  //   padding: theme.spacing(0, 2),
  height: "80%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minWidth: "3rem",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  flex: 1,
  height: "100%",
  borderRadius: "9999px",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    height: "100%",
    fontSize: "1.25rem",
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
}));

export default function CgHeader() {
  const theme = useTheme();

  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  const desktop = useMediaQuery(theme.breakpoints.down("lg"));

  const bgimage = require("../../assets/Helper Home/CompressedWebP/CgHero.webp");
  const bgImgTablet = require("../../assets/Helper Home/CompressedWebP/cgheadertablet.webp");
  const bgImgMobile = require("../../assets/Helper Home/CompressedWebP/MobileCgHeroImg.webp");
  return (
    <>
      <Box
        sx={{
          backgroundColor: "#05242A",
          width: "100%",
          maxWidth: "100vw",
          overflow: "hidden",
        }}
      >
        <Navbar color="#3D7A86" />
        {/*<AlertBanner text="Service Update: The Herewith and Helper platforms will undergo scheduled 
        maintenance and will be temporarily unavailable on Thursday, Nov 21, 2024, from approximately 1AM EST–4AM EST.
         We appreciate your understanding while we perform these upgrades."/>*/}

        {/* -------------------- desktop --------------- */}

        <div
          style={{
            display: phone ? "none" : tablet ? "none" : "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-start",
            backgroundImage: `url(${bgimage})`,
            margin: "2rem auto",
            width: "90%",
            height: "fit-content",
            minHeight: "40rem",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            borderRadius: "2.5rem",
          }}
        >
          <Box
            sx={{
              display: phone ? "none" : "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "space-around",
              height: "inherit",
              margin: "2rem auto",
              width: "90%",
            }}
          >
            <Box sx={{ maxWidth: "70%", textAlign: "left" }}>
              <h1 style={{ fontSize: "4rem", color: "white" }}>
                <span style={{ fontStyle: "italic", color: "#FDBB2E" }}>
                  Get paid
                </span>{" "}
                to help seniors in your neighborhood
              </h1>

              {/* text */}

              <p
                style={{
                  width: "70%",
                  color: "white",
                  fontSize: "1.1rem",
                  lineHeight: "1.75",
                  margin: "1rem .5rem",
                }}
              >
                Do you enjoy spending time with older adults? Find flexible work
                as a Helper on Herewith. Earn $25-30/hour on average for helping
                seniors with everyday tasks and errands.
              </p>

              {/* Search */}
              {/* <Search>
                <SearchIconWrapper>
                  <LocationOnRoundedIcon
                    style={{ fontSize: "2rem", color: "#177A98" }}
                  />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Enter your zip code"
                  inputProps={{ "aria-label": "search" }}
                />
                <Link to="https://app.herewith.com/jobs?sort=price&order=ASC&page=1&per_page=20">
                  <Button
                    variant="contained"
                    disableElevation
                    sx={{
                      borderRadius: "9999px",
                      fontSize: "18px",
                      "&:hover": {
                        background: "#FECA5B",
                        // Add other hover styles as needed
                      },
                    }}
                  >
                    Find jobs
                  </Button>
                </Link>
              </Search> */}
              <Link to="https://app.herewith.com/pro/sign-up/?utm_campaign=facility_launch_24&utm_source=helper_home&utm_medium=page&utm_content=cta&utm_id=website">
                <Button
                  variant="contained"
                  endIcon={<ArrowForwardIcon sx={{ fontSize: "1.5rem" }} />}
                  sx={{
                    minWidth: phone ? "fit-content" : "fit-content",
                    width: phone ? "100%" : "fit-content",
                    height: "3.5rem",
                    marginTop: "2rem",
                    borderRadius: 9999,
                    fontSize: "18px",
                    "&:hover": {
                      background: "#FECA5B",
                      // Add other hover styles as needed
                    },
                  }}
                >
                  Find Jobs
                </Button>
              </Link>
            </Box>
            <a href="/">
              <Button
                variant="text"
                endIcon={<ArrowForwardIcon sx={{ fontSize: "1.5rem" }} />}
                sx={{
                  textTransform: "none",
                  fontSize: "1.25rem",
                  color: "#90C8D4",
                  borderBottom: "1px solid #90C8D4",
                  borderRadius: "0",
                  paddingLeft: 0,
                  paddingRight: 0,
                  margin: "3rem 0",
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "#0C3D4C",
                    borderColor: "#0C3D4C",
                  },
                }}
              >
                Find a Helper instead
              </Button>
            </a>
          </Box>
          {/* <img src={bgimage} alt="background" /> */}
        </div>

        {/* -------------------- tablet --------------- */}

        <Box
          sx={{
            display: phone ? "none" : tablet ? "flex" : "none",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-start",
            backgroundImage: `url(${bgImgTablet})`,
            margin: "2rem auto",
            width: "90%",
            height: "fit-content",
            minHeight: "20rem",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            borderRadius: "2.5rem",
            [theme.breakpoints.down("sm")]: {
              display: "none !important",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "space-around",
              height: "inherit",
              margin: "2rem auto",
              width: "90%",
            }}
          >
            <Box sx={{ maxWidth: "90%", textAlign: "left" }}>
              <h1 style={{ color: "white" }}>
                <span style={{ fontStyle: "italic", color: "#FDBB2E" }}>
                  Get paid
                </span>{" "}
                to help seniors in your neighborhood
              </h1>

              {/* text */}

              <p
                style={{
                  width: "85%",
                  color: "white",
                  fontSize: "1rem",
                  lineHeight: "1.75",
                  margin: "1rem .5rem",
                }}
              >
                Do you enjoy spending time with older adults? Find flexible work
                as a Helper on Herewith. Earn $25-30/hour on average for helping
                seniors with everyday tasks and errands.
              </p>

              {/* Search */}
              {/* <Search>
                <SearchIconWrapper>
                  <LocationOnRoundedIcon
                    style={{ fontSize: "2rem", color: "#177A98" }}
                  />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Enter your zip code"
                  inputProps={{ "aria-label": "search" }}
                />
                <Link to="https://app.herewith.com/jobs?sort=price&order=ASC&page=1&per_page=20">
                  <Button
                    variant="contained"
                    disableElevation
                    sx={{
                      borderRadius: "9999px",
                      fontSize: "18px",
                      "&:hover": {
                        background: "#FECA5B",
                        // Add other hover styles as needed
                      },
                    }}
                  >
                    Find jobs
                  </Button>
                </Link>
              </Search> */}
              <Link to="https://app.herewith.com/pro/sign-up/?utm_campaign=facility_launch_24&utm_source=helper_home&utm_medium=page&utm_content=cta&utm_id=website">
                <Button
                  variant="contained"
                  endIcon={<ArrowForwardIcon sx={{ fontSize: "1.5rem" }} />}
                  sx={{
                    minWidth: phone ? "fit-content" : "fit-content",
                    width: phone ? "100%" : "fit-content",
                    height: "3rem",
                    marginTop: "1rem",
                    borderRadius: 9999,
                    fontSize: "18px",
                    "&:hover": {
                      background: "#FECA5B",
                      // Add other hover styles as needed
                    },
                  }}
                >
                  Find Jobs
                </Button>
              </Link>
            </Box>
            <a href="/">
              <Button
                variant="text"
                endIcon={<ArrowForwardIcon sx={{ fontSize: "1.5rem" }} />}
                sx={{
                  textTransform: "none",
                  fontSize: "1rem",
                  color: "#90C8D4",
                  borderBottom: "1px solid #90C8D4",
                  borderRadius: "0",
                  paddingLeft: 0,
                  paddingRight: 0,
                  margin: "3rem 0",
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "#0C3D4C",
                    borderColor: "#0C3D4C",
                  },
                }}
              >
                Find a Helper instead
              </Button>
            </a>
          </Box>
          {/* <img src={bgimage} alt="background" /> */}
        </Box>

        {/* -------------------- mobile --------------- */}

        <div
          style={{
            display: phone ? "flex" : "none",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-start",
            backgroundImage: `url(${bgImgMobile})`,
            margin: "2rem auto",
            width: "90%",
            height: "fit-content",
            backgroundPosition: "center bottom",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            borderRadius: "2.5rem",
            padding: "2rem 1rem 15rem 1rem",
          }}
        >
          <Box sx={{ maxWidth: "100%", textAlign: "center" }}>
            <h1 style={{ fontSize: "1.75rem", color: "white" }}>
              <span style={{ fontStyle: "italic", color: "#FDBB2E" }}>
                Get paid
              </span>{" "}
              to help seniors in your neighborhood
            </h1>

            {/* text */}

            <p
              style={{
                width: "100%",
                color: "white",
                fontSize: "1rem",
                lineHeight: "2",
                margin: "1rem auto",
              }}
            >
              Do you enjoy spending time with older adults? Find flexible work
              as a Helper on Herewith. Earn $25-30/hour on average for helping
              seniors with everyday tasks and errands.
            </p>

            {/* Search */}
            {/* <Search>
              <SearchIconWrapper>
                <LocationOnRoundedIcon
                  style={{ fontSize: "2rem", color: "#177A98" }}
                />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Enter your zip code"
                inputProps={{ "aria-label": "search" }}
              />
            </Search> */}
            <Link to="https://app.herewith.com/pro/sign-up/?utm_campaign=facility_launch_24&utm_source=helper_home&utm_medium=page&utm_content=cta&utm_id=website">
              <Button
                variant="contained"
                endIcon={<ArrowForwardIcon sx={{ fontSize: "1.5rem" }} />}
                sx={{
                  minWidth: phone ? "fit-content" : "fit-content",
                  width: phone ? "100%" : "fit-content",
                  height: "3.5rem",
                  marginTop: "2rem",
                  borderRadius: 9999,
                  fontSize: "18px",
                  "&:hover": {
                    background: "#FECA5B",
                    // Add other hover styles as needed
                  },
                }}
              >
                Find Jobs
              </Button>
            </Link>
            {/* <Button
              variant="contained"
              disableElevation
              sx={{
                borderRadius: "9999px",
                fontSize: "1.25rem",
                marginTop: "1rem",
                width: "100%",
                height: "4rem",
                "&:hover": {
                  background: "#FECA5B",
                  // Add other hover styles as needed
                },
              }}
            >
              Find jobs
            </Button> */}
            <a href="/">
              <Button
                variant="text"
                endIcon={<ArrowForwardIcon sx={{ fontSize: "1.5rem" }} />}
                sx={{
                  textTransform: "none",
                  fontSize: "1rem",
                  color: "#90C8D4",
                  borderBottom: "1px solid #90C8D4",
                  borderRadius: "0",
                  paddingLeft: 0,
                  paddingRight: 0,
                  marginTop: "1.5rem",
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "#0C3D4C",
                    borderColor: "#0C3D4C",
                  },
                }}
              >
                Find a Helper instead
              </Button>
            </a>
          </Box>
        </div>
      </Box>
    </>
  );
}
