import * as React from "react";
import Box from "@mui/material/Box";
import ReactDOM from "react-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

interface TestimonialProps {
  disappear?: boolean;
}

const CgTestimonials: React.FC<TestimonialProps> = ({disappear = true}) => {
  const first = require("../../assets/Helper Home/CompressedWebP/Testimonial1.webp");
  const second = require("../../assets/Helper Home/CompressedWebP/Testimonial2.webp");
  const third = require("../../assets/Helper Home/CompressedWebP/Testimonial3.webp");
  const fourth = require("../../assets/Helper Home/CompressedWebP/Testimonial4.webp");
  const fifth = require("../../assets/Helper Home/CompressedWebP/Testimonial5.webp");
  const sixth = require("../../assets/Helper Home/CompressedWebP/Testimonial6.webp");

  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down("sm"));
  const tablet = useMediaQuery(theme.breakpoints.down("md"));
  
  const leftArrow = require("../../assets/Home/CompressedWebP/circlebuttonleft.webp");
  const rightArrow = require("../../assets/Home/CompressedWebP/circlebuttonright.webp");

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: phone ? "fit-content" : "30rem",
        height: "fit-content",
        display: disappear && phone ? "none" : "block",
        margin: phone ? "1rem" : "0",
        padding: phone ? "1rem" : "2rem",
        backgroundColor: "white",
      }}
    >
      <h1 style={{
        display: "block",
        marginBottom: "2rem",
        fontSize: tablet ? "2rem" : "2.5rem",
        color: '#000000',
      }}>
        What people are saying
      </h1>
      <Box
        sx={{
          width: phone ? "100%" : tablet ? "100%" : "1000px",
          margin: "0 auto",
        }}
      >
        <Carousel
          autoPlay
          infiniteLoop
          showThumbs={false}
          showIndicators={false} // Hide indicators
          showStatus={false}
          renderArrowPrev={(clickHandler, hasPrev, label) => (
            <button
              onClick={clickHandler}
              aria-label={label}
              style={{
                position: "absolute",
                left: "10px",
                top: "30%",
                transform: "translateY(-50%)",
                zIndex: 1,
                background: "none",
                border: "none",
                cursor: "pointer",
              }}
            >
              <img src={leftArrow} alt="Previous" />
            </button>
          )}
          renderArrowNext={(clickHandler, hasNext, label) => (
            <button
              onClick={clickHandler}
              aria-label={label}
              style={{
                position: "absolute",
                right: "10px",
                top: "30%",
                transform: "translateY(-50%)",
                zIndex: 1,
                background: "none",
                border: "none",
                cursor: "pointer",
              }}
            >
              <img src={rightArrow} alt="Next" />
            </button>
          )}
        >
          {[
            {
              image: first,
              text: `“I like that Herewith’s a task-oriented gig. The pay is good, 
              the requirements are low, and it gives me the opportunity to help out
               seniors.”`,
              author: "Sydney",
            },
            {
              image: second,
              text: `“I've used other sites. I think Herewith was a little more
               motivated to get me a client. It's kind of just effortless. You guys
                do a lot of the work—makes my life a little bit easier.”`,
              author: "Stephanie",
            },
            {
              image: third,
              text: `“Herewith really wants the best caregiver for the 
              person who needs care.”`,
              author: "Carol",
            },
            {
              image: fourth,
              text: `“I really feel Herewith is on my side, being considerate and thoughtful.
               Everybody I have dealt with has been absolutely wonderful and helpful.”`,
              author: "Marie",
            },
            {
              image: fifth,
              text: `“I think Herewith does a good job to make sure that the 
              caregivers are alright. If we don't understand something, then they 
              reach out to you. Go step by step with you to make sure that you 
              understand.”`,
              author: "Emma",
            },
            {
              image: sixth,
              text: `“When I needed help, they sent me a little tutorial. And I was
               like, okay, piece of cake and I figured it out.”`,
              author: "Sandra",
            },
          ].map(({ image, text, author }, index) => (
            <div key={index} style={{ minHeight: phone ? "8rem" : 0 }}>
              <img
                src={image}
                loading="lazy"
                alt={`Testimonial ${index + 1}`}
                style={{
                  margin: "1rem 0 2rem 0",
                  borderRadius: "1rem",
                  height: "104px",
                  width: "104px",
                  filter: "drop-shadow(0px 20px 19px #55524E3D)",
                }}
              />
              <div style={{
                fontSize: '20px',
                color: '#706D67',
                textAlign: 'center',
                marginBottom: '2rem',
              }}>
                {author}
              </div>
              <Divider sx={{ opacity: "50%", marginBottom: "1rem" }} />
              <p className="testimonial-text" 
                style={{
                fontSize: "22px",
                lineHeight: "1.5",
                marginBottom: phone ? "1rem" : "2.5rem",
              }}>
                {text}
              </p>
            </div>
          ))}
        </Carousel>
      </Box>
    </Box>
  );
};

export default CgTestimonials;
