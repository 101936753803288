import * as React from "react";
import { useTheme, createTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import SlideButton from "./SlideButton";
import Navbar from "./Navbar";

interface BubbleProps{
    titleBeforeSpan?: string,
    span: any,
    titleAfterSpan?: string,
    spanColor?: string,
    textColor: string,
    subText?: string,
    buttonText: string,
    image: string,
    backgroundC?: string,
    subTextSecond?: string,
    clipPath?: string,
    utm?: string,
    buttonColor?: string,
    buttonTextClor?: string,
}

const ResponsiveBubble: React.FC<BubbleProps> = ({
    subTextSecond,
    titleBeforeSpan,
    span,
    titleAfterSpan,
    spanColor="#795EC6",
    textColor,
    subText,
    buttonText="default",
    image,
    backgroundC="white",
    clipPath = "ellipse(85% 110% at 110% 77%)",
    utm="",

    }) => {
      const newBp = createTheme({
        breakpoints: {
          values: {
            xs: 0,
            sm: 660,
            md: 820,
            lg: 1200,
            xl: 760,
          },
        },
      });
    const theme = useTheme();
    const phone = useMediaQuery(newBp.breakpoints.down("sm"));
    const tablet = useMediaQuery(newBp.breakpoints.down("lg"));
    const tablet2 = useMediaQuery(newBp.breakpoints.down("md"));
    const heroImg = require("../assets/Home/CompressedWebP/Hero Image - Generic.webp");

    const promo = require("../assets/Partners/CompressedWebP/BannerDesktop.webp");
    const promoMbl = require("../assets/Partners/CompressedWebP/BannerMobile.webp");
    const promoTablet = require("../assets/Partners/CompressedWebP/BannerTablet.webp");
;
    return(
      <>
      <Navbar color="white" />

      <Link to={`https://app.herewith.com/sign-up${utm}`}>
        <img
          src={promo}
          alt="promotional banner - get a free hour of service"
          loading="lazy"
          style={{ display: phone || tablet ? "none" : "block", width: "100%" }}
        />
      </Link>

      <Link to={`https://app.herewith.com/sign-up${utm}`}>
        <img
          src={promoTablet}
          alt="promotional banner - get a free hour of service"
          loading="lazy"
          style={{
            display: phone ? "none" : tablet ? "flex" : "none",
            width: "100%",
          }}
        />
      </Link>

      <Link to={`https://app.herewith.com/sign-up${utm}`}>
        <img
          src={promoMbl}
          alt="promotional banner - get a free hour of service"
          loading="lazy"
          style={{
            display: phone ? "block" : "none",
            borderBottom: "2px solid white",
            width: "100%",
          }}
        />
      </Link>

        <Box
        sx={{
          display: "flex",
          overflow: "hidden",
          width: "100%",
          height: phone ? "42rem" : tablet ? "38rem" : "44rem",
          minHeight: phone || tablet ? "fit-content" : "100%",
          backgroundPosition: "center ",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundColor: backgroundC,
          flexDirection: phone ? "column" : "row",
          justifyContent: "space-between",
        }}
        >
        <Box
          sx={{
            width: phone ? "100% !important" : tablet ? "45%" : "38%",
            minHeight: phone || tablet ? "fit-content" : "100%",
            margin: phone ? "1rem 0rem" : tablet ? "1rem 0 0 3rem" : tablet2 ? "8rem 0 0 3rem" : "0 0 0 5rem ",
            marginRight: phone ? "1rem !important" : tablet ? "-100px" : "-300px",
            display: "flex",
            flexDirection: "column",
            alignItems: phone ? "center" : "space-between",
            justifyContent: phone ? "space-evenly" : "center",
            textAlign: phone ? "center" : "left",
            paddingBottom: phone ? "0px" : tablet ? "0rem" : 0,
          }}
        >
          {/*{showLogo && (
            <img
              src={logo}
              alt="logo"
              loading="lazy"
              style={{
                width: phone || tablet ? "65%" : "70%",
                marginBottom: phone ? "0" : "2rem",
                marginTop: phone || tablet ? "1.5rem" : 0,
              }}
            />
          )}*/}

          <h1
            style={{
              marginBottom: phone ? "1rem" : "2rem",
              marginTop: phone ? "2rem" : 0,
              fontSize: phone ? "1.75rem" : tablet ? "28px" : "2.5rem",
            }}
          >
            {titleBeforeSpan}{" "}
            <span style={{ fontStyle: "italic", color: spanColor }}>
              {span}
            </span>{" "}
            {titleAfterSpan}
          </h1>
          <p
            style={{
              maxWidth: phone || tablet ? "90%" : "100%",
              fontSize: phone || tablet? "1rem" : "1.1rem",
              lineHeight: 1.5,
              textAlign: phone ? "center" : "left",
            }}
          >
            {subText}
            <br /> <br />
            {subTextSecond}
          </p>
          <Link to={`https://app.herewith.com/sign-up${utm}`}>
            <SlideButton link={`https://app.herewith.com/sign-up${utm}`} text={buttonText}/>
          </Link>
        </Box>
        <img 
          src={heroImg} 
          style={{
            position: "relative",
            clipPath: phone 
              ? "ellipse(90% 90% at bottom)" 
              : tablet 
              ? "ellipse(110% 110% at 110% 77%)" 
              : clipPath,
            width: phone 
              ? "100%" 
              : tablet 
              ? "50%" 
              : "70%",  // Adjust these widths based on your needs
            maxWidth: "100%",
            height: "100%",  // Maintain aspect ratio
            alignSelf: tablet ? "end" : "auto",
            objectFit: "cover",
          }}
        />
        </Box>
        </>
    )
}

export default ResponsiveBubble;