import "./App.css";

import Dashboard from "./views/Dashboard/Dashboard.tsx";
import About from "./views/AboutUs/About.tsx";
import CaregiverHome from "./views/CaregiverHomepage/CaregiverHome.tsx";
import HelpCenter from "./views/HelpCenter/HelpCenter.tsx";
import PrivacyPolicy from "./views/PrivacyPolicy.tsx";
import TermsOfService from "./views/TermsOfService.tsx";
import BennieHealth from "./views/PartnerPages/BennieHealth.tsx";
import CommunityGuidelines from "./views/CommunityGuidelines.tsx";
import BlogHome from "./views/HelpCenter/BlogHome.tsx";
import SingleBlogPost from "./views/HelpCenter/SingleBlogPost.tsx";
import EmployerLanding from "./views/Employers/EmployerLanding.tsx";
import EventsLanding from "./views/Events/EventsLanding.tsx";
import PartnerLanding from "./views/PartnerPages/PartnerLanding.tsx";
import NewPartnerLanding from "./views/PartnerPages/NewPartnerLanding.tsx";
import GetStarted from "./views/Campaigns/GetStarted.tsx";
import HelpersSocial from "./views/HelpersSocial/HelpersSocial.tsx";
import Error from "./views/Error.tsx";
import { useTheme } from "@mui/material/styles";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ScrollToAnchor from "./utils/ScrollToAnchor.js";
import BlogSearch from "./views/HelpCenter/BlogSearch.tsx";

import TrackPageView from "./utils/TrackPageView.js";
import { Helmet } from "react-helmet";

import MiniHome from "./views/Dashboard/MiniHome.tsx";
import FindCare from "./views/Dashboard/DashLandingPages/FindCare.tsx";
import FreeHelp from "./views/Campaigns/FreeHelp.tsx";
import Hotjar from "./utils/Hotjar.js";

const App = () => {
  const theme = useTheme();
  const MetaTags = () => (
    <Helmet>
      {/* Preconnect to external resources */}
      <link rel="preconnect" href="https://www.googletagmanager.com" />
      <link rel="preconnect" href="https://connect.facebook.net" />
      <meta name="google-site-verification" content="PmLtD78g7ufDsX3_cGKyuxlMan_3qqB52CSVOeUw-DE" />
    </Helmet>
  );


  return (
    <div className="App">
      <MetaTags />
      <Router>
        <Hotjar />
        <ScrollToAnchor />
        <Routes>
          <Route exact path="/" element={<Dashboard />} />
          <Route exact path="/helpers" element={<CaregiverHome />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/help-center" element={<HelpCenter />} />
          <Route exact path="/help-center/search-results" element={<BlogSearch />} />
          <Route exact path="/guides-and-articles" element={<HelpCenter />} />
          <Route exact path="/privacy" element={<PrivacyPolicy />} />
          <Route exact path="/partners" element={<EmployerLanding />} />
          <Route exact path="/events" element={<EventsLanding />} />
          <Route exact path="/terms-of-service" element={<TermsOfService />} />
          <Route
            exact
            path="/bennie-health"
            element={<PartnerLanding partner="bennie" showLogo={false} />}
          />
          <Route exact path="/partner-demo" element={<BennieHealth />} />
          <Route exact path="/get-started" element={<GetStarted />} />
          <Route exact path="/free-help" element={<FreeHelp />} />
          <Route exact path="/find-help" element={<MiniHome />} />
          <Route exact path="/find-care" element={<FindCare />} />
          <Route exact path="/find-jobs" element={<HelpersSocial />} />
          <Route
            exact
            path="/member-benefits"
            element={<PartnerLanding partner="ebg" />}
          />
          <Route
            exact
            path="/benefits"
            element={<PartnerLanding partner="beneplace" />}
          />

          <Route
            exact
            path="/benefithub"
            element={<PartnerLanding partner="benefithub" showLogo={true} />}
          />
          <Route
            exact
            path="/fond"
            element={<NewPartnerLanding partner="fond" showLogo={true} />}
          />
          <Route
            exact
            path="/amac"
            element={<PartnerLanding partner="amac" showLogo={false} />}
          />
          <Route
            exact
            path="/welcome-members"
            element={<PartnerLanding partner="gateway" showLogo={false} />}
          />
          <Route
            exact
            path="/vantage-circle"
            element={<PartnerLanding partner="vantage" showLogo={false} />}
          />

          <Route exact path="/blog" element={<BlogHome />} />
          <Route path="/help-center/:slug" element={<SingleBlogPost />} />
          <Route path="*" element={<Error />} />
          <Route
            exact
            path="/community-guidelines"
            element={<CommunityGuidelines />}
          />
        </Routes>
      </Router>
    </div>
  );
};

export default App;
